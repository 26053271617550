import React, { useState, useEffect } from "react";
import Select from "react-select";

const optionsData = [
  {
    label: "Others",
    options: [
      { value: "other", label: "Other", category_id: 1 },
      { value: "127", label: "Add test", category_id: "1" },
      { value: "130", label: "Assa", category_id: "1" },
      { value: "132", label: "Asssada", category_id: "1" },
      { value: "126", label: "Dddd", category_id: "1" },
    ],
  },
  {
    label: "Hospitality & Tourism",
    options: [
      { value: "4", label: "Bar & Beverage Staff", category_id: "2" },
      { value: "2", label: "Chef & Cook", category_id: "2" },
      { value: "6", label: "Front Office & Guest Services", category_id: "2" },
      { value: "3", label: "Kitchen & Sandwich Hand", category_id: "2" },
      { value: "7", label: "Management", category_id: "2" },
    ],
  },
];

const CategorySelect = () => {
  // Assuming you only have the value initially (e.g., "130")
  const [selectedOptionValue, setSelectedOptionValue] = useState("7");

  const [selectedCategory, setSelectedCategory] = useState(null); // Store the corresponding category
  const [selectedOption, setSelectedOption] = useState(null); // Store the selected option object

  // Extract only the category labels for the first dropdown
  const categoryOptions = optionsData.map((category) => ({
    label: category.label,
    value: category.label,
  }));

  // Handle category selection
  const handleCategoryChange = (selected) => {
    setSelectedCategory(selected);
    setSelectedOption(null); // Reset the option select when a new category is selected
  };

  // Get category-specific options based on selectedCategory
  const getCategoryOptions = () => {
    if (!selectedCategory) return [];
    const category = optionsData.find((cat) => cat.label === selectedCategory.value);
    return category ? category.options : [];
  };

  // Find and set the category and option when we have a selectedOptionValue
  useEffect(() => {
    if (selectedOptionValue) {
      // Find the category that contains the option with the selectedOptionValue
      const category = optionsData.find((cat) =>
        cat.options.some((option) => option.value === selectedOptionValue)
      );

      if (category) {
        // Set the corresponding category
        setSelectedCategory({ label: category.label, value: category.label });

        // Set the corresponding option
        const option = category.options.find(
          (option) => option.value === selectedOptionValue
        );
        setSelectedOption(option);
      }
    }
  }, [selectedOptionValue]);

  return (
    <div>
      {/* First Select: Categories */}
      <Select
        options={categoryOptions}
        value={selectedCategory}
        onChange={handleCategoryChange}
        placeholder="Select Category"
      />

      {/* Second Select: Category-specific options */}
      <Select
        options={getCategoryOptions()}
        value={selectedOption}
        onChange={setSelectedOption}
        placeholder="Select an option"
        isDisabled={!selectedCategory} // Disable until a category is selected
      />
    </div>
  );
};

export default CategorySelect;


// import React, { useState } from 'react';

// const optionsData = [
//   {
//     label: "Others",
//     options: [
//       { value: "other", label: "Other", category_id: 1 },
//       { value: "127", label: "Add test", category_id: "1" },
//       { value: "130", label: "Assa", category_id: "1" },
//       { value: "132", label: "Asssada", category_id: "1" },
//       { value: "126", label: "Dddd", category_id: "1" },
//     ],
//   },
//   {
//     label: "Hospitality & Tourism",
//     options: [
//       { value: "4", label: "Bar & Beverage Staff", category_id: "2" },
//       { value: "2", label: "Chef & Cook", category_id: "2" },
//       { value: "6", label: "Front Office & Guest Services", category_id: "2" },
//       { value: "3", label: "Kitchen & Sandwich Hand", category_id: "2" },
//       { value: "7", label: "Management", category_id: "2" },
//     ],
//   },
// ];

// const LookingForSelect = () => {
//   const [showOptions, setShowOptions] = useState(false);
//   const [selectedCategory, setSelectedCategory] = useState(null);
//   const [selectedOption, setSelectedOption] = useState('');

//   const handleCategoryClick = (category) => {
//     setSelectedCategory(category);
//     setShowOptions(true);
//   };

//   const handleOptionChange = (option) => {
//     setSelectedOption(option);
//     setShowOptions(false);
//   };

//   return (
//     <div className="form-group col-md-12">
//       <label className="looking_lable" onClick={() => setShowOptions(!showOptions)}>
//         Looking For {selectedOption && `: ${selectedCategory?.label} - ${selectedOption}`}
//       </label>
//       {showOptions && (
//         <div className="options-list">
//           {optionsData.map((category) => (
//             <div key={category.label} className="category-card">
//               <div
//                 className="category-header"
//                 onClick={() => handleCategoryClick(category)}
//               >
//                 <label class="looking_lable_option" >{category.label}</label>
//               </div>
//               {selectedCategory?.label === category.label && (
//                 <div className="category-options">
//                   {category.options.map((option) => (
//                     <label key={option.value} className="container">
//                       <input
//                         type="radio"
//                         name="option"
//                         value={option.label}
//                         checked={selectedOption === option.label}
//                         onChange={() => handleOptionChange(option.label)}
//                       />
//                       {option.label}
//                       <span className="checkmark"></span>
//                     </label>
//                   ))}
//                 </div>
//               )}
//             </div>
//           ))}
//         </div>
//       )}
//     </div>
//   );
// };

// export default LookingForSelect;




// import React, { useRef, useState } from 'react';

// const AvailabilitySection = () => {
//   // Create a ref for each day's 'from' input
//   const refs = {
//     monday: useRef(null),
//     tuesday: useRef(null),
//     wednesday: useRef(null),
//     thursday: useRef(null),
//     friday: useRef(null),
//     saturday: useRef(null),
//     sunday: useRef(null),
//   };

//   // Track checked state for each day
//   const [checkedDays, setCheckedDays] = useState({
//     monday: false,
//     tuesday: false,
//     wednesday: false,
//     thursday: false,
//     friday: false,
//     saturday: false,
//     sunday: false,
//   });

//   // Handle checkbox toggle and focus on 'from' input
//   const handleCheckboxChange = (day) => {
//     const newCheckedDays = { ...checkedDays, [day]: !checkedDays[day] };
//     setCheckedDays(newCheckedDays);
    
//     // If the checkbox is checked, focus on the 'from' input
//     if (!checkedDays[day]) {
//       refs[day].current.focus();
//       refs[day].current.click(); // Try to trigger the time picker dropdown
//     }
//   };

//   // Render availability input for each day
//   const renderDay = (dayLabel, dayKey) => (
//     <div className="availability-sec d-flex align-items-center mb-2" key={dayKey}>
//       <div className="checkboxwraper">
//         <input
//           type="checkbox"
//           checked={checkedDays[dayKey]}
//           onChange={() => handleCheckboxChange(dayKey)}
//           name={`availability[${dayKey}][checkbox][]`}
//         />
//         <span className="checkspan"></span>
//       </div>
//       <p className="cursor_pointer">{dayLabel}</p>
//       <input
//         type="time"
//         name={`availability[${dayKey}][from][]`}
//         className="form-control"
//         placeholder="00:00 am"
//         ref={refs[dayKey]} // Reference to focus on this input
//       />
//       <div className="subheadpera">To</div>
//       <input
//         type="time"
//         name={`availability[${dayKey}][to][]`}
//         className="form-control"
//         placeholder="00:00 am"
//       />
//     </div>
//   );

//   return (
//     <div>
//       {renderDay('Monday', 'monday')}
//       {renderDay('Tuesday', 'tuesday')}
//       {renderDay('Wednesday', 'wednesday')}
//       {renderDay('Thursday', 'thursday')}
//       {renderDay('Friday', 'friday')}
//       {renderDay('Saturday', 'saturday')}
//       {renderDay('Sunday', 'sunday')}
//     </div>
//   );
// };

// export default AvailabilitySection;