import React, { useState, useEffect, Component } from 'react';
import axios from 'axios';
import { NavLink, Link } from 'react-router-dom';
import { Form, Modal } from 'react-bootstrap';
import { toast } from 'react-toastify';
//import 'react-toastify/dist/ReactToastify.css';
import { useDispatch } from 'react-redux'
import { workExpDocumentStatus } from '../redux_state/reducres/checkDocumentstatus';
import DoeanloadFileComp from './DoeanloadFileComp';
import Select from 'react-select';
import YearSelector from '../expocomp/YearsNumbersLoopComp';
import MonthsNumberSelection from '../expocomp/MonthsNumberLoopComp';
import GoogleAutocomplete from '../expocomp/GoogleAutocomplate';
import customStyles from '../expocomp/SelectStyleComp';
import JobTitleCAtegoryComp from '../expocomp/JobTitleCAtegoryComp';

const WorkExpComp = (getdata) => {
    //const auth = localStorage.getItem('squadGologin');
    const dispatch = useDispatch();
    const loggedInUser = JSON.parse(localStorage.getItem("squadGologin"));
    const [userUniqueID, setuserUniqueID] = useState((typeof (getdata.attr) == 'undefined') ? loggedInUser.unique_id : getdata.attr);
    const [workExperienceData, setWorkExperienceData] = useState([]);
    const [getCountryList, setCountryList] = useState([]);
    const [getCountryListOption, setCountryListOption] = useState([]);

    const YearsNumbersLoop = (attr = '') => {
        const itemElements = [];
        itemElements.push(<option value="">YYYY</option>);
        for (let i = 2000; i < 2024; i++) {
            itemElements.push(<option selected={(attr != '' && attr.select_attr == i) ? true : false} value={i}>{i}</option>);
        }
        return itemElements
    }

    const Months = [{ name: 'January', key: 1, abbreviation: 'Jan' }, { name: 'February', key: 2, abbreviation: 'Feb' }, { name: 'March', key: 3, abbreviation: 'Mar' }, { name: 'April', key: 4, abbreviation: 'Apr' }, { name: 'May', key: 5, abbreviation: 'May' }, { name: 'June', key: 6, abbreviation: 'Jun' }, { name: 'July', key: 7, abbreviation: 'Jul' }, { name: 'August', key: 8, abbreviation: 'Aug' }, { name: 'September', key: 9, abbreviation: 'Sep' }, { name: 'October', key: 10, abbreviation: 'Oct' }, { name: 'November', key: 11, abbreviation: 'Nov' },
    { name: 'December', key: 12, abbreviation: 'Dec' },];


    const sessionStorageData = JSON.parse(localStorage.getItem("sessionStorageData"));
    const [isDataLoading, setIsDataLoading] = useState(true);
    //const [isWorkExpVerified, setIsWorkExpVerified] = useState('false');
    const [addEditJobModalToggle, setAddEditJobModalToggle] = useState(false)


    const [totalExpLength, settotalExpLength] = useState(false);//getsize
    const getWorkSExperienceLoadCall = (APICALL = `https://squadgoo.com.au/api.squadgoo.com.au/get_work_experience/${userUniqueID}`) => {
        setIsDataLoading(true);
        (async () => {
            try {
                const resData = await axios.get(APICALL);
                if (resData.data.status == 1) {
                    setWorkExperienceData(resData.data.data.results);
                    settotalExpLength(resData.data.data.getsize);
                    setIsDataLoading(false);
                }
            } catch (error) { console.log(error); }
        })();
    }

    const [dataIsLoad, setDataIsLoad] = useState(false);
    const getMoreExperenc = (type) => {
        if (type == 'load') {
            setDataIsLoad(true);
            getWorkSExperienceLoadCall(`https://squadgoo.com.au/api.squadgoo.com.au/get_work_experience/${userUniqueID}?limit=10`);
        } else {
            setDataIsLoad(false);
            getWorkSExperienceLoadCall(`https://squadgoo.com.au/api.squadgoo.com.au/get_work_experience/${userUniqueID}`);
        }
    }

    useEffect(() => {
        setCountryList(sessionStorageData.get_all_country);
        setCountryListOption(sessionStorageData?.get_all_country_options ? sessionStorageData.get_all_country_options : []);
        getWorkSExperienceLoadCall();
    }, []);



    // const [isHidden, setIsHidden] = useState(true);
    // const toggleHidden = () => { setIsHidden(!isHidden); };
    const [isHiddenItem, setIsHiddenItem] = useState(true);
    useEffect(() => { console.log(isHiddenItem) }, [isHiddenItem]);
    const toggleHiddenSection = (itemid) => { (isHiddenItem) ? setIsHiddenItem(itemid) : setIsHiddenItem(true); }
    const cancelWorkexperienceForm = () => { setIsHiddenItem(true); setAddEditJobModalToggle(false); }




    const AddAndEditFormBtn = (attrData) => {

        //const [userUploadPaySlipDocuments, setUserUploadPaySlipDocuments] = useState([]);


        const [workExperienceData, setWorkExperienceData] = useState(attrData.data);

        let setWorkExperienceReferenceDataArray = [''];
        if (attrData.data.workexperience_referencetype) { setWorkExperienceReferenceDataArray = attrData.data.workexperience_referencetype; }

        //if (attrData.data.payment_pay_slip) { setUserUploadPaySlipDocuments(attrData.data.payment_pay_slip); }

        const [isModalShow, setIsModalShow] = useState(false);
        const [workExperienceReferenceData, setWorkExperienceReferenceData] = useState(setWorkExperienceReferenceDataArray);
        const handleAddMore = () => { setWorkExperienceReferenceData([...workExperienceReferenceData, '']); };
        const handleRemove = (index) => { const newInputs = [...workExperienceReferenceData]; newInputs.splice(index, 1); setWorkExperienceReferenceData(newInputs); };
        const [checkExperienceType, setCheckExperienceType] = useState(workExperienceData.work_expe_prooftype);
        const checkExperienceProof = (event) => { setCheckExperienceType(event.target.value); }
        const [setNewJobTitleValue, setSetNewJobTitleValue] = useState('');
        const [workExperienceTitle, setWorkExperienceTitle] = useState(workExperienceData.work_expe_jobtitle);
        const [defaultJobTitle, setDefaultJobTitle] = useState(workExperienceData.work_expe_jobtitle_id);
        const [getCurrentCountry, setCurrentCountry] = useState(workExperienceData?.work_expe_countryname ? workExperienceData.work_expe_countryname : 13);
        const [jobTitleList, setJobtitleList] = useState([]);
        const [isAddNewJobModalShow, setIsAddNewJobModalShow] = useState(false);
        const [isAddNewJobtitleError, setIsAddNewJobtitleError] = useState(false);
        const [isFileLoading, setisFileLoading] = useState(false);
        const [isAddNewJobtitleErrorMessage, setIsAddNewJobtitleErrorMessage] = useState('');
        const [isAddNewJobtitleSuccessMessage, setIsAddNewJobtitleSuccessMessage] = useState('');
        const getJobTitleValue = (e) => { setIsAddNewJobModalShow(false); setWorkExperienceTitle(e.target.options[e.target.selectedIndex].text); if (e.target.value === 'other') { setIsAddNewJobModalShow(true) } }
        const getNewJobtitle = (e) => { setSetNewJobTitleValue(e.target.value); }
        var PaySlipsDocuments = []
        var ResumeDocuments = []
        if (typeof (attrData.data.workexperience_payslips) != 'undefined') {
            PaySlipsDocuments = attrData.data.workexperience_payslips;
        }

        const [userUploadPayslipDocumentsContent, setUserUploadPayslipDocumentsContent] = useState(PaySlipsDocuments);
        if (typeof (attrData.data.workexperience_resume) != 'undefined') {
            ResumeDocuments = attrData.data.workexperience_resume;
        }
        const userFileUploadToServerBasic = async (field, file, reference_id = 0) => {
            const maxSizeInBytes = 5 * 1024 * 1024; // For example, 1 MB
            if (file.size > maxSizeInBytes) {
                toast.warning('File size is greater than the specified limit.');
            } else {
                setisFileLoading(true);
                try {
                    const formData = new FormData();
                    formData.append('file', file);
                    formData.append('uploaded_for', field);
                    formData.append('userid', userUniqueID);
                    formData.append('maxSizeInBytes', maxSizeInBytes);
                    formData.append('reference_id', reference_id);
                    const response = await axios.post(
                        `https://squadgoo.com.au/api.squadgoo.com.au/userdetails_file_uploads`,
                        formData, { headers: { 'Content-Type': 'multipart/form-data' } });
                    setisFileLoading(false);
                    if (response.data.status == 1) {
                        toast.success('Uploaded Successfully!');
                        if (field == 'workexperience_payslips') {
                            setUserUploadPayslipDocumentsContent(response.data.data.upload_array)
                        }
                    } else { toast.warning(response.data.message); }
                } catch (error) { setisFileLoading(false); console.error('Error uploading file:', error); }
            }
        };
        //const editFileFromServer = async (field, id) => { }
        const removeFileFromServer = async (field, id) => {
            //if (window.confirm("Do you want to remove this file?")) {
            try {
                const formData = new FormData();
                formData.append('field', field);
                formData.append('file_id', id);
                formData.append('reference_id', 0);
                const response = await axios.post(
                    `https://squadgoo.com.au/api.squadgoo.com.au/userdetails_upload_file_remove`,
                    formData, { headers: { 'Content-Type': 'multipart/form-data' } });
                if (response.data.status == 1) {
                    toast.success('Removed Successfully!');
                    setIsModalShow(0)
                    setUserUploadPayslipDocumentsContent(response.data.data)
                    // if (field == 'workexperience_payslips') { setUserUploadPaySlipDocuments(response.data.data) }
                } else { toast.warning(response.data.message); }
            } catch (error) { console.error('Error uploading file:', error); }
            //}
        }





        const [validated, setValidated] = useState(false);
        const onWorkExperienceSubmit = (event) => {
            event.preventDefault();
            const form = event.currentTarget;
            if (form.checkValidity() === false) {
                event.preventDefault(); event.stopPropagation();
            } else {
                const formData = new FormData(event.target);
                const referenceDataValue = {};
                formData.forEach((value, name) => {
                    if (name.endsWith('[]')) {
                        const inputName = name.slice(0, -2);
                        if (!referenceDataValue[inputName]) {
                            referenceDataValue[inputName] = [value];
                        } else { referenceDataValue[inputName].push(value); }
                    } else { referenceDataValue[name] = value; }
                });
                if (workExperienceData.id) { formData.append('editid', workExperienceData.id); }
                if (userUniqueID) {
                    formData.append('userid', userUniqueID);
                }
                //work_expe_countryname getCurrentCountry
                formData.append('work_expe_countryname', getCurrentCountry);
                formData.append('work_expe_jobtitle_id', defaultJobTitle);
                //userid
                (async () => {
                    axios.post("https://squadgoo.com.au/api.squadgoo.com.au/work_experience_action", formData)
                        .then((response) => {
                            if (response.status == 200 && response.data.status == 1) {

                                toast.success(response.data.message);
                                /*redux validation update */
                                dispatch(workExpDocumentStatus(1));
                                /*redux validation check end  */

                                // dispatch(checkDocumentstatus('add'));
                                getWorkSExperienceLoadCall();
                                cancelWorkexperienceForm();
                                setAddEditJobModalToggle(false)
                            } else {
                                toast.warning(response.data.message);
                            }
                            //setEditDataID(0);
                        })
                        .catch((error) => { console.error(error); });
                })();

            }
            setValidated(true);
        };

        const setWorkExperienceDataFun = (event) => {
            const { name, value } = event.target;

            console.log(name);

            // if(name == 'work_expe_duration_from_year' || name == 'work_expe_duration_to_year'){

            // }
            setWorkExperienceData((prevFormData) => ({
                ...prevFormData,
                [name]: value
            }));
        };



        const [timeDurationFrom, setTimeDurationFrom] = useState('');
        const [timeDurationTo, setTimeDurationTo] = useState('');
        const workExperienceDurationCheck = (e) => {
            const { name, value } = e.target;
        }


        const handleChange = (event, id, fieldName) => {
            console.log('....')
            const updatedRows = workExperienceReferenceData.map((row) => {
                if (row.id === id) {

                    const regex = /^\d+$/; // 10-digit number pattern
                    if (fieldName == 'contactno' && !regex.test(event.target.value)) {
                        console.log(',...')
                        return { ...row, [fieldName]: '' };
                    } else {
                        return { ...row, [fieldName]: event.target.value };
                    }
                }
                return row;
            });

            setWorkExperienceReferenceData(updatedRows);
        };


        /*-------start--------*/


        useEffect(() => {
            //setJobtitleList(sessionStorageData.get_all_jobtitle);
            if (sessionStorageData) {
                setJobtitleList(sessionStorageData.get_jobtitle_options);
            } else {
                (async () => {
                    try {
                        const resData = await axios.get("https://squadgoo.com.au/api.squadgoo.com.au/get_jobtitle_options");
                        if (resData.data.status == 1) {
                            setJobtitleList(resData.data.data.results);
                        }
                    } catch (error) { console.log(error); }
                })();
            }
        }, []);

        //const customStyles = { option: (provided, state) => ({ ...provided, padding: '3px 10px', fontSize: '14px' }), };


        const joBTitleSelected = (select) => {
            setIsAddNewJobModalShow(false);
            setWorkExperienceTitle(select.label);
            setDefaultJobTitle(select.value);
            if (select.value === 'other') { setSetNewJobTitleValue(''); setIsAddNewJobModalShow(true) }
        }


        const addJobTitleInList = (event) => {
            event.preventDefault();
            // const queryParameters = new URLSearchParams(window.location.search)
            const userid = loggedInUser.unique_id;
            setIsAddNewJobtitleSuccessMessage('');
            if (setNewJobTitleValue && setNewJobTitleValue.length > 3) {
                setIsAddNewJobtitleError(false);
                (async () => {
                    try {
                        const resData = await axios.get(`https://squadgoo.com.au/api.squadgoo.com.au/set_new_jobtitle/${userid}/${setNewJobTitleValue}`);
                        if (resData.data.status == 1) {
                            //setJobtitleList(resData.data.data.results);
                            setJobtitleList(resData.data.data.jobtitle_option);
                            setIsAddNewJobModalShow(false);
                            setDefaultJobTitle(resData.data.data.last_id)
                            setIsAddNewJobtitleSuccessMessage(resData.data.message);
                        } else {
                            setIsAddNewJobtitleErrorMessage(resData.data.message)
                        }
                    } catch (error) { console.log(error); }
                })();
            } else {
                setIsAddNewJobtitleError(true);
                setIsAddNewJobtitleErrorMessage('Please enter title');
            }
        }

        /*------end-------*/
        /*----------------------------------------*/
        const getMonthsAfter = (startMonthName) => {
            if (!startMonthName) {
                return Months;
            }
            const normalizedStartMonth = startMonthName.toLowerCase();
            const startIndex = Months.findIndex(month => month.name.toLowerCase() === normalizedStartMonth);
            // Slice the array from 'May' to the end
            const filteredMonths = Months.slice(startIndex);
            return filteredMonths;
        };
        /*----------------------------------------*/
        /*google autocomplete*/
        //const [address, setAddress] = useState(null);
        const [googleAddressValue, setGoogleAddress] = useState(workExperienceData.work_expe_subrub);
        const googleAuotoCompelte = (newAddress) => {
            //setAddress(newAddress);
            console.log(newAddress);
            setGoogleAddress(`${newAddress.placevalue}`)
        };

        const handleInputChange = (e) => {
            setGoogleAddress(e.target.value);
        };

        /*---------------------------------------------*/
        const countryNameChange = (select) => {
            //workExperienceData.work_expe_countryname
            setCurrentCountry(select.value)
            //setWorkExperienceData((prevData) => ({ ...prevData, 'work_expe_countryname': select.value }))
        }

        const [classForTitleError, setClassForTitleError] = useState('');
        const [classForCategoryError, setClassForCategoryError] = useState('');
        const handleJobTitleChange = (selectedJobTitle) => {
            setDefaultJobTitle(selectedJobTitle.value);
            setIsAddNewJobModalShow(false);
            setWorkExperienceTitle(selectedJobTitle.label);
            setDefaultJobTitle(selectedJobTitle.value);
            if (selectedJobTitle.value === 'other') { setSetNewJobTitleValue(''); setIsAddNewJobModalShow(true) }

         };

        return (
            <>
                <Form noValidate validated={validated} onSubmit={onWorkExperienceSubmit} className='row align-items-start editfieldss' >
                    
                    
            {/* {userUniqueID} */}

                <JobTitleCAtegoryComp
                defaultJobID={defaultJobTitle}
                onJobTitleChange={handleJobTitleChange}
                classForCategory={classForCategoryError}
                classForTitle={classForTitleError}
                classContainerName={'col-md-3'}
                />
                    {/* <div className="col-md-4">
                        <input type='hidden' value={workExperienceTitle} name="work_expe_jobtitle" id="work_expe_jobtitle" />
                        <div className="editblock">
                            <div className="editblockinnerbtm">
                                <div className="form-group">
                                    <Select
                                        options={jobTitleList}
                                        isSearchable
                                        placeholder="Search..."
                                        styles={customStyles}
                                        onChange={joBTitleSelected}
                                        value={jobTitleList.flatMap(group => group.options).find(option => option.value == defaultJobTitle)}
                                    >
                                    </Select>
                                </div>
                            </div>
                        </div>
                    </div> */}
                    <div className="col-md-3">
                        <div className="editblock"><label>Company name<span className="starr">*</span> </label>
                            <div className="editblockinnerbtm">
                                <div className="form-group">
                                    <input required={true} onChange={setWorkExperienceDataFun} type="text" name='work_expe_companyname' value={workExperienceData.work_expe_companyname} className="form-control" placeholder="Enter country name" />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-3">
                        <div className="editblock"><label>Country<span className="starr">*</span></label>
                            <div className="editblockinnerbtm">
                                <div className="form-group">
                                    <Select className='react_select' required aria-label="4"
                                        options={getCountryListOption}
                                        isSearchable
                                        placeholder="Search..."
                                        styles={customStyles}
                                        onChange={countryNameChange}
                                        value={getCountryListOption.find(item => item.value == getCurrentCountry)}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>



                    {(workExperienceData.work_expe_countryname == 13) && <div className="col-md-12">
                        <div className="editblock mb-2"><label>Suburb<span className="starr">*</span></label>
                            <div className="editblockinnerbtm">
                                <div className='form-group'>

                                    <GoogleAutocomplete
                                        onSelect={googleAuotoCompelte} required
                                        name='work_expe_subrub'
                                        value={googleAddressValue}
                                        placeHolder='Enter your suburb'
                                        className="form-control"
                                        onChange={handleInputChange}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>}

                    {
                        isAddNewJobModalShow && <>
                            <div className="col-md-12">
                                <div className="form-group mb-2 graybox ">
                                    <label className="form-label">Add Job title <span className="starr">*</span> </label>
                                    <div className={isAddNewJobtitleError ? 'input-group bordercss mb-2' : 'input-group mb-2'}>
                                        <input type="text" required={true} className="form-control" onChange={getNewJobtitle} value={setNewJobTitleValue} name="add_job_title" placeholder="Enter job title" />
                                        <div className='input-group-append'>
                                            <button className='batn batn-orange  add_newjobtitle' onClick={addJobTitleInList} >Add Title</button>
                                        </div>

                                    </div>
                                    {
                                        isAddNewJobtitleErrorMessage && <><div className='error_message'>{isAddNewJobtitleErrorMessage}</div></>
                                    }
                                </div>
                            </div>
                        </>
                    }

                    <div className="col-md-12">
                        <div className="editblock"><label>Work duration<span className="starr">*</span></label>
                            <div className="editblockinnerbtm">
                                <div className="form-group d-flex align-items-center">
                                    <div className="d-flex w-100">
                                        <div className="w-100 mr-2">
                                            <select required={true} className="form-control" onChange={setWorkExperienceDataFun} name="work_expe_duration_from_month">
                                                <MonthsNumberSelection startMonthName='' selectedMonth={workExperienceData.work_expe_duration_from_month} />
                                            </select>
                                        </div>
                                        <div className=" w-100">
                                            <select required={true} className="form-control" name="work_expe_duration_from_year" onChange={setWorkExperienceDataFun} >
                                                <YearSelector selectedYear={workExperienceData.work_expe_duration_from_year} />
                                            </select>
                                        </div>
                                    </div>
                                    <div><label className="form-label label px-3">To</label></div>
                                    <div className="d-flex w-100">
                                        <div className="w-100 mr-2 ">
                                            <select required={true} className="form-control" name="work_expe_duration_to_month" onChange={setWorkExperienceDataFun} >
                                                <MonthsNumberSelection startMonthName={workExperienceData.work_expe_duration_from_month} selectedMonth={workExperienceData.work_expe_duration_to_month} />
                                            </select>
                                        </div>
                                        <div className="w-100">
                                            <select required={true} className="form-control" name="work_expe_duration_to_year" onChange={setWorkExperienceDataFun} >
                                                {
                                                    workExperienceData.work_expe_duration_from_year ?
                                                        <YearSelector startYear={workExperienceData.work_expe_duration_from_year} selectedYear={workExperienceData.work_expe_duration_to_year} />
                                                        :
                                                        <option value={''}>YYYY</option>
                                                }
                                            </select>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="col-md-12">
                        <div className="editblock"><label>Job description<span className="starr">*</span></label>
                            <div className="editblockinnerbtm">
                                <div className="form-group ">
                                    <textarea required={true} type="text" className="form-control" rows={1}
                                        placeholder="Enter job description" name="work_expe_jobdescription" onChange={setWorkExperienceDataFun} value={workExperienceData.work_expe_jobdescription}></textarea></div>
                            </div>
                        </div>
                    </div>


                    {/* reference module */}
                    {
                        (checkExperienceType == 'reference' || true) &&
                        <>
                            <div className="col-md-12" >

                                <div className="editblock"><label>Experience Proof<span className="starr">*</span></label>
                                    <input type='hidden' name="work_expe_prooftype" value={'reference'} />


                                    {workExperienceReferenceData.map((item, index) => (
                                        <div className="editblock graybox refreanceblockk mt-2">
                                            <label className="form-label">Reference {index + 1}
                                                {index == 0 ? <span className="starr">*</span> : ''}
                                                {
                                                    item.admin_varification == 1 ?
                                                        <><span className="badge bg-success ml-2">Verified </span></> :
                                                        <><span className="badge bg-danger ml-2">Not Verified </span></>
                                                }
                                            </label>
                                            {index !== 0 && item.admin_varification != 1 && (<span className="cursor_pointer close_reference_btn" onClick={() => handleRemove(index)}><img src="/front/images/workedit.svg" /></span>)}
                                            <div className="editblockinnerbtm">
                                                <div className="form-group">
                                                    <div className="row">
                                                        <div className="col-md-6 mb-2">
                                                            <input type="text" required={index == 0 ? true : false} className="form-control" onChange={(event) => handleChange(event, item.id, 'name')} value={item.name} placeholder="Name" name="reference_name[]" />
                                                        </div>
                                                        <div className="col-md-6 mb-2">
                                                            <input type="text" required={index == 0 ? true : false} className="form-control" onChange={(event) => handleChange(event, item.id, 'position')} value={item.position} placeholder="Position" name="reference_position[]" />
                                                        </div>
                                                        <div className="col-md-6 mb-2">
                                                            <input type="tel" required={index == 0 ? true : false} className="form-control" maxLength={10} onChange={(event) => handleChange(event, item.id, 'contactno')} value={item.contactno} placeholder="Contact number" name="reference_contactno[]" />
                                                        </div>
                                                        <div className="col-md-6 mb-2">
                                                            <input type="email" required={index == 0 ? true : false} className="form-control" onChange={(event) => handleChange(event, item.id, 'email')} value={item.email} placeholder="Email address" name="reference_email[]" />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    ))}
                                    <div className="addworkexp mt-1" onClick={handleAddMore}>
                                        <span className="cursor_pointer linktxt d-flex align-items-center">
                                            <img className="mr-2" src="/front/images/addwork.svg" />
                                            Add {(workExperienceReferenceData.length > 0) ? 'another' : ''} reference
                                        </span>
                                    </div>
                                </div>

                            </div>
                        </>
                    }
                    {/* payment pay slips upload fun */}
                    {
                        (checkExperienceType == 'payslips' || true) &&
                        <>
                            <div className="col-md-12">
                                <div className="form-group addressproofdoc mb-0 stepscont editblock">
                                    <label className="form-label">{userUploadPayslipDocumentsContent.length > 0 ? 'Uploaded payslips' : 'Upload payslips'} </label>
                                    {
                                        (checkExperienceType != 'reference' || true) &&
                                        <>
                                            <div className="col-md-12 p-0">

                                                <div className="editblockinner d-flex align-items-start">
                                                    <h4>
                                                        {
                                                            (userUploadPayslipDocumentsContent.length > 0) &&
                                                            userUploadPayslipDocumentsContent.map((item, index) => (
                                                                <>


                                                                    <div className="documentwrap d-flex align-items-center ml-0 mt-1">
                                                                        <a className='document_list' target="_blank" href={'https://squadgoo.com.au/api.squadgoo.com.au/' + item.document_url}>
                                                                            <p>Payslip {index + 1}</p>
                                                                        </a>
                                                                        <div className="ml-auto d-flex">
                                                                            <a className='document_list' download={true} target="_blank" href={'https://squadgoo.com.au/api.squadgoo.com.au/' + item.document_url}>
                                                                                <img src="/front/images/download.png" />
                                                                            </a>
                                                                            {/* <span className='cursor_pointer ml-2' onClick={e => removeFileFromServer('workexperience_payslips', item.id)}><img src="/front/images/delete.png" /></span> */}
                                                                            <span className='cursor_pointer ml-2' onClick={e => setIsModalShow(item.id)}><img src="/front/images/delete.png" /></span>
                                                                        </div>
                                                                    </div>


                                                                </>
                                                            ))
                                                        }
                                                    </h4>
                                                </div>
                                            </div>





                                        </>
                                    }
                                    {
                                        userUploadPayslipDocumentsContent.length >= 3 ?
                                            ''
                                            :
                                            <>
                                                <div className="editblockinnerbtm">
                                                    <div className="form-group identityproof">
                                                        <div className="customuploadsec">
                                                            <input type="file" disabled={isFileLoading} accept="image/x-png,image/gif,image/jpeg ,application/pdf" onChange={(e) => userFileUploadToServerBasic('workexperience_payslips', e.target.files[0], workExperienceData.id)} ></input>
                                                            <div className="customuploadinner text-center">
                                                                <div className="my-4"><img src="/front/images/uploadicon.png" className="mt-4"></img></div>
                                                                <p className="mb-0">
                                                                    {
                                                                        isFileLoading ?
                                                                            <>File is uploading....</>
                                                                            :
                                                                            <>Click here to upload a pay slip {userUploadPayslipDocumentsContent.length + 1} </>
                                                                    }

                                                                </p>
                                                            </div>
                                                        </div>
                                                        <p className="mt-1 purpleclr font12 mb-0">Accept only jpg, jpeg, png and pdf files (Max file size 10 MB)</p>
                                                    </div>
                                                </div>
                                            </>
                                    }




                                </div>
                            </div>
                        </>
                    }

                    <div className="editwrapbntns text-left mt-2">
                        <button disabled={isFileLoading} className="batn batn-orange" type="submit" role="button">Save</button>
                        <button disabled={isFileLoading} className="batn batn-orange ml-2" data-bs-dismiss="modal" aria-label="Close" onClick={cancelWorkexperienceForm} type="button" role="button">Cancel</button>
                    </div>
                </Form>

                {/* <Link></Link> */}
                <Modal className="modal fade ask-jobdeclinedmodle" show={isModalShow} onHide={() => setIsModalShow(false)} >
                    <div className="modal-dialog1">
                        <div className="modal-content">
                            <div className="modal-body">
                                <button type="button" className="btn-close" onClick={() => setIsModalShow(false)} ></button>

                                <div style={{ textAlign: "center", fontSize: '55px' }}  >
                                    <i class="fa fa-warning text-danger"></i>
                                </div>
                                <h5 className="text-center mb-2">Do you want to delete payslip?</h5>
                                <div className="btnsgroup text-center">
                                    <Link onClick={() => setIsModalShow((e) => removeFileFromServer('workexperience_payslips', isModalShow))} className="batn batn-orange" >Yes</Link>
                                    <Link onClick={() => setIsModalShow(!isModalShow)} className="batn batn-orange batn-border">No</Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </Modal>
            </>
        )
    }



    const userWorkExpVerification = (id) => {
        (async () => {
            try {
                const response = await axios.get(`https://squadgoo.com.au/api.squadgoo.com.au/user_workexperice_verifications/${loggedInUser.login_token}/${id}`);
                if (response.data.status == 1) {
                    toast.success(response.data.message);
                    getWorkSExperienceLoadCall();
                }
            } catch (error) { console.log(error); }
        })();
    }

    return (
        <>
            {/* <ToastContainer />*/}
            <div className="addworkexp mb-4">
                <span className="cursor_pointer linktxt d-inline-flex align-items-center float-right" onClick={() => setAddEditJobModalToggle(true)} >
                    <img className="ml-2 mr-2" src="/front/images/addwork.svg" />
                    <span>Add new experience </span>
                </span>
            </div>
            <div className="col-md-12">
                <div className="profilerightwraper stepscont commonform basicdetail">
                    {
                        (workExperienceData.length > 0) ?

                            <>
                                {workExperienceData.map((workexp, index) => (
                                    <>
                                        <div key={index} className="card-box work-experiencesec work-experiencesecnew commonform mb-4">
                                            <div className="row align-items-start editfieldss" key={index}>
                                                <div className="col-md-12 d-flex justify-content-between pb-0">
                                                    <h3 className="mb-3 text-capitalize">
                                                        {workexp.work_expe_jobtitle}
                                                    </h3>
                                                    <div className='d-flex align-items-center mt-15px'>
                                                        <div className="d-flex mr-3" >
                                                            {(isHiddenItem != workexp.id) &&
                                                                <div className="editbtn" onClick={(e) => toggleHiddenSection(workexp.id)}  > <img src="/front/images/edit.png" /></div>
                                                            }
                                                        </div>
                                                        {<div className='d-flex align-items-center workexp_very_unvery'>
                                                            <div className="disableprofilewrapinner border-0 verifiywrap">
                                                                {
                                                                    (workexp.is_verified == 1) ?
                                                                        <><span>Verified</span><img className='veriunveri-images' src="/front/images/vfy.png"></img></>
                                                                        :
                                                                        <><span>Unverified</span><img className='veriunveri-images' src="/front/images/uvfy.png"></img></>
                                                                }
                                                                {
                                                                    loggedInUser.user_type == 1 &&
                                                                    <>
                                                                        <input
                                                                            type="checkbox"
                                                                            checked={workexp.is_verified}
                                                                            onChange={() => userWorkExpVerification(workexp.id)}
                                                                        />
                                                                    </>
                                                                }

                                                            </div>
                                                        </div>}
                                                    </div>
                                                </div>


                                                {(isHiddenItem != workexp.id) &&
                                                    <>
                                                        <div className="col-md-4">
                                                            <div className="editblock">
                                                                <label>Job title</label>
                                                                <div className="editblockinner d-flex align-items-start">
                                                                    <h4 className='text-capitalize'>{workexp.work_expe_jobtitle}</h4>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-md-4">
                                                            <div className="editblock">
                                                                <label>Company name</label>
                                                                <div className="editblockinner d-flex align-items-start">
                                                                    <h4 className='text-capitalize'>
                                                                        {workexp.work_expe_companyname}

                                                                    </h4>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-md-4">
                                                            <div className="editblock">
                                                                <label>{(workexp.work_expe_country_name == 'Australia' && workexp.work_expe_subrub) ? 'Suburb' : 'Country'}</label>
                                                                <div className="editblockinner d-flex align-items-start">
                                                                    <h4>
                                                                        {(workexp.work_expe_country_name == 'Australia' && workexp.work_expe_subrub) ? workexp.work_expe_subrub + '(' + workexp.work_expe_country_name + ')' : workexp.work_expe_country_name}

                                                                    </h4>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-md-4">
                                                            <div className="editblock">
                                                                <label>Work duration</label>
                                                                <div className="editblockinner d-flex align-items-start">
                                                                    <h4>
                                                                        <span className="mr-2">{workexp.work_expe_duration_from_month}</span>
                                                                        <span className="mr-2">{workexp.work_expe_duration_from_year}</span>
                                                                        <span className="mr-2">To</span>
                                                                        <span className="mr-2">{workexp.work_expe_duration_to_month}</span>
                                                                        <span>{workexp.work_expe_duration_to_year}</span>
                                                                    </h4>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-md-8">
                                                            <div className="editblock">
                                                                <label>Job description</label>
                                                                <div className="editblockinner d-flex align-items-start">
                                                                    <h4>{workexp.work_expe_jobdescription}</h4>
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div className="col-md-12 ">
                                                            <div className="editblock">
                                                                <label>Experience Proof</label>
                                                                <div className="editblock">
                                                                    <div className="editblockinner">
                                                                        {
                                                                            (workexp.work_expe_prooftype != 'reference' || true) &&
                                                                            <>
                                                                                <h4 className="d-flex align-items-start">
                                                                                    {
                                                                                        workexp.workexperience_payslips.length ?
                                                                                            <>
                                                                                                {
                                                                                                    workexp.workexperience_payslips.map((item, index) => (
                                                                                                        <>
                                                                                                            <div className="documentwrap d-flex align-items-center ml-0 mt-1 mr-2">
                                                                                                                <a className='document_list' target="_blank" href={'https://squadgoo.com.au/api.squadgoo.com.au/' + item.document_url}>
                                                                                                                    <p>Payslip {index + 1}</p>
                                                                                                                </a>
                                                                                                                <div className="ml-auto d-flex">

                                                                                                                    <DoeanloadFileComp url={`https://squadgoo.com.au/api.squadgoo.com.au/${item.document_url}`} />
                                                                                                                    {/* <a className='document_list' download={true} target="_blank" href={'https://squadgoo.com.au/api.squadgoo.com.au/' + item.document_url}>
                                                                                                                    <img src="/front/images/download.png" />
                                                                                                                </a> */}
                                                                                                                    {/* <span className='cursor_pointer ml-2' onClick={e => removeFileFromServer('workexperience_payslips' , item.id ) }><img src="/front/images/delete.png" /></span> */}
                                                                                                                </div>
                                                                                                            </div>
                                                                                                        </>
                                                                                                    ))
                                                                                                }
                                                                                            </>
                                                                                            :
                                                                                            <>
                                                                                                <div className='col-md-12 pb-0'>
                                                                                                    <h4 className='p-0 mb-0 editblockinner'>Pay slip not uploaded</h4>
                                                                                                </div>
                                                                                            </>
                                                                                    }
                                                                                </h4>
                                                                            </>
                                                                        }
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        {
                                                            (workexp.workexperience_referencetype.length > 0 && workexp.work_expe_prooftype == 'reference') ?
                                                                <>
                                                                    {
                                                                        workexp.workexperience_referencetype.map((reference, index_ref) => (
                                                                            <>
                                                                                <div className="col-md-4">
                                                                                    <div className="editblock">
                                                                                        <label>References {index_ref + 1} {reference.admin_varification == 1 ? <><span className="badge bg-success">Verified </span></> : <><span className="badge bg-danger">Not Verified </span></>}</label>
                                                                                        <div className="refferancbolok spacindsec editblockinner d-flex align-items-start">
                                                                                            <h4>
                                                                                                <div><span>Full Name:</span> <span className='text-capitalize'>{reference.name}</span></div>
                                                                                                <div><span>Position:</span> {reference.position}</div>
                                                                                                <div><span>Contact number:</span> {reference.contactno}</div>
                                                                                                <div><span>Email address:</span> {reference.email}</div>
                                                                                            </h4>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </>
                                                                        ))
                                                                    }
                                                                </>
                                                                : ''
                                                        }

                                                        {/* this is pay slips section */}
                                                        <div className="col-md-12">

                                                        </div>
                                                    </>
                                                }


                                                {(isHiddenItem == workexp.id) &&
                                                    <AddAndEditFormBtn data={workexp} />
                                                }

                                            </div>
                                        </div>
                                    </>
                                ))}

                                {/* workExperienceData.length > 3 && */}
                                {
                                    totalExpLength > 3 && <p className='load_more_exp_link'>
                                        <NavLink onClick={() => getMoreExperenc(dataIsLoad ? 'hide' : 'load')} className={`text_safron`} >{dataIsLoad ? 'Hide Experiences' : 'Load more ...'}</NavLink>
                                    </p>
                                }

                            </>
                            :
                            <>
                                <div className="card-box work-experiencesec commonform mb-4">
                                    <div className="row align-items-start editfieldss" >
                                        {
                                            isDataLoading ?
                                                <><p className='text-center'><img className='width-100' src={'/front/images/loader.gif'} /></p><div className={`model_loader display_none ${!isDataLoading ? 'display_none' : ''}`}>
                                                    <img src={'/front/images/loader.gif'} />
                                                </div></> :
                                                <><AddAndEditFormBtn data={[]} /></>
                                        }
                                    </div>
                                </div>
                            </>
                    }
                </div>
            </div>



            <Modal className='wordkExperienceAddModel' show={addEditJobModalToggle} size="lg" onHide={() => setAddEditJobModalToggle(false)}>
                <div className="modal-dialog1 modal-lg">
                    <div className="modal-content">
                        <div className="modal-body">
                            <button type="button" className="btn-close" onClick={() => setAddEditJobModalToggle(false)} ></button>
                            <AddAndEditFormBtn data={[]} />
                        </div>
                    </div>
                </div>
            </Modal>


        </>
    );
}
export default WorkExpComp;