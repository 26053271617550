import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route, NavLink, useParams, useNavigate } from 'react-router-dom';
import { Form, Button } from 'react-bootstrap';
import axios from 'axios';
// import TimerComponent from '../../expocomp/TimerComp';

const Varification = () => {

  const [userUniqueId, setUserUniqueId] = useState('');
  const [varificationCode, setVarificationCode] = useState('');
  const [validated, setValidated] = useState(false);
  const [timeIntervalSecond , setTimeIntervalSecond] = useState(0);
  const [serverResMessage, setServerResMessage] = useState('');
  const [serverResMessageSuccess, setServerResMessageSuccess] = useState('');
  const [nextStep, setNextStep] = useState('signup');


  const navigate = useNavigate();
  useEffect(() => {
    const queryParameters = new URLSearchParams(window.location.search)
    const candidate = queryParameters.get("id");

    const status = queryParameters.get("status");
    setNextStep(status);
    // get_userdata("https://api.careerhamster.com/userdetails/"+candidate)
    window.scrollTo(0, 0);

    
    (async () => {
      try {
        const responceData = await axios.get("https://squadgoo.com.au/api.squadgoo.com.au/userdetails/" + candidate);
        if (responceData.data.status == 1) { 

            let expire_seconds  = responceData.data.data.results.otp_will_expire_seconds ;
            setUserUniqueId(candidate); 
            setTimeIntervalSecond(expire_seconds);
            resendVerificationCode(1,candidate ,expire_seconds );
        }
        else { navigate('/login') } 
      } catch (error) { console.log(error); }
    })();
  }, [])

  const handleSubmit = (event) => {
    event.preventDefault();
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.stopPropagation();
    } else {
      const formData = new FormData();
      formData.append('unique_id', userUniqueId);
      formData.append('contact_varification_code', varificationCode);
      axios.post('https://squadgoo.com.au/api.squadgoo.com.au/check_contact_activation', formData)
        .then((response) => {
          if (response.data.status == 1) {
            const status = '';
            // (nextStep == 'emailverification') ?
            //   navigate('/verified?status=' + nextStep + '&id=' + userUniqueId) :
            //   navigate('/verified?id=' + userUniqueId)
            //   ;
            navigate('/verified?id=' + userUniqueId)

          } else { setServerResMessage(response.data.message); setTimeout(function () { setServerResMessage('') }, 3000); }
        })
        .catch((error) => { console.error(error); });
    }
    setValidated(true);
  };


  /*-------------------------------------------*/
  const resendVerificationCode = (callingtype,unique_id , intervalSecond) => {
    const formData = new FormData();
    formData.append('unique_id', unique_id ? unique_id : userUniqueId);
    //formData.append('email_varification_code', varificationCode);
    formData.append('contact_varification_code', varificationCode);
    axios.post('https://squadgoo.com.au/api.squadgoo.com.au/contact_resend_verification', formData)
      .then((response) => {
        //serverResMessageSuccess, setServerResMessageSuccess
        if(callingtype == 2){
          setServerResMessageSuccess(response.data.message); 
        }
        setSeconds(intervalSecond ? intervalSecond : timeIntervalSecond);
        setTimeout(function () { setServerResMessageSuccess('') }, 3000);
      })
      .catch((error) => { console.error(error); });
  }


  /*---------------------------------------------------------------*/
  const [seconds, setSeconds] = useState(timeIntervalSecond); // 300 seconds for 5 minutes
  const [isRunning, setIsRunning] = useState(true);
  useEffect(() => {
    let interval = null;
    if (isRunning && seconds > 0) {
      interval = setInterval(() => { setSeconds((prevSeconds) => prevSeconds - 1); }, 1000);
    } else if (!isRunning && interval !== null) {  clearInterval(interval);  }

    return () => clearInterval(interval); // Cleanup on component unmount
  }, [isRunning, seconds]);



  const formatTime = (seconds) => {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;
    return `${minutes}:${remainingSeconds < 10 ? '0' : ''}${remainingSeconds}`;
  };
  const styleComp  = {fontSize: '13px' ,color: 'red'}

  return (
    <>

      <div className="loginsignupwrap">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="loginsignupwrapformwrap">
                <Form noValidate validated={validated} onSubmit={handleSubmit} className="commonform lofinsignupform loginform">
                  <div className="formbranding text-center"><img src="/front/images/formlogo.png" /></div>
                  <div className="text-center my-4"><img src="/front/images/varifyicon.png" /></div>
                  <h2 className="text-center mb-2">Verify your contact number</h2>
                  <div className="text-center formbtmtxt mb-4">
                    <p>We have sent a code on your contact number</p>
                  </div>
                  <div className="form-group mb-2">
                    <input
                      required
                      type="text"
                      onChange={(event) => setVarificationCode(event.target.value)}
                      className="form-control verificationfield"
                      placeholder="Enter varification code"
                      style={{ paddingLeft: '8px' }}
                    />
                    
                    {(seconds > 0)  ? <span style={styleComp}>{(seconds ? 'Expire will be: ' : 'Expired: ')+formatTime(seconds)}</span> :
                      <><span className="text-orange font13px cursor_pointer" onClick={() => resendVerificationCode(2,userUniqueId,timeIntervalSecond)} >Re-send verification code</span></>
                    }
                  </div>
                  {serverResMessage ? <p className="alert alert-danger text-center  mb-0">{serverResMessage}</p> : ''}
                  {serverResMessageSuccess ? <p className="alert alert-success text-center  mb-0">{serverResMessageSuccess}</p> : ''}
                  <div className="form-group mb-2"><input type="Submit" className="batn batn-orange" value="Verify" /></div>
                  <div className="text-center formbtmtxt">
                    <p>Already have an account?  <NavLink className="" to="/login">Log In</NavLink>
                    </p>
                  </div> 
                </Form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
export default Varification;