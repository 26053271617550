import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import { NavLink } from 'react-router-dom';
import { Form, Modal } from 'react-bootstrap';
import { toast } from 'react-toastify';

import { useDispatch } from 'react-redux';
import { preferedJobValidationCheck } from '../redux_state/reducres/checkDocumentstatus';
import JOB_SEARCH_TYPE  from '../json/jobsearchtype.json';
import AVAILABILITY_JSON from '../json/availability.json'
import Select from 'react-select';
import customStyles from '../expocomp/SelectStyleComp';
import JobTitleCAtegoryComp from '../expocomp/JobTitleCAtegoryComp';

const PrefferdJobComp = (getdata) => {
    const userdetails = JSON.parse(localStorage.getItem("squadGologin"));
    const [userUniqueID, setuserUniqueID] = useState((typeof (getdata.attr) == 'undefined') ? userdetails.unique_id : getdata.attr);
    const [isCllingAdmin, setisCllingAdmin] = useState((typeof (getdata.attr) == 'undefined') ? false : true);
    const [addEditJobModalToggle , setAddEditJobModalToggle] = useState(false)
    const [getAllJobtitle, setAllJobtitle] = useState([]);
    const [jobTypeList, setJobTypeList] = useState([]);
    const [jobWntToApplyArray, setjobWntToApplyArray] = useState([]);
    const [isDataLoading, setIsDataLoading] = useState(true);
    const [totalExpLength, settotalExpLength] = useState(false);//getsize
    const [jobTitleList, setJobtitleList] = useState([]);
    const [dataIsLoad, setDataIsLoad] = useState(false);
    const [isHidden, setIsHidden] = useState(true);
    const toggleHidden = () => { setIsHidden(!isHidden); };
    const [isHiddenItem, setIsHiddenItem] = useState(true);
    const toggleHiddenSection = (itemid) => { (isHiddenItem) ? setIsHiddenItem(itemid) : setIsHiddenItem(true); }
    const cancelWorkexperienceForm = () => { setIsHiddenItem(true); }
   
    const dispatch = useDispatch();

    const YearExpNumberLoop = (attr = '') => { const itemElements = []; itemElements.push(<option value="">Years</option>); for (let i = 0; i < 50; i++) { itemElements.push(<option selected={(attr != '' && attr.select_attr == i) ? true : false} value={i}>{i} {i == 1 ? 'Year' : 'Years'}</option>); } return itemElements }
    const MonthExpNumberLoop = (attr = '') => { const itemElements = []; itemElements.push(<option value="">Month</option>); for (let i = 0; i < 12; i++) { itemElements.push(<option selected={(attr != '' && attr.select_attr == i) ? true : false} value={i}>{i} Month{i == 1 ? '' : 's'}</option>); } return itemElements }

    const sessionStorageData = JSON.parse(localStorage.getItem("sessionStorageData"));
    

    const getJobsWantToJobFun = (APICALL = `https://squadgoo.com.au/api.squadgoo.com.au/get_job_wanttoapply/${userUniqueID}`) => {
        setIsDataLoading(true);
        (async () => {
            try {
                const resData = await axios.get(APICALL);
                if (resData.data.status == 1) { setIsDataLoading(false); setjobWntToApplyArray(resData.data.data.results); settotalExpLength(resData.data.data.getsize); }
            } catch (error) { console.log(error); }
        })();
    }





    useEffect(() => {
        getJobsWantToJobFun()
        if (sessionStorageData) {
            setAllJobtitle(sessionStorageData.get_all_jobtitle);
            setJobtitleList(sessionStorageData.get_jobtitle_options);
            setJobTypeList(sessionStorageData.get_all_jobtype);
        } else {
            (async () => {
                try {
                    const resData = await axios.get("https://squadgoo.com.au/api.squadgoo.com.au/get_jobtitle_options");
                    if (resData.data.status == 1) { setJobtitleList(resData.data.data.results); }
                } catch (error) { console.log(error); }
                try {
                    const resData = await axios.get("https://squadgoo.com.au/api.squadgoo.com.au/get_all_jobtitle");
                    if (resData.data.status == 1) { setAllJobtitle(resData.data.data.results); }
                } catch (error) { console.log(error); }
            })();
        }
    }, []);


    
    const getMoreWantToJob = (type) => {
        if (type == 'load') {
            setDataIsLoad(true);
            getJobsWantToJobFun(`https://squadgoo.com.au/api.squadgoo.com.au/get_job_wanttoapply/${userUniqueID}?limit=10`);
        } else {
            setDataIsLoad(false);
            getJobsWantToJobFun(`https://squadgoo.com.au/api.squadgoo.com.au/get_job_wanttoapply/${userUniqueID}`);
        }
    }


    const AddAndEditFormBtn = (attrData) => {
        const inputRefs = useRef([]);
        const inputRefs2 = useRef([]);
        const [activeIndex, setActiveIndex] = useState(null);

        const weekNameArray1 = attrData.data.availability_data ? attrData.data.availability_data : AVAILABILITY_JSON

        const [wntToApplyArrayData, setWntToApplyArrayData] = useState(attrData.data);
        const [isAddNewJobtitleError, setIsAddNewJobtitleError] = useState(false);
        const [isAddNewJobModalShow, setIsAddNewJobModalShow] = useState(false);
        const [isAddNewJobtitleErrorMessage, setIsAddNewJobtitleErrorMessage] = useState('');
        const [defaultJobTitle, setDefaultJobTitle] = useState(wntToApplyArrayData.job_title);
        const [setNewJobTitleValue, setSetNewJobTitleValue] = useState('');
        const [inputValue, setInputValue] = useState('');
        let locationArray = attrData.data.prefered_location_array ? attrData.data.prefered_location_array : [];
        const [locationTagArray, setLocationTagArray] = useState(locationArray);
        const [weekNameArray, setWeekNameArray] = useState(weekNameArray1);
        const [validated, setValidated] = useState(false);
        //const [setNewJobTitleValue, setSetNewJobTitleValue] = useState('');
        const [workExperienceTitle, setWorkExperienceTitle] = useState('');

        const handleInputKeyDown = (event) => { if (event.key === 'Enter' && inputValue !== '') { event.preventDefault(); setLocationTagArray([...locationTagArray, inputValue]); setInputValue(''); } };
        //const handleTagRemove = (tagToRemove) => { const updatedTags = locationTagArray.filter((tag) => tag !== tagToRemove); setLocationTagArray(updatedTags); };
       

        const [isVelidate, setisVelidate] = useState({
            job_title: false,
            job_type: false,
            total_experience_year: false,
            total_experience_months: false,
            expect_salary_from: false,
            expect_salary_to: false,
            preferred_location: false,
        });
        /********tags input**********/
        
        const handleInputChange = (event) => {
            setInputValue(event.target.value);
            setisVelidate((pre) => ({ ...pre, 'preferred_location': true }));
            if (locationTagArray.length > 0) { setisVelidate((pre) => ({ ...pre, 'preferred_location': false })); }
        };
        
        const setWntToApplyArrayDataFun = (event) => {
            const { name, value } = event.target;
            var ValidatedToggle = false;
            setisVelidate((pre) => ({ ...pre, 'expect_salary_from': false }));
            setisVelidate((pre) => ({ ...pre, 'expect_salary_to': false }));

            if (name == 'expect_salary_from' && value.length == 0) {
                setisVelidate((pre) => ({ ...pre, 'expect_salary_from': true }));
                ValidatedToggle = true;
            }
            if (name == 'expect_salary_to' && value.length == 0) {
                setisVelidate((pre) => ({ ...pre, 'expect_salary_to': true }));
                ValidatedToggle = true;
            }

            //if (ValidatedToggle) { return false; }
            if (name == 'expect_salary_from' && value.length >= 6 || name == 'expect_salary_to' && value.length >= 6) {
                return false;
            }
            setWntToApplyArrayData((prevFormData) => ({ ...prevFormData, [name]: value }));
        };

        const handleBlur = () => {
            const minSalary = wntToApplyArrayData.expect_salary_from;
            const maxSalary = wntToApplyArrayData.expect_salary_to;
            console.log(minSalary);
            if (minSalary !== '' && maxSalary !== '' && parseInt(minSalary, 10) > parseInt(maxSalary, 10)) {
                const temp = minSalary;
                setWntToApplyArrayData((prevFormData) => ({ ...prevFormData, ['expect_salary_from']: maxSalary }));
                setWntToApplyArrayData((prevFormData) => ({ ...prevFormData, ['expect_salary_to']: temp }));
            }
        };


     




        const checkValueofDay = (key, type, value, index_key) => {
            const newArray = [...weekNameArray];
            const index = newArray.findIndex(item => item.key === key);
            if (index !== -1) {

                if (newArray[index].from_value && newArray[index].to_value) {
                    newArray[index].checked = true;
                }
                console .log(type);
                if (type === 'from') { newArray[index].from_value = value; inputRefs2.current[index].focus(); }
                else if (type === 'to') { newArray[index].to_value = value; }
                else if (type === 'checkbox') {

                    newArray[index].checked = value;

                    newArray[index].from_value = "";
                    newArray[index].to_value = "";
                    if(value == true &&  !newArray[index].from_value && !newArray[index].to_value){
                        newArray[index].from_value = "00:00";
                        newArray[index].to_value = "23:59";
                        inputRefs.current[index].focus();
                        setActiveIndex(index);
                    }
                    //alert();
                    // newArray[index].checked = value;
                    // if (value == false) {
                    //     newArray[index].from_value = ''; newArray[index].to_value = '';
                    // }
                    // newArray[index].checked = false;
                    // if (newArray[index].from_value && newArray[index].to_value) {
                    //     newArray[index].checked = true;
                    // }
                }
                
                setWeekNameArray(newArray);
            }
        }

        const copyFromAndToTime = (index, key) => {
            const newArray = [...weekNameArray];
            const index_key = newArray.findIndex(item => item.key === key);
            if (index_key !== -1) {
                const newArray = weekNameArray.map(obj => ({
                    ...obj,
                    ['from_value']: weekNameArray[index_key].from_value,
                    ['to_value']: weekNameArray[index_key].to_value,
                    ['checked']: true
                }));
                setWeekNameArray(newArray);
            }
        }

        
        const handleSubmit = (event) => {
            const form = event.currentTarget;
            var ValidatedToggle = false;
            if (!event.target.job_type.value) {
                setisVelidate((pre) => ({ ...pre, 'job_type': true }));
                ValidatedToggle = true;
            }

            if (!event.target.total_experience_year.value) {
                setisVelidate((pre) => ({ ...pre, 'total_experience_year': true }));
                ValidatedToggle = true;
            }

            if (!event.target.total_experience_months.value) {
                setisVelidate((pre) => ({ ...pre, 'total_experience_months': true }));
                ValidatedToggle = true;
            }

            if (!event.target.expect_salary_from.value) {
                setisVelidate((pre) => ({ ...pre, 'expect_salary_from': true }));
                ValidatedToggle = true;
            }

            if (!event.target.expect_salary_to.value) {
                setisVelidate((pre) => ({ ...pre, 'expect_salary_to': true }));
                ValidatedToggle = true;
            }

            if (locationTagArray.length == 0) {
                setisVelidate((pre) => ({ ...pre, 'preferred_location': true }));
                ValidatedToggle = true;
            }


            if (form.checkValidity() === false && ValidatedToggle == true) {
                event.preventDefault();
                event.stopPropagation();

            } else {
                event.preventDefault();
                const formData = new FormData(event.target);
                const wanttoApplyDataValue = {};
                formData.forEach((value, name) => {
                    if (name.endsWith('[]')) {
                        const inputName = name.slice(0, -2);
                        if (!wanttoApplyDataValue[inputName]) {
                            wanttoApplyDataValue[inputName] = [value];
                        } else { wanttoApplyDataValue[inputName].push(value); }
                    } else { wanttoApplyDataValue[name] = value; }
                });
                formData.append('prefered_location', locationTagArray);
                formData.append('job_title', defaultJobTitle);
                if (wntToApplyArrayData.id) { formData.append('editid', wntToApplyArrayData.id); }
                if (userUniqueID) { formData.append('userid', userUniqueID); }

                (async () => {
                    axios.post("https://squadgoo.com.au/api.squadgoo.com.au/user_job_wanttoapply", formData)
                        .then((response) => {
                            if (response.status == 200 && response.data.status == 1) {

                                getJobsWantToJobFun();
                                cancelWorkexperienceForm();
                                dispatch(preferedJobValidationCheck(1));

                                toast.success(response.data.message);
                                //get_requesteddata()
                                setAddEditJobModalToggle(false)
                            } else {
                                toast.warning(response.data.message);
                            }
                            //setEditDataID(0);
                        })
                        .catch((error) => { console.error(error); });
                })();
            }

            setValidated(true);
        };




        /********************/
        
        const getJobTitleValue = (e) => {
            setIsAddNewJobModalShow(false);
            setSetNewJobTitleValue('');
            if (e.target.value === 'other') { setIsAddNewJobModalShow(true) }
        }

        const getNewJobtitle = (e) => { setSetNewJobTitleValue(e.target.value); }

        const addJobTitleInList = (event) => {
            event.preventDefault();
            const userid = userUniqueID;
            if (setNewJobTitleValue && setNewJobTitleValue.length > 3) {
                setIsAddNewJobtitleError(false);
                (async () => {
                    try {
                        const resData = await axios.get(`https://squadgoo.com.au/api.squadgoo.com.au/set_new_jobtitle/${userid}/${setNewJobTitleValue}`);
                        if (resData.data.status == 1) {
                            setAllJobtitle(resData.data.data.results);
                            setIsAddNewJobModalShow(false);
                            setDefaultJobTitle(resData.data.data.last_id)
                        } else { setIsAddNewJobtitleErrorMessage(resData.data.message);  }
                    } catch (error) { console.log(error); }
                })();
            } else { setIsAddNewJobtitleError(true);  setIsAddNewJobtitleErrorMessage('Please enter title'); }
        }

        const joBTitleSelected = (select) => {
            setIsAddNewJobModalShow(false);
            setWorkExperienceTitle(select.label);
            setDefaultJobTitle(select.value);
            console.log(select);
            if (select.value === 'other') { setSetNewJobTitleValue(''); setIsAddNewJobModalShow(true) }
         }

         const [classForTitleError, setClassForTitleError] = useState('');
         const [classForCategoryError, setClassForCategoryError] = useState('');
         const handleJobTitleChange = (selectedJobTitle) => {
             setDefaultJobTitle(selectedJobTitle.value);
             setIsAddNewJobModalShow(false);
             setWorkExperienceTitle(selectedJobTitle.label);
             setDefaultJobTitle(selectedJobTitle.value);
            
             if (selectedJobTitle.value === 'other') { setSetNewJobTitleValue(''); setIsAddNewJobModalShow(true) }
 
          };

        return (
            <>
                <Form noValidate validated={validated} onSubmit={handleSubmit} className='row align-items-start editfieldss' >
                    {/* <div className="col-md-6">
                        <div className="editblock">
                            <label>Job title<span className="starr">*</span></label>
                            <div className="editblockinnerbtm">
                                <div className="form-group">
                                        
                                    <Select
                                        options={jobTitleList}
                                        className={'react_select2'}
                                        isSearchable
                                        placeholder="Search..."
                                        styles={customStyles}
                                        onChange={joBTitleSelected}
                                        value={jobTitleList.find(item => item.value == defaultJobTitle)}
                                    >
                                    </Select>
                                </div>

                               
                            </div>
                        </div>
                    </div> */}
                    <JobTitleCAtegoryComp
                        defaultJobID={defaultJobTitle}
                        onJobTitleChange={handleJobTitleChange}
                        classForCategory={classForCategoryError}
                        classForTitle={classForTitleError}
                        classContainerName={'col-md-3'}
                    />
                    <div className="col-md-3">
                        <div className="editblock">

                            <label className="form-label">Job type<span className="starr">*</span></label>
                            <div className="editblockinnerbtm">
                                <div className="d-flex form-group">
                                    {/* <select required={true} name="job_type" className="form-control" aria-label="Default select example"> */}
                                    <select
                                        required={true}
                                        name="job_type"
                                        className="form-control"
                                    >
                                        <option value=''>Select</option>
                                        {
                                            jobTypeList.map((item, index) => (
                                                <option selected={wntToApplyArrayData.job_type == item.id} value={item.id} datatitle={item.jobtype} >{item.jobtype}</option>
                                            ))
                                        }
                                    </select>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-3">
                        <div className="editblock">

                            <label className="form-label">Job Search type<span className="starr">*</span></label>
                            <div className="editblockinnerbtm">
                                <div className="d-flex form-group">
                                    {/* <select required={true} name="job_type" className="form-control" aria-label="Default select example"> */}
                                    <select id="jobSearchType" name="job_search_type" className="form-control" >
                                        {JOB_SEARCH_TYPE.map((option) => (
                                        <option selected={wntToApplyArrayData.job_search_type == option.value ? true : false } key={option.value} value={option.value}>
                                            {option.name}
                                        </option>
                                        ))}
                                    </select>
                                </div>
                            </div>
                        </div>
                    </div>

                    {
                        isAddNewJobModalShow && <>
                        <div className="col-md-12">
                            <div className="form-group graybox ">
                                <label className="form-label">Add Job title <span className="starr">*</span> </label>
                                <div className={isAddNewJobtitleError ? 'input-group bordercss mb-2' : 'input-group mb-2'}>
                                    <input type="text" required={true} className="form-control" onChange={getNewJobtitle} value={setNewJobTitleValue} name="add_job_title" placeholder="Enter job title" />
                                    <div className='input-group-append'>
                                        <button className='batn batn-orange  add_newjobtitle' onClick={addJobTitleInList} >Add Title</button>
                                    </div>

                                </div>
                                {
                                    isAddNewJobtitleErrorMessage && <><div className='error_message'>{isAddNewJobtitleErrorMessage}</div></>
                                }
                            </div>
                            </div>
                        </>
                    }
                    <div className="col-md-6">
                        <div className="editblock">
                            <label>Total experience<span className="starr">*</span></label>

                            <div className="editblockinnerbtm">
                                <div className="d-flex form-group">
                                    <div className="mr-2 w-100">
                                        <select required={true} className="form-control" name="total_experience_year" >
                                            {<YearExpNumberLoop select_attr={wntToApplyArrayData.total_experience_year} />}
                                        </select>
                                    </div>
                                    <div className="w-100">
                                        <select required={true} className="form-control" name="total_experience_months">
                                            {<MonthExpNumberLoop select_attr={wntToApplyArrayData.total_experience_months} />}
                                        </select>
                                    </div>
                                </div>
                            </div>


                        </div>
                    </div>
                    <div className="col-md-6 mt-0">
                        <div className="editblock">
                            <label>Expected salary<span className="starr">*</span></label>
                            <div className="editblockinnerbtm">

                                <div className="d-flex form-group">

                                    <div className="mr-2 w-100">
                                        <div className="salaryblock">
                                            <div className={`input-group salaryfield ${isVelidate.expect_salary_from ? 'bordercss' : ''}`}>
                                                <div className="input-group-prepend">
                                                    <div className="input-group-text">Min $</div>
                                                </div>
                                                <input required={true} type="number" onBlur={handleBlur} onChange={(e) => { setWntToApplyArrayDataFun(e); }}  maxlength="1" name='expect_salary_from' value={wntToApplyArrayData.expect_salary_from} className="form-control" placeholder="0" />
                                                <p>/per hour</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="w-100">
                                        <div className="salaryblock">
                                            <div className={`input-group salaryfield ${isVelidate.expect_salary_to ? 'bordercss' : ''}`}>
                                                <div className="input-group-prepend">
                                                    <div className="input-group-text">Max $</div>
                                                </div>
                                                <input type="number" onBlur={handleBlur} onChange={(e) => { setWntToApplyArrayDataFun(e); }} required={true} maxlength="3" className="form-control" placeholder="000" name='expect_salary_to' value={wntToApplyArrayData.expect_salary_to} />
                                                <p>/per hour</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                    <input
                        type="hidden"
                        value={inputValue}
                        onChange={handleInputChange}
                        onKeyDown={handleInputKeyDown}
                        className="form-control"
                        style={{ width: '300px' }}
                        placeholder="Write your location and press ENTER"
                    />

                    <div className="col-md-12">
                        <div className="editblock">
                            <label>Availability to work</label>
                            <div className="editblockinnerbtm">
                                <div className="form-group">
                                    <div className="form-group">
                                        {
                                            weekNameArray.map((item, index) => (
                                                <div className="availability-sec d-flex align-items-center  mb-2" key={item.name}>
                                                    <div className="checkboxwraper">
                                                        <input type="checkbox" name={`availability[${item.key}][checkebox][]`} onChange={(e) => checkValueofDay(item.key, 'checkbox', e.target.checked, index)} value={item.from_value} checked={item.checked} ></input>
                                                        <span className="checkspan"></span>
                                                    </div>
                                                    <p>{item.name}</p>
                                                    <input  ref={(el) => (inputRefs.current[index] = el)} type="time" name={`availability[${item.key}][from][]`} onChange={(e) => checkValueofDay(item.key, 'from', e.target.value, index)} value={item.from_value} className={`form-control ${activeIndex === index ? 'availibility_active_from' : ''}`} placeholder="00:00 am" ></input>
                                                    <div className="subheadpera">To</div>
                                                    <input  ref={(el) => (inputRefs2.current[index] = el)} type="time" name={`availability[${item.key}][to][]`} onChange={(e) => checkValueofDay(item.key, 'to', e.target.value, index)} value={item.to_value} className={`form-control ${activeIndex === index ? 'availibility_active_from' : ''}`} placeholder="00:00 pm"></input>
                                                    {
                                                        (item.from_value && item.to_value) ?
                                                            <>
                                                                <span className="copyin ml-3 cursor_pointer infowrap" onClick={(e) => copyFromAndToTime(index, item.key)}><img src="/front/images/copuicon.png" /><span className="onhoverinfo">Duplicate the same time for week availability</span></span>
                                                            </>
                                                            : ''
                                                    }
                                                </div>
                                            ))
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="editwrapbntns text-left mt-2">
                        <button className="batn batn-orange" type="submit" role="button" >Save</button>
                        {console.log(attrData.data.length)}
                        {/* <button type="button" className="batn batn-orange ml-2 " data-bs-dismiss="modal" aria-label="Close">Cancel</button> */}
                        {(attrData.data.length != 0) && <button className="batn batn-orange ml-2 " data-bs-dismiss="modal" aria-label="Close" onClick={cancelWorkexperienceForm} type="button" role="button" >Cancel</button>}
                    </div>
                </Form>
            </>
        )
    }

    return (
        <>
            <div className="addworkexp mb-4">
                {/* <span className="cursor_pointer linktxt d-inline-flex align-items-center float-right" data-bs-toggle="modal" data-bs-target="#docuploadmodal">
                    <img className="ml-2 mr-2" src="/front/images/addwork.svg" />
                    <span>Add new job</span>
                </span> */}
                
                <span className="cursor_pointer linktxt d-inline-flex align-items-center float-right" onClick={() => setAddEditJobModalToggle(true)}>
                    <img className="ml-2 mr-2" src="/front/images/addwork.svg" />
                    <span>Add new job</span>
                </span>
            </div>
            <div className="profilerightwraper stepscont commonform basicdetail">
                {isHidden &&
                    <>
                        {
                            jobWntToApplyArray.length ?
                                <>
                                    {jobWntToApplyArray.map((item, index) => (
                                        <>
                                            <div key={index} className="card-box work-experiencesec work-experiencesecnew commonform mb-4">
                                                <div className="row align-items-start editfieldss" key={index}>

                                                    <div className="col-md-12 d-flex justify-content-between pb-0">
                                                        <h3 className="mb-3 text-capitalize">{item.job_id_title}</h3>
                                                        {(isHiddenItem != item.id) &&
                                                            <div className="editbtn cursor_pointer" onClick={(e) => toggleHiddenSection(item.id)}  > <img src="/front/images/edit.png" /></div>
                                                        }
                                                    </div>

                                                    {(isHiddenItem != item.id) &&
                                                        <>
                                                  
                                                            <div className="col-md-3">
                                                                <div className="editblock">
                                                                    <label>Job title</label>
                                                                    <div className="editblockinner d-flex align-items-start">
                                                                        <h4>{item.job_id_title}</h4>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="col-md-3">
                                                                <div className="editblock">
                                                                    <label>Job type</label>
                                                                    <div className="editblockinner d-flex align-items-start">
                                                                        <h4>{item.job_type_title}</h4>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="col-md-3">
                                                                <div className="editblock">
                                                                    <label>Total experience</label>
                                                                    <div className="editblockinner d-flex align-items-start">
                                                                        <h4>
                                                                            <span>{item.total_experience_year} {item.total_experience_year == 1 ? 'Year' : 'Years'}</span> ,
                                                                            <span> {item.total_experience_months} {item.total_experience_year == 1 ? 'Month' : 'Months'}</span>
                                                                        </h4>
                                                                    </div>

                                                                </div>
                                                            </div>
                                                            <div className="col-md-3">
                                                                <div className="editblock">
                                                                    <label>Expected salary</label>
                                                                    <div className="editblockinner d-flex align-items-start">
                                                                        <h4>
                                                                            <span className="mr-2">{item.expect_salary_from ? '$' + item.expect_salary_from_money + '/hour' : '...'} </span>
                                                                            <span className="mr-2">To</span>
                                                                            <span className="mr-2">{item.expect_salary_to ? '$' + item.expect_salary_to_money + '/hour' : '...'} </span>
                                                                        </h4>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                          
                                                            <div className="col-md-12">
                                                                <div className="editblock">
                                                                    <label>Availability to work</label>
                                                                    <div className="availabilityedit spacindsec  editblockinner d-flex align-items-start mb-3">
                                                                        <h4>
                                                                            {
                                                                                (item.availability.length > 0) ?
                                                                                    <>
                                                                                        {
                                                                                            item.availability.map((dayitem, dayindex) => (
                                                                                                <div>
                                                                                                    <span className="mr-2 text-capitalize">{dayitem.day_name}</span>
                                                                                                    <span className="mr-2">{dayitem.availability_from_time ? dayitem.availability_from_time : 'Nill'}</span>
                                                                                                    <span className="mr-2"> To </span>
                                                                                                    <span className="mr-2"> {dayitem.availability_to_time ? dayitem.availability_to_time : 'Nill'}</span>
                                                                                                </div>
                                                                                            ))
                                                                                        }
                                                                                    </>
                                                                                    : 'Not Added Availability'
                                                                            }
                                                                        </h4>

                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </>
                                                    }

                                                    {(isHiddenItem == item.id) &&
                                                        <AddAndEditFormBtn data={item} />
                                                    }
                                                </div>
                                            </div>
                                        </>
                                    ))}

                                    {totalExpLength > 3 && <p className='load_more_exp_link'>
                                        <NavLink onClick={() => getMoreWantToJob(dataIsLoad ? 'hide' : 'load')} className={`text_safron`} >{dataIsLoad ? 'Hide Jobs' : 'Load more .....'}</NavLink>
                                    </p>}

                                </>
                                :
                                <>
                                    <div className={isCllingAdmin ? 'admin_screen' : 'card-box work-experiencesec commonform mb-4'}  >
                                        <div className="row align-items-start editfieldss" >
                                            {
                                                isDataLoading ?
                                                    <><p className='text-center'><img className='width-100' src={'/front/images/loader.gif'} /></p><div className={`model_loader display_none ${!isDataLoading ? 'display_none' : ''}`}>
                                                        <img src={'/front/images/loader.gif'} />
                                                    </div></> :
                                                    <><AddAndEditFormBtn data={[]} /></>
                                            }

                                        </div>
                                    </div>
                                </>
                        }

                    </>
                }

            </div>
            {/* <div className="modal fade modallarg docuploadmodal addjobpop add_preffered_job_modal" id="docuploadmodal" tabIndex="-1" aria-labelledby="docuploadmodalLabel" aria-hidden="true">
                <div className="modal-dialog modal-lg">
                    <div className="modal-content">
                        <div className="modal-body row" >
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                            <AddAndEditFormBtn data={[]} />
                        </div>
                    </div>
                </div>
            </div> */}
            
            <Modal className='add_preffered_job_modal' show={addEditJobModalToggle} size="lg" onHide={() => setAddEditJobModalToggle(false)}>
                <div className="modal-dialog1 modal-lg">
                    <div className="modal-content">
                        <div className="modal-body">
                            <button type="button" className="btn-close" onClick={() => setAddEditJobModalToggle(false)} ></button>
                            <AddAndEditFormBtn data={[]} />
                        </div>
                    </div>
                </div>
            </Modal>
        </>
    );


}
export default PrefferdJobComp;