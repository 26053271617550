import React, { useEffect, useRef } from 'react';
import { usePlacesWidget } from 'react-google-autocomplete';

const GoogleAutocomplete = ({ onSelect, onChange, className = '', value = '', name = '', placeHolder = ''  }) => {

    const address = {
        lat: '',
        lng: '',
        unit: '',
        houseNumber: '',
        streetName: '',
        suburb: '',
        city: '',
        state: '',
        country: '',
        postalCode: '',
        placevalue: ''
    };
    // console.log(ref);
    const { ref } = usePlacesWidget({
        apiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
        options: {
            types: ['address'],
            componentRestrictions: { country: ['au'] }  //['nz'] // Restrict to Australia (AU) and New Zealand (NZ)
        },
        onPlaceSelected: async (place) => {

            console.log('---------------------');
            const addressComponents = place.address_components;
            //console.log(place);
            address.placevalue = place.formatted_address;
            if (place.geometry) {
                // Extract latitude and longitude from the place geometry
                address.lat = place.geometry.location.lat();
                address.lng = place.geometry.location.lng();
            }

            addressComponents.forEach(component => {
                if (component.types.includes('street_number')) {
                    address.houseNumber = component.long_name;
                } else if (component.types.includes('route')) {
                    address.streetName = component.long_name;
                } else if (component.types.includes('sublocality')) {
                    address.suburb = component.long_name;
                } else if (component.types.includes('locality')) {
                    address.city = component.long_name;
                } else if (component.types.includes('administrative_area_level_1')) {
                    address.state = component.long_name;
                } else if (component.types.includes('country')) {
                    address.country = component.long_name;
                } else if (component.types.includes('postal_code')) {
                    address.postalCode = component.long_name;
                }
            });
            //console.log('Hello....');
            onSelect(address);
        }
    });


    /*
        ------------------------------------------------
            ------------Need to add with comp---------------
        ------------------------------------------------
    */
    /*
    const [address, setAddress] = useState(null);
     const [googleAddressValue, setGoogleAddress] = useState('');
        const googleAuotoCompelte = (newAddress) => { setAddress(newAddress); setGoogleAddress(`${newAddress.placevalue}`)  };

        const handleInputChange = (e) => { setGoogleAddress(e.target.value);  };
       
        useEffect(() => {
            if (address && address.houseNumber) {
                setAddressDetails((prevFormData) => ({ ...prevFormData, ['address_building_no']: address.houseNumber }));
            }
        }, [address]);


         <GoogleAutocomplete
        onSelect={googleAuotoCompelte} required
        name='work_expe_subrub'
        value={googleAddressValue} 
        placeHolder='Enter your suburb'
        className="form-control"
        onChange={handleInputChange}
        />
    */

    const inputRef = useRef(null);

    useEffect(() => {
        inputRef.current = ref.current; // Ensure ref is assigned
        console.log('Input ref:', inputRef.current);
    }, [ref]);


    return <input
        ref={ref} className={className} name={name} value={value} placeholder={placeHolder}
        onChange={onChange}  
    />;
};
export default GoogleAutocomplete;