import React, {  useEffect ,useState } from 'react';
const TermsAndConditions = () => {
	const [activeUser, setActiveUser] = useState('jobseeker');
	//const queryParameters = new URLSearchParams(window.location.search)
	useEffect(() => {
		const queryParameters = new URLSearchParams(window.location.search)
		const usertype = queryParameters.get("usertype");
		(usertype == 'jobseeker') ? setActiveUser('jobseeker') : (usertype ? setActiveUser('recruiter') : setActiveUser('jobseeker'));
		window.scrollTo(0, 110);
	},[]);

	
	const getActiveTabs  = (usertype) => {
		setActiveUser(usertype)
	}

	return (
		<>
			<div className="mainpages">
				<div className="banner page-banner">
					<div className="container">
						<div className="row align-items-center">
							<div className="col-md-6">
								<div className="banner-left">
									<h1>Terms and Conditions</h1>
								</div>
							</div>
							<div className="col-md-6">
								<img src="/front/images/termsbg.png"></img>
							</div>
						</div>
					</div>
				</div>




				<div className="trrmssec">

					<div className="container">
						<div className="row">
							<div className="col-md-12">

								<div className="tabwarperr"><ul className="nav nav-pills mb-3 comontabs" id="pills-tab" role="tablist">
								<li className="nav-item" role="presentation">
        <button 
          onClick={() => getActiveTabs('jobseeker')} 
          className={`nav-link ${activeUser === 'jobseeker' ? 'active' : ''}`} 
          id="pills-jobseeker-tab" 
          data-bs-toggle="pill" 
          data-bs-target="#pills-jobseeker" 
          type="button" 
          role="tab" 
          aria-controls="pills-jobseeker" 
          aria-selected={activeUser === 'jobseeker'}
        >
          Jobseeker
        </button>
      </li>
      <li className="nav-item" role="presentation">
        <button 
          onClick={() => getActiveTabs('recruiter')} 
          className={`nav-link ${activeUser === 'recruiter' ? 'active' : ''}`} 
          id="pills-recruiter-tab" 
          data-bs-toggle="pill" 
          data-bs-target="#pills-recruiter" 
          type="button" 
          role="tab" 
          aria-controls="pills-recruiter" 
          aria-selected={activeUser === 'recruiter'}
        >
          Recruiter
        </button>
      </li>

								</ul>
								</div>
								<div className="tab-content" id="pills-tabContent">
									<div className={`tab-pane box fade ${activeUser == 'jobseeker' ? 'show active' : ''} `} id="pills-jobseeker" role="tabpanel" aria-labelledby="pills-jobseeker-tab">

										<div className="banner-left">
											<div className="d-flex align-items-center justify-content-between">
												<h2 className="sectionhead" >TERMS AND CONDITIONS (FOR JOBSEEKERS)</h2>

												<a href="/downloads/pdf/SquadGooJobseekerTandC.pdf" download>
													<button className="batn batn-orange">
														<i className="fa fa-file-pdf-o mr-2"></i>Download PDF
													</button>
												</a>

											</div>
											<h3 className="sectionsubhead">1. About The Website </h3>
											<ul>
												<li>Welcome to <a href="https://squadgoo.com.au">www.squadgoo.com.au</a> (Website). The Website is Matching JOBSEEKERS and JOB PROVIDERS (Services).</li>
												<li>The Website is operated by SQUAD GOO PTY LTD (ACN 670318715). Access to and use of the Website, or nay of its associated Products or Services, is provided by SQUAD GOO PTY LTD. Please read these terms and conditions (Terms) carefully. By using, browsing and/or reading the Website, this signifies that you have read, understood, and agree to be bound by the Terms. If you do not agree with the Terms, you must cease usage of the Website, or any of Services, immediately.</li>
												<li>SQUAD GOO PTY LTD reserves the right to review and change any of the Terms by updating this page at its sole discretion. When SQUAD GOO PTY LTD updates the Terms, it will use reasonable endeavours to provide you with notice of updates to the Terms. Any changes to the Terms take immediate effect from the date of their publication. Before you continue, we recommend you keep a copy of the Terms for your records.</li>
											</ul>
											<h3 className="sectionsubhead">2. ACCEPTANCE OF THE TERMS</h3>
											<p>You accept the Terms by remaining on the Website. You may also accept the Terms by clicking to accept or agree to the Terms where this option is
												made available to you by SQUAD GOO PTY LTD in the user interface.
											</p>
											<h3 className="sectionsubhead">3. REGISTREATION TO USE THE SERVICES </h3>
											<ol>
												<li>To access the Services, you must first register for an account through the Website (ACCOUNT).</li>
												<li>
													As part of the registration process, or as part of your continued use of the Services, you may be required to provide personal information about yourself (such as identification or contact details), including:
													<ul>
														<li>Email address. </li>
														<li>Preferred username </li>
														<li>Full name </li>
														<li>Mailing address </li>
														<li>Mobile number </li>
														<li>Password </li>
														<li>Contact details of reference you provide to verify your work history.</li>
														<li>Date of birth </li>
														<li>ABN OR TFN OR BOTH </li>
														<li>Personal social media profile links. </li>
													</ul>
												</li>
												<li>
													As part of the registration process, or as part of your continued use of the Services, you may be required to provide documents about yourself (such as proof of identity, or documents matching your provided information) such as:
													<ul>
														<li>Passport, Drivers Licenses, Birth Certificates, or any forms of id matching your identification details.</li>
														<li>Resume (work history) </li>
														<li>ABN registration proof </li>
														<li>Any qualification you hold if voluntarily provided. </li>
														<li>Your current clear face photo </li>
														<li>Or any documents we may ask, or you may voluntarily provide to make your profile strong for potential match with other users (Services) in our Website.</li>
													</ul>
												</li>
												<li>As part of the registration process, or as part of your continued use of the Services, you may be required to provide access to your live location sharing or potential location of home address through Google maps or both.</li>
												<li>You warrant that any information, documents and access you give to SQUAD GOO PTY LTD while completing the registration process or during the use of our website will always be accurate, correct and up to date.</li>
												<li>Once you have completed the registration process, you will be a registered member of the Website (Member) and agree to be bound by the Terms.</li>
												<li>
													You may not use the Services and may not accept the Terms if:
													<ul>
														<li>You are not of legal agree to form a binding contract with Squad goo pty ltd, or</li>
														<li>You are a person barred from receiving the Services under the laws of Australia or other countries including the country in which you are resident or from which you use the Services.</li>
													</ul>
												</li>
											</ol>
											<h3 className="sectionsubhead">4. YOUR OBLIGATIONS AS A MEMBER</h3>
											<ol>
												<li>
													As a member, you agree to comply with the following:
													<ul>
														<li>
															You will use the Services only for purposes that are permitted by:
															<ul>
																<li>the Terms; and  </li>
																<li>any applicable law, regulation or generally accepted practices or guidelines in the relevant jurisdictions.</li>
															</ul>
														</li>
														<li>You have the sole responsibility for protecting the confidentiality of your password and/or email address. Use of your password by any other person may result in the immediate cancellation of the Services; </li>
														<li>any use of your registration information by any other person, or third parties, is strictly prohibited. You agree to immediately notify SQUAD GOO PTY LTD of any unauthorised use of your password or email address or any breach of security of which you have become aware; </li>
														<li>access and use of Website is limited, non-transferable and allows for the sole use of the Website by you for the purposes of SQUAD GOO PTY LTD providing the Services;</li>
														<li>you will not use the Services or the Website in connection with any commercial endeavours except those that are specifically endorsed or approved by the management of SQUAD GOO PTY LTD.</li>
														<li>you will not use the Services or the Website for any illegal and/or unauthorised use which includes collecting email addresses of Members by electronic or other means for the purpose of sending unsolicited email or unauthorised framing of or linking to the Website; </li>
														<li>You agree that commercial advertisement, affiliate links, or other forms of solicitation may be removed from the Website without notice and may result in termination of the Services. Appropriate legal action will be taken by SQUAD GOO PTY LTD for any illegal or unauthorised use of the Website; and  </li>
														<li>You acknowledge and agree that any automated use of the Website or its Services is prohibited. </li>
													</ul>
												</li>
											</ol>
											<h3 className="sectionsubhead">5. SHARING OF YOUR PERSONAL INFORMATION </h3>
											<ol>
												<li>SQUAD GOO may share limited/or full dates you provided after being active on our Website to other users (JOB PROVIDERS) of Website. </li>
												<li>To complete matching your profile with another user (JOB PROVIDERS), Your collected information, documents or access may be shared. Once we find your match with other user, selected user may be able to see all information collected about you.</li>
												<li>Once you and another user agree to connect/or accept the offer, all dates about each other may be shared to facilitate smooth connection and with aim to know each other. </li>
												<li>All communication such as TEXT, VOICE/OR VIDEO CALL or ATTACHMENTS or EMAIL taken place using communication portal provided by SQUAD GOO will be recorded in full/or part and may be resummoned by both connected users if needed. </li>
											</ol>
											<h3 className="sectionsubhead">6. PAYMENT</h3>
											<ol>
												<li>All payments made during your use of the Services are made using ____________. In using the Website, the Services or when making any payment in relation to your use of the Services, you warrant that you have read, understood, and agree to be bound by the _______________terms and conditions which are available on their website. </li>
												<li>You acknowledge and agree that where a request for the payment of the Services Fee is returned or denied, then you are liable for any costs, including banking fee and charges, associated with the Services Fee. </li>
												<li>You agree and acknowledge that SQUAD GOO PTY LTD can vary the Services Fee at any time. </li>
											</ol>
											<h3 className="sectionsubhead">7. Refund Policy</h3>
											<ol>
												<li>SQUAD GOO Pty Ltd will only provide you with a refund of the Services Fee in the event they are unable to continue to provide the Services or if the manager of SQUAD GOO PtyLtd makes a decision, at its absolute discretion, that it is reasonable to do so under the circumstances (Refund). </li>
												<li>Any benefits set out in this Terms and Conditions may apply in addition to consumer’s rights under the Australian Consumer Law. </li>
											</ol>
											<h3 className="sectionsubhead">8. Copyright and Intellectual Property </h3>
											<ol>
												<li>The Website, the Services and all the related products of SQUAD GOO Pty Ltd are subject to copyright. The material on the Website is protected by copyright under the laws of Australia and through international treaties. Unless otherwise indicated, all rights (including copyright) in the Services and compilation of the Website (including but not limited to text, graphics, logos, button icons, video images, audio clips, Website code, scripts, design elements and interactive features) or the Services are owned or controlled for these purposes, and are reserved by SQUAD GOO Pty Ltd or its contributors. </li>
												<li>
													All trademarks, service marks and trade names are owned, registered and/or licensed by SQUAD GOO Pty Ltd, who grants to you a worldwide, non-exclusive, royalty-free, revocable license whilst you are a Member to:
													<ul>
														<li>use the Website pursuant to the Terms. </li>
														<li>copy and store the Website and the material contained in the Website in your device’s cache memory;  </li>
														<li>and print pages from the Website for your own personal and non-commercial use. SQUAD GOO Pty Ltd does not grant you any other rights whatsoever in relation to the Website or the Services. All other rights are expressly reserved by SQUAD GOO Pty Ltd.</li>
													</ul>
												</li>
												<li>
													SQUAD GOO Pty Ltd retains all rights, title, and interest in and to the Website and all related Services. Nothing you do on or in relation to the Website will transfer any:
													<ul>
														<li>business name, trading name, domain name, trademark, industrial design, patent, registered design, or copyright, or</li>
														<li>a right to use or exploit a business name, trading name, domain name, trademark, or industrial design, or </li>
														<li>a thing, system or process that is the subject of a patent, registered design, or copyright (or an adaptation or modification of such a thing, system, or process).</li>
													</ul>
												</li>
												<li>You may not, without the prior written permission of SQUAD GOO Pty Ltd and the permission of any other relevant rights owners: broadcast, republish, up-load to a third-party, transmit, post, distribute, show or play in public, adapt or change in any way the Services or third-party Services for any purpose, unless otherwise provided by these Terms. This prohibition does not extend to materials on the Website, which are freely available for re-use or are in the public domain. </li>
											</ol>
											<h3 className="sectionsubhead">9. Privacy </h3>
											<p>SQUAD GOO Pty Ltd takes your privacy seriously and any information provided
												through your use of the Website and/or Services are subject to SQUAD GOO Pty Ltd's
												Privacy Policy, which is available on the Website.
											</p>
											<h3 className="sectionsubhead">10. General Disclaimer </h3>
											<ol>
												<li>Nothing in the Terms limits or excludes any guarantees, warranties, representations, or conditions implied or imposed by law, including the Australian Consumer Law (or any liability under them) which by law may not be limited or excluded. </li>
												<li>
													Subject to this clause, and to the extent permitted by law:
													<ul>
														<li>all terms, guarantees, warranties, representations, or conditions which are not expressly stated in the Terms are excluded; and </li>
														<li>SQUAD GOO Pty Ltd will not be liable for any special, indirect or consequential loss or damage (unless such loss or damage is reasonably foreseeable resulting from our failure to meet an applicable Consumer Guarantee), loss of profit or opportunity, or damage to goodwill arising out of or in connection with the Services or these Terms (including as a result of not being able to use the Services or the late supply of the Services), whether at common law, under contract, tort (including negligence), in equity, pursuant to statute or otherwise. </li>
													</ul>
												</li>
												<li>
													Use of the Website and the Services is at your own risk. Everything on the Website and the Services is provided to you "as is" and "as available" without warranty or condition of any kind. None of the affiliates, directors, officers, employees, agents, contributors, and licensors of SQUAD GOO Pty Ltd make any express or implied representation or warranty about the Services or any products or Services (including the products or Services of SQUAD GOO Pty Ltd) referred to on the Website. This includes (but is not restricted to) loss or damage you might suffer because of any of the following:
													<ul>
														<li>failure of performance, error, omission, interruption, deletion, defect, failure to correct defects, delay in operation or transmission, computer virus or other harmful component, loss of data, communication line failure, unlawful third-party conduct, or theft, destruction, alteration, or unauthorised access to records. </li>
														<li> the accuracy, suitability or currency of any information on the Website, the Services, or any of its Services related products (including third party material and advertisements on the Website); </li>
														<li>costs incurred because of you using the Website, the Services or any of the products of SQUAD GOO Pty Ltd; and </li>
														<li>the Services or operation in respect to links which are provided for your convenience. </li>
													</ul>
												</li>
											</ol>
											<h3 className="sectionsubhead">12. Competitors </h3>
											<p>If you are in the business of providing similar Services for the purpose of providing them to users for a commercial gain, whether business users or domestic users, then you are a competitor of SQUAD GOO Pty Ltd. Competitors are not permitted to use or access any information or content on our application. If you breach this provision, SQUAD GOO Pty Ltd will hold you fully responsible for any loss that we may sustain and hold you accountable for all profits that you might make from such a breach. </p>
											<h3 className="sectionsubhead">13. Termination of Contract</h3>
											<ol>
												<li>The Terms will continue to apply until terminated by either you or by SQUAD GOO Pty Ltd as set out below. </li>
												<li>
													If you want to terminate the Terms, you may do so by:
													<ul>
														<li>providing SQUAD GOO Pty Ltd with 30 business days' notice of your intention to terminate; and</li>
														<li>closing your accounts for all the services which you use, where SQUAD GOO Pty Ltd has made this option available to you. Your notice should be sent, in writing, to SQUAD GOO Pty Ltd via the 'Contact Us' link on our homepage.</li>
													</ul>
												</li>
												<li>
													SQUAD GOO Pty Ltd may at any time, terminate the Terms with you if:
													<ul>
														<li>you have breached any provision of the Terms or intend to breach any provision. </li>
														<li>SQUAD GOO Pty Ltd is required to do so by law. </li>
														<li>the provision of the Services to you by SQUAD GOO Pty Ltd is, in the opinion of SQUAD GOO Pty Ltd, no longer commercially viable. </li>
													</ul>
												</li>
												<li>Subject to local applicable laws, SQUAD GOO Pty Ltd reserves the right to discontinue or cancel your membership at any time and may suspend or deny, in its sole discretion, your access to all or any portion of the Website or the Services without notice if you breach any provision of the Terms or any applicable law or if your conduct impacts SQUAD GOO Pty Ltd's name or reputation or violates the rights of those of another party.</li>
											</ol>
											<h3 className="sectionsubhead">14. Indemnity</h3>
											<ol>
												<li>
													You agree to indemnify SQUAD GOO Pty Ltd, its affiliates, employees, agents, contributors, third-party content providers and licensors from and against:
													<ul>
														<li>all actions, suits, claims, demands, liabilities, costs, expenses, loss, and damage (including legal fees on a full indemnity basis) incurred, suffered, or arising out of or in connection with your content.</li>
														<li>any direct or indirect consequences of you accessing, using, or transacting on the Website or attempts to do so; and/or </li>
														<li>any breach of the Terms. </li>
													</ul>
												</li>
											</ol>
											<h3 className="sectionsubhead">15. Dispute Resolution</h3>
											<ol>
												<li>Compulsory: If a dispute arises out of or relates to the Terms, either party may not commence any Tribunal or Court proceedings in relation to the dispute, unless the following clauses have been complied with (except where urgent interlocutory relief is sought) </li>
												<li>Notice: A party to the Terms claiming a dispute (Dispute) has arisen under the Terms, must give written notice to the other party detailing the nature of the dispute, the desired outcome and the action required to settle the Dispute. </li>
												<li>
													Resolution: On receipt of that notice (Notice) by that other party, the parties to the Terms (Parties)must:
													<ul>
														<li>Within 28 days of the Notice endeavour in good faith to resolve the Dispute expeditiously by negotiation or such other means upon which they may mutually agree;</li>
														<li>If for any reason whatsoever, 28 days after the date of the Notice, the Dispute has not been resolved, the Parties must either agree upon selection of a mediator or request that an appropriate mediator be appointed by the Australian Mediation Association;</li>
														<li>The Parties are equally liable for the fees and reasonable expenses of a mediator and the cost of the venue of the mediation and without limiting the foregoing undertake to pay any amounts requested by the mediator as a precondition to the mediation commencing. The Parties must each pay their own costs associated with the mediation.</li>
														<li>The mediation will be held in Melbourne, Australia. </li>
													</ul>
												</li>
												<li>Confidential All communications concerning negotiations made by the Parties arising out of and in connection with this dispute resolution clause are confidential and to the extent possible, must be treated as "without prejudice" negotiations for the purpose of applicable laws of evidence. </li>
												<li>Termination of Mediation: If 2 months have elapsed after the start of a mediation of the Dispute and the Dispute has not been resolved, either Party may ask the mediator to terminate the mediation and the mediator must do so.</li>
											</ol>
											<h3 className="sectionsubhead">16. Venue and Jurisdiction </h3>
											<p>The Services offered by SQUAD GOO Pty Ltd is intended to be viewed by residents of Australia. In the event of any dispute arising out of or in relation to the Website, you agree that the exclusive venue for resolving any dispute shall be in the courts of Victoria, Australia. </p>
											<h3 className="sectionsubhead">17. Governing Law</h3>
											<p>The Terms are governed by the laws of Victoria, Australia. Any dispute, controversy, proceeding or claim of whatever nature arising out of or in any way relating to the Terms and the rights created hereby shall be governed, interpreted, and construed by, under and pursuant to the laws of Victoria, Australia, without reference to conflict of law principles, notwithstanding mandatory rules. The validity of this governing law clause is not contested. The Terms shall be binding to the benefit of the parties hereto and their successors and assigns. </p>
											<h3 className="sectionsubhead">18.  Severance</h3>
											<p>If any part of these Terms is found to be void or unenforceable by a Court of competent jurisdiction, that part shall be severed, and the rest of the Terms shall remain in force.</p>
										</div>
									</div>

									<div className={`tab-pane box fade ${activeUser == 'recruiter' ? 'show active' : ''} `} id="pills-recruiter" role="tabpanel" aria-labelledby="pills-recruiter-tab">

										<div className="banner-left">
											<div className="d-flex align-items-center justify-content-between">
												<h2 className="sectionhead" >TERMS AND CONDITIONS (FOR jOB RECRUITER)</h2>

												<a href="/downloads/pdf/SquadGooRecruiterTandC.pdf" download>
												<button className="batn batn-orange"><i className="fa fa-file-pdf-o mr-2"></i>Download PDF</button>
												</a>
											</div>
											<h3 className="sectionsubhead">1. About The Website </h3>
											<ul>
												<li>Welcome to <a href="">www.squadgoo.com.au</a> (Website). The Website is Matching JOBSEEKERS and JOB PROVIDERS (Services).</li>
												<li>The Website is operated by SQUAD GOO PTY LTD (ACN 670318715). Access to and use of the Website, or nay of its associated Products or Services, is provided by SQUAD GOO PTY LTD. Please read these terms and conditions (Terms) carefully. By using, browsing and/or reading the Website, this signifies that you have read, understood, and agree to be bound by the Terms. If you do not agree with the Terms, you must cease usage of the Website, or any of Services, immediately.</li>
												<li>SQUAD GOO PTY LTD reserves the right to review and change any of the Terms by updating this page at its sole discretion. When SQUAD GOO PTY LTD updates the Terms, it will use reasonable endeavours to provide you with notice of updates to the Terms. Any changes to the Terms take immediate effect from the date of their publication. Before you continue, we recommend you keep a copy of the Terms for your records.</li>
											</ul>
											<h3 className="sectionsubhead">2. ACCEPTANCE OF THE TERMS</h3>
											<p>You accept the Terms by remaining on the Website. You may also accept the Terms by clicking to accept or agree to the Terms where this option is
												made available to you by SQUAD GOO PTY LTD in the user interface.
											</p>
											<h3 className="sectionsubhead">3. REGISTREATION TO USE THE SERVICES </h3>
											<ol>
												<li>To access the Services, you must first register for an account through the Website (ACCOUNT).</li>
												<li>
													As part of the registration process, or as part of your continued use of the Services, you may be required to provide personal information about yourself (such as identification or contact details), including:
													<ul>
														<li>
															<strong>Representative Detail</strong>
															<ul>
																<li>Email address. </li>
																<li>Full name </li>
																<li>Mobile number </li>
																<li>Position</li>
															</ul>
														</li>

														<li>
															<strong>Director's Details</strong>
															<ul>
																<li>Email address. </li>
																<li>Full name </li>
																<li>Mobile number </li>
															</ul>
														</li>

														<li>
															<strong>Company Details</strong>
															<ul>
																<li>Company name </li>
																<li>Company website</li>
																<li>Company team size</li>
																<li>Industry </li>
																<li>Date of birth</li>
																<li>Company address details(Unit no, Building no, Building no, Street name, Suburb, State)</li>
																<li>Company ABN number</li>
															</ul>
														</li>

														<li>
															<strong>Owner's Detail</strong>
															<ul>
																<li>Email address. </li>
																<li>Full name </li>
																<li>Mobile number </li>
																<li>Date of birth</li>
																<li>Owner's address details(Unit no, Building no, Building no, Street name, Suburb, State)</li>
																<li>ABN number</li>
															</ul>
														</li>


														<li>
															<strong>Individual's Detail</strong>
															<ul>
																<li>Email address. </li>
																<li>Full name </li>
																<li>Mobile number </li>
																<li>Date of birth</li>
																<li>Individual's address details(Unit no, Building no, Building no, Street name, Suburb, State)</li>
																<li>ABN number</li>
															</ul>
														</li>
													</ul>
												</li>
												<li>
													As part of the registration process, or as part of your continued use of the Services, you may be required to provide documents about yourself (such as proof of identity, or documents matching your provided information) such as:
													<ul>
														<li>Company registration document </li>
														<li>Document for address proof any of these (Select document, Australian Citizenship Certificate, Australian Government Agency Letters, Bank Statements, Council Rates Notice, Driver's License, Electoral Roll Confirmation, Electricity Bill, Employer Letter, Gas Bill, Insurance Policies, Letter from a Notary Public, Medicare Card, Official Government Correspondence, Official Government License or Permit, Passport, Property Lease or Rental Agreement, School/College Enrollment Letter, Statutory Declaration, Taxation Office (ATO) Correspondence, Tenancy Agreement, Utility Bills, Vehicle Registration Certificate, Water Bill)</li>
														<li>Passport, Drivers Licenses, Birth Certificates, or any forms of id matching your identification details.</li>

														<li>ABN registration proof </li>
														<li>Your current clear face photo </li>
													</ul>
												</li>
												<li>As part of the registration process, or as part of your continued use of the Services, you may be required to provide access to your live location sharing or potential location of home address through Google maps or both.</li>
												<li>You warrant that any information, documents and access you give to SQUAD GOO PTY LTD while completing the registration process or during the use of our website will always be accurate, correct and up to date.</li>
												<li>Once you have completed the registration process, you will be a registered member of the Website (Member) and agree to be bound by the Terms.</li>
												<li>
													You may not use the Services and may not accept the Terms if:
													<ul>
														<li>You are not of legal agree to form a binding contract with Squad goo pty ltd, or</li>
														<li>You are a person barred from receiving the Services under the laws of Australia or other countries including the country in which you are resident or from which you use the Services.</li>
													</ul>
												</li>
											</ol>
											<h3 className="sectionsubhead">4. YOUR OBLIGATIONS AS A MEMBER</h3>
											<ol>
												<li>
													As a member, you agree to comply with the following:
													<ul>
														<li>
															You will use the Services only for purposes that are permitted by:
															<ul>
																<li>the Terms; and  </li>
																<li>any applicable law, regulation or generally accepted practices or guidelines in the relevant jurisdictions.</li>
															</ul>
														</li>
														<li>You have the sole responsibility for protecting the confidentiality of your password and/or email address. Use of your password by any other person may result in the immediate cancellation of the Services; </li>
														<li>any use of your registration information by any other person, or third parties, is strictly prohibited. You agree to immediately notify SQUAD GOO PTY LTD of any unauthorised use of your password or email address or any breach of security of which you have become aware; </li>
														<li>access and use of Website is limited, non-transferable and allows for the sole use of the Website by you for the purposes of SQUAD GOO PTY LTD providing the Services;</li>
														<li>you will not use the Services or the Website in connection with any commercial endeavours except those that are specifically endorsed or approved by the management of SQUAD GOO PTY LTD.</li>
														<li>you will not use the Services or the Website for any illegal and/or unauthorised use which includes collecting email addresses of Members by electronic or other means for the purpose of sending unsolicited email or unauthorised framing of or linking to the Website; </li>
														<li>You agree that commercial advertisement, affiliate links, or other forms of solicitation may be removed from the Website without notice and may result in termination of the Services. Appropriate legal action will be taken by SQUAD GOO PTY LTD for any illegal or unauthorised use of the Website; and  </li>
														<li>You acknowledge and agree that any automated use of the Website or its Services is prohibited. </li>
													</ul>
												</li>
											</ol>
											<h3 className="sectionsubhead">5. SHARING OF YOUR PERSONAL INFORMATION </h3>
											<ol>
												<li>SQUAD GOO may share limited/or full dates you provided after being active on our Website to other users (JOB PROVIDERS) of Website. </li>
												<li>To complete matching your profile with another user (JOB PROVIDERS), Your collected information, documents or access may be shared. Once we find your match with other user, selected user may be able to see all information collected about you.</li>
												<li>Once you and another user agree to connect/or accept the offer, all dates about each other may be shared to facilitate smooth connection and with aim to know each other. </li>
												<li>All communication such as TEXT, VOICE/OR VIDEO CALL or ATTACHMENTS or EMAIL taken place using communication portal provided by SQUAD GOO will be recorded in full/or part and may be resummoned by both connected users if needed. </li>
											</ol>
											<h3 className="sectionsubhead">6. PAYMENT</h3>
											<ol>
												<li>All payments made during your use of the Services are made using ____________. In using the Website, the Services or when making any payment in relation to your use of the Services, you warrant that you have read, understood, and agree to be bound by the _______________terms and conditions which are available on their website. </li>
												<li>You acknowledge and agree that where a request for the payment of the Services Fee is returned or denied, then you are liable for any costs, including banking fee and charges, associated with the Services Fee. </li>
												<li>You agree and acknowledge that SQUAD GOO PTY LTD can vary the Services Fee at any time. </li>
											</ol>
											<h3 className="sectionsubhead">7. Refund Policy</h3>
											<ol>
												<li>SQUAD GOO Pty Ltd will only provide you with a refund of the Services Fee in the event they are unable to continue to provide the Services or if the manager of SQUAD GOO PtyLtd makes a decision, at its absolute discretion, that it is reasonable to do so under the circumstances (Refund). </li>
												<li>Any benefits set out in this Terms and Conditions may apply in addition to consumer’s rights under the Australian Consumer Law. </li>
											</ol>
											<h3 className="sectionsubhead">8. Copyright and Intellectual Property </h3>
											<ol>
												<li>The Website, the Services and all the related products of SQUAD GOO Pty Ltd are subject to copyright. The material on the Website is protected by copyright under the laws of Australia and through international treaties. Unless otherwise indicated, all rights (including copyright) in the Services and compilation of the Website (including but not limited to text, graphics, logos, button icons, video images, audio clips, Website code, scripts, design elements and interactive features) or the Services are owned or controlled for these purposes, and are reserved by SQUAD GOO Pty Ltd or its contributors. </li>
												<li>
													All trademarks, service marks and trade names are owned, registered and/or licensed by SQUAD GOO Pty Ltd, who grants to you a worldwide, non-exclusive, royalty-free, revocable license whilst you are a Member to:
													<ul>
														<li>use the Website pursuant to the Terms. </li>
														<li>copy and store the Website and the material contained in the Website in your device’s cache memory;  </li>
														<li>and print pages from the Website for your own personal and non-commercial use. SQUAD GOO Pty Ltd does not grant you any other rights whatsoever in relation to the Website or the Services. All other rights are expressly reserved by SQUAD GOO Pty Ltd.</li>
													</ul>
												</li>
												<li>
													SQUAD GOO Pty Ltd retains all rights, title, and interest in and to the Website and all related Services. Nothing you do on or in relation to the Website will transfer any:
													<ul>
														<li>business name, trading name, domain name, trademark, industrial design, patent, registered design, or copyright, or</li>
														<li>a right to use or exploit a business name, trading name, domain name, trademark, or industrial design, or </li>
														<li>a thing, system or process that is the subject of a patent, registered design, or copyright (or an adaptation or modification of such a thing, system, or process).</li>
													</ul>
												</li>
												<li>You may not, without the prior written permission of SQUAD GOO Pty Ltd and the permission of any other relevant rights owners: broadcast, republish, up-load to a third-party, transmit, post, distribute, show or play in public, adapt or change in any way the Services or third-party Services for any purpose, unless otherwise provided by these Terms. This prohibition does not extend to materials on the Website, which are freely available for re-use or are in the public domain. </li>
											</ol>
											<h3 className="sectionsubhead">9. Privacy </h3>
											<p>SQUAD GOO Pty Ltd takes your privacy seriously and any information provided
												through your use of the Website and/or Services are subject to SQUAD GOO Pty Ltd's
												Privacy Policy, which is available on the Website.
											</p>
											<h3 className="sectionsubhead">10. General Disclaimer </h3>
											<ol>
												<li>Nothing in the Terms limits or excludes any guarantees, warranties, representations, or conditions implied or imposed by law, including the Australian Consumer Law (or any liability under them) which by law may not be limited or excluded. </li>
												<li>
													Subject to this clause, and to the extent permitted by law:
													<ul>
														<li>all terms, guarantees, warranties, representations, or conditions which are not expressly stated in the Terms are excluded; and </li>
														<li>SQUAD GOO Pty Ltd will not be liable for any special, indirect or consequential loss or damage (unless such loss or damage is reasonably foreseeable resulting from our failure to meet an applicable Consumer Guarantee), loss of profit or opportunity, or damage to goodwill arising out of or in connection with the Services or these Terms (including as a result of not being able to use the Services or the late supply of the Services), whether at common law, under contract, tort (including negligence), in equity, pursuant to statute or otherwise. </li>
													</ul>
												</li>
												<li>
													Use of the Website and the Services is at your own risk. Everything on the Website and the Services is provided to you "as is" and "as available" without warranty or condition of any kind. None of the affiliates, directors, officers, employees, agents, contributors, and licensors of SQUAD GOO Pty Ltd make any express or implied representation or warranty about the Services or any products or Services (including the products or Services of SQUAD GOO Pty Ltd) referred to on the Website. This includes (but is not restricted to) loss or damage you might suffer because of any of the following:
													<ul>
														<li>failure of performance, error, omission, interruption, deletion, defect, failure to correct defects, delay in operation or transmission, computer virus or other harmful component, loss of data, communication line failure, unlawful third-party conduct, or theft, destruction, alteration, or unauthorised access to records. </li>
														<li> the accuracy, suitability or currency of any information on the Website, the Services, or any of its Services related products (including third party material and advertisements on the Website); </li>
														<li>costs incurred because of you using the Website, the Services or any of the products of SQUAD GOO Pty Ltd; and </li>
														<li>the Services or operation in respect to links which are provided for your convenience. </li>
													</ul>
												</li>
											</ol>
											<h3 className="sectionsubhead">12. Competitors </h3>
											<p>If you are in the business of providing similar Services for the purpose of providing them to users for a commercial gain, whether business users or domestic users, then you are a competitor of SQUAD GOO Pty Ltd. Competitors are not permitted to use or access any information or content on our application. If you breach this provision, SQUAD GOO Pty Ltd will hold you fully responsible for any loss that we may sustain and hold you accountable for all profits that you might make from such a breach. </p>
											<h3 className="sectionsubhead">13. Termination of Contract</h3>
											<ol>
												<li>The Terms will continue to apply until terminated by either you or by SQUAD GOO Pty Ltd as set out below. </li>
												<li>
													If you want to terminate the Terms, you may do so by:
													<ul>
														<li>providing SQUAD GOO Pty Ltd with 30 business days' notice of your intention to terminate; and</li>
														<li>closing your accounts for all the services which you use, where SQUAD GOO Pty Ltd has made this option available to you. Your notice should be sent, in writing, to SQUAD GOO Pty Ltd via the 'Contact Us' link on our homepage.</li>
													</ul>
												</li>
												<li>
													SQUAD GOO Pty Ltd may at any time, terminate the Terms with you if:
													<ul>
														<li>you have breached any provision of the Terms or intend to breach any provision. </li>
														<li>SQUAD GOO Pty Ltd is required to do so by law. </li>
														<li>the provision of the Services to you by SQUAD GOO Pty Ltd is, in the opinion of SQUAD GOO Pty Ltd, no longer commercially viable. </li>
													</ul>
												</li>
												<li>Subject to local applicable laws, SQUAD GOO Pty Ltd reserves the right to discontinue or cancel your membership at any time and may suspend or deny, in its sole discretion, your access to all or any portion of the Website or the Services without notice if you breach any provision of the Terms or any applicable law or if your conduct impacts SQUAD GOO Pty Ltd's name or reputation or violates the rights of those of another party.</li>
											</ol>
											<h3 className="sectionsubhead">14. Indemnity</h3>
											<ol>
												<li>
													You agree to indemnify SQUAD GOO Pty Ltd, its affiliates, employees, agents, contributors, third-party content providers and licensors from and against:
													<ul>
														<li>all actions, suits, claims, demands, liabilities, costs, expenses, loss, and damage (including legal fees on a full indemnity basis) incurred, suffered, or arising out of or in connection with your content.</li>
														<li>any direct or indirect consequences of you accessing, using, or transacting on the Website or attempts to do so; and/or </li>
														<li>any breach of the Terms. </li>
													</ul>
												</li>
											</ol>
											<h3 className="sectionsubhead">15. Dispute Resolution</h3>
											<ol>
												<li>Compulsory: If a dispute arises out of or relates to the Terms, either party may not commence any Tribunal or Court proceedings in relation to the dispute, unless the following clauses have been complied with (except where urgent interlocutory relief is sought) </li>
												<li>Notice: A party to the Terms claiming a dispute (Dispute) has arisen under the Terms, must give written notice to the other party detailing the nature of the dispute, the desired outcome and the action required to settle the Dispute. </li>
												<li>
													Resolution: On receipt of that notice (Notice) by that other party, the parties to the Terms (Parties)must:
													<ul>
														<li>Within 28 days of the Notice endeavour in good faith to resolve the Dispute expeditiously by negotiation or such other means upon which they may mutually agree;</li>
														<li>If for any reason whatsoever, 28 days after the date of the Notice, the Dispute has not been resolved, the Parties must either agree upon selection of a mediator or request that an appropriate mediator be appointed by the Australian Mediation Association;</li>
														<li>The Parties are equally liable for the fees and reasonable expenses of a mediator and the cost of the venue of the mediation and without limiting the foregoing undertake to pay any amounts requested by the mediator as a precondition to the mediation commencing. The Parties must each pay their own costs associated with the mediation.</li>
														<li>The mediation will be held in Melbourne, Australia. </li>
													</ul>
												</li>
												<li>Confidential All communications concerning negotiations made by the Parties arising out of and in connection with this dispute resolution clause are confidential and to the extent possible, must be treated as "without prejudice" negotiations for the purpose of applicable laws of evidence. </li>
												<li>Termination of Mediation: If 2 months have elapsed after the start of a mediation of the Dispute and the Dispute has not been resolved, either Party may ask the mediator to terminate the mediation and the mediator must do so.</li>
											</ol>
											<h3 className="sectionsubhead">16. Venue and Jurisdiction </h3>
											<p>The Services offered by SQUAD GOO Pty Ltd is intended to be viewed by residents of Australia. In the event of any dispute arising out of or in relation to the Website, you agree that the exclusive venue for resolving any dispute shall be in the courts of Victoria, Australia. </p>
											<h3 className="sectionsubhead">17. Governing Law</h3>
											<p>The Terms are governed by the laws of Victoria, Australia. Any dispute, controversy, proceeding or claim of whatever nature arising out of or in any way relating to the Terms and the rights created hereby shall be governed, interpreted, and construed by, under and pursuant to the laws of Victoria, Australia, without reference to conflict of law principles, notwithstanding mandatory rules. The validity of this governing law clause is not contested. The Terms shall be binding to the benefit of the parties hereto and their successors and assigns. </p>
											<h3 className="sectionsubhead">18.  Severance</h3>
											<p>If any part of these Terms is found to be void or unenforceable by a Court of competent jurisdiction, that part shall be severed, and the rest of the Terms shall remain in force.</p>
										</div>

									</div>

								</div>




							</div>
						</div>
					</div>

				</div>







			</div>

		</>
	);
}
export default TermsAndConditions;