import React, { useState, useEffect, Component } from 'react';
import { Link } from 'react-router-dom';
import { Modal } from 'react-bootstrap';

import SuperAdminSidebar from './superadmin_sidebar'
import axios from 'axios';


import DateFilter from '../DateFilter';

import PdfExportButton from './supcomp/PdfExportButton';
import ExcelExportButton from './supcomp/ExcelExportButton';

const Jobseekers = () => {
   const [dataisLoading, setDataisLoading] = useState(false);
   const [jobseekerDataArr, setjobseekerData] = useState([]);
   const [currentActivePage, setCurrentActivePage] = useState(1);
   const [statusType, setstatusType] = useState('all');
   const [profileStatusType, setProfileStatusType] = useState('all');
   const [recruiterType, setRecruiterType] = useState('all');
   const [totalPageCount, settotalPageCount] = useState(1);
   const paginationLoadContentNo = 10;
   const [jobseekerExpData, setjobseekerExpData] = useState([]);
   const [searchText, setSearchText] = useState('');
   const [jobseekerDataCount, setJobseekerDataCount] = useState(0);
   const userdetails = JSON.parse(localStorage.getItem("squadGologinSession"));
   const [moduleAccessibility] = useState(typeof (userdetails.accessibility) != undefined ? userdetails.accessibility.jobseekers : [{
      view: true,
      edit: true,
      delete: true,
   }]);
   const viewAccessibility = (moduleAccessibility != undefined && typeof (moduleAccessibility.view) != undefined) ? moduleAccessibility.view : true;
   const editAccessibility = (moduleAccessibility != undefined && typeof (moduleAccessibility.edit) != undefined) ? moduleAccessibility.edit : true;
   const deleteAccessibility = (moduleAccessibility != undefined && typeof (moduleAccessibility.delete) != undefined) ? moduleAccessibility.delete : true;
   const [tableOrderType, settableOrderType] = useState('ASC');
   const [userDtailsDataArr, setUserDtailsDataArr] = useState([]);
   const getRecruiterData = (search = '') => {

      const page = (search == '') ? currentActivePage : (1);
      (async () => {
         setDataisLoading(true);
         try {
            const formData = new FormData();
            axios.get(`https://squadgoo.com.au/api.squadgoo.com.au/recruiters/${userdetails.login_token}?page=${page}&search=${search}&status=${statusType}&profilestatus=${profileStatusType}&recruitertype=${recruiterType}&fromDate=${fromDate}&toDate=${toDate}&orderby=${tableOrderType}`, formData)
               .then((response) => {
                  setDataisLoading(false);
                  if (response.status == 200 && response.data.status == 1) {
                    // console.log(response.data.data.export_userdata);
                     setjobseekerData(response.data.data.userdata);
                     setJobseekerDataCount(response.data.data.total_length);
                     setjobseekerExpData(response.data.data.export_userdata);
                     const calculatedTotalPages = Math.ceil(response.data.data.total_length / paginationLoadContentNo);
                     settotalPageCount(calculatedTotalPages);
                  }
               })
               .catch((error) => { console.error(error); });
         } catch (error) { console.log(error); }

      })();
   }

   const getUserDetails = (user_id) => {
      (async () => {
         setDataLoading(true)
         try {
            const formData = new FormData();
            axios.get(`https://squadgoo.com.au/api.squadgoo.com.au/recruiters/${userdetails.login_token}/${user_id}`, formData)
               .then((response) => {
                  setDataLoading(false);
                  if (response.status == 200 && response.data.status == 1) {
                     setUserDtailsDataArr(response.data.userdata);
                  }
               })
               .catch((error) => { console.error(error); });
         } catch (error) { console.log(error); }

      })();
   }

   const getUserBlock = (user_id) => {
      (async () => {
         setDataLoading(true)
         try {
            const formData = new FormData();
            axios.get(`https://squadgoo.com.au/api.squadgoo.com.au/recruiterblock/${userdetails.login_token}/0/${user_id}?paramtype=block`, formData)
               .then((response) => {
                  setDataLoading(false);
                  if (response.status == 200 && response.data.status == 1) {
                     setisMessageShow(true); setIsBlockModelShow(false); getRecruiterData();
                  }
               })
               .catch((error) => { console.error(error); });
         } catch (error) { console.log(error); }

      })();
   }

   //useEffect(() => { getRecruiterData(); }, []);
   useEffect(() => { getRecruiterData(); }, [currentActivePage, statusType, profileStatusType, recruiterType ,tableOrderType]);

   const searchKeyValue = (searchkey) => { getRecruiterData(searchkey); setSearchText(searchkey) }
   const RenderPaginationNumbers = () => {
      const paginationNumbers = [];
      paginationNumbers.push(<li className="page-item" ><Link onClick={(e) => handlePageChange(currentActivePage - 1)} className="page-link" href="#"><i className="fa fa-angle-left" aria-hidden="true"></i></Link></li>)
      for (let i = 1; i <= totalPageCount; i++) { paginationNumbers.push(<li key={i} className={i === currentActivePage ? 'page-item active' : 'page-item'} ><Link onClick={() => handlePageChange(i)} className="page-link" href="#">{i}</Link></li>); }
      paginationNumbers.push(<li className="page-item" ><Link onClick={(e) => handlePageChange(currentActivePage + 1)} className="page-link" href="#"><i className="fa fa-angle-right" aria-hidden="true"></i></Link></li>)
      return paginationNumbers;
   };
   const handlePageChange = (newPage) => { if (newPage >= 1 && newPage <= totalPageCount) { setCurrentActivePage(newPage); } };

   /*--------------------------*/


   const statsOption = ['all', 'verified', 'unverified'];
   const recTyeOption = [
      { key: 'all', value: 'Type' },
      { key: 'rec_owners', value: 'Company' },
      { key: 'rec_indivisual', value: 'Individual' },
      { key: 'rec_reprasentative', value: 'Representative' },
   ];

   const recTypeBlockUnBlock = [
      { key: 'all', value: 'Status' },
      { key: 0, value: 'Blocked' },
      { key: 1, value: 'Un Blocked' },
   ];


   //const onStatusChange = (type) => { setstatusType(type) }



   /*-------------------action button -----------------*/
   const [currentEditId, setcurrentEditId] = useState(0);
   const [dataLoading, setDataLoading] = useState(false);
   const [isMessageShow, setisMessageShow] = useState(false);

   const [isUserDetailsModelShow, setIsUserDetailsModelShow] = useState(false);
   //const closeUserModelShow  = () => {  setIsUserDetailsModelShow(false) }
   const showUserDetails = (id) => { setcurrentEditId(id); getUserDetails(id); setIsUserDetailsModelShow(true); }

   //isUserDetailsModelShow closeUserModelShow

   const [isBlockModelShow, setIsBlockModelShow] = useState(false);
   const [isActivated, setisActivated] = useState(false);
   //const closeBlockModelShow  = () => { setIsBlockModelShow(false) }
   const showUserBlockAction = (id, status) => { setcurrentEditId(id); setIsBlockModelShow(true); setisActivated(status) }
   const showUserBlockActionExecute = () => { getUserBlock(currentEditId) }


   const showUserEmailDetails = (type) => { }


   /*------------------------date filter-------------------------*/

   const [hideSeekDateField, setHideSeekDateField] = useState(false);

   const handleDateChange = (startDate, endDate) => {
      // Filter the data based on the selected date range
      // const filtered = jobseekerDataArr.filter((item) => {
      //   const itemDate = new Date(item.created_date);
      //   return (
      //     (!startDate || itemDate >= startDate) &&
      //     (!endDate || itemDate <= endDate)
      //   );
      // });

      // jobseekerDataArr(filtered);
   };



   const GetRecruiterType = (attr) => {
      var returnValue = 'recruiter';
      switch (attr.data) {
         case 'rec_indivisual':
            returnValue = 'Individual';
            break;
         case 'rec_owners':
            returnValue = 'Owners';
            break;
         case 'rec_reprasentative':
            returnValue = 'Representative';
            break;
      }
      //console.log(returnValue)
      return returnValue;
   };



   /*------------------------date filter-------------------------*/

   //const [hideSeekDateField, setHideSeekDateField] = useState(false);
   const [fromDate, setFromDate] = useState('');
   const [toDate, setToDate] = useState('');

   const handleFromDateChange = (event) => {
      const newFromDate = event.target.value;
      // Check if newFromDate is less than toDate
      if (!toDate || new Date(newFromDate) < new Date(toDate)) {
         setFromDate(newFromDate);
      } else { setFromDate(toDate); setToDate(newFromDate); }
   };

   const handleToDateChange = (event) => {
      const newToDate = event.target.value;
      if (!fromDate || new Date(newToDate) > new Date(fromDate)) {
         setToDate(newToDate);
      } else { setToDate(fromDate); setFromDate(newToDate); }
   };

   useEffect(() => {
      if (fromDate && toDate) { getRecruiterData(); }
   }, [fromDate, toDate]);


   return (
      <>
         <div className="dashboarwrap">
            <div className="pagebanner">
               <div className="container">
                  <div className="row">
                     <div className="col-md-12">
                        <h2>Recruiters</h2>
                     </div>
                  </div>
               </div>
            </div>

            <div className="adminwrap py-4">
               <div className="container">
                  <div className="row">
                     <div className="col-md-3 adminsidebar">
                        <SuperAdminSidebar></SuperAdminSidebar>
                     </div>
                     <div className="col-md-9">
                        <div className="box mb-4">
                           {/* <div className="box-ttl mb-3 d-flex align-items-center justify-content-between">
                              <h3>Recruiters</h3>
                           </div> */}
                           <div className="table_filter mb-2 d-flex align-items-center justify-content-between commonform">
                              {/* <div className="tblsearch">
                                 <input onChange={(e) => searchKeyValue(e.target.value)} type="text" className="form-control" placeholder="Search" />
                              </div>  */}
                                <div className="tblsearch2">
                                    <input onChange={(e) => searchKeyValue(e.target.value)} value={searchText} type="text" className="form-control" placeholder="Search" fdprocessedid="imthp7" />
                                 </div>
                              <div className="d-flex align-items-center datefilter ">

                                 <PdfExportButton jsonData={jobseekerExpData} export_for={'recruiter'} />
                                 <ExcelExportButton jsonData={jobseekerExpData} export_for={'recruiter'} />
                                 <span onClick={() => { 
                                             setSearchText(''); 
                                             setstatusType('all'); 
                                             setRecruiterType('all'); 
                                             setProfileStatusType('all'); 
                                             setFromDate(''); 
                                             setToDate('') ;
                                             getRecruiterData(); 
                                             }} 
                                             className="reseticon filtericon"   data-toggle="tooltip" title="Reset filters" >
                                    <i className="fa fa-refresh"></i>
                                 </span>
                              </div>
                           </div>



                           <div className="table_filter mb-2 d-flex align-items-center justify-content-between commonform">
                              {/* <div className="tblsearch">
                                 <input onChange={(e) => searchKeyValue(e.target.value)} type="text" className="form-control" placeholder="Search" />
                              </div>  */}
                              <div className="d-flex align-items-center datefilter">

                               
                                 <input value={fromDate} onChange={handleFromDateChange} type="date" className="form-control  text-capitalize mr-2"></input>
                                 <span>To</span>
                                 <input value={toDate} onChange={handleToDateChange} type="date" className="form-control  text-capitalize ml-2"></input>

                                 {/* statusType recruiterType profileStatusType */}
                                 <select className="form-control ml-3 text-capitalize" onChange={(e) => setstatusType(e.target.value)} >
                                    {
                                       statsOption.map((item, index) => (
                                          <option selected={statusType == item} value={item} key={index}>{item}</option>
                                       ))
                                    }
                                 </select>
                                 <select className="form-control ml-3 text-capitalize" onChange={(e) => setRecruiterType(e.target.value)} >
                                    {
                                       recTyeOption.map((item, index) => (
                                          <option selected={recruiterType == item.key} value={item.key} key={item.key}>{item.value}</option>
                                       ))
                                    }
                                 </select>
                                 <select className="form-control ml-3 text-capitalize" onChange={(e) => setProfileStatusType(e.target.value)} >
                                    {
                                       recTypeBlockUnBlock.map((item, index) => (
                                          <option selected={profileStatusType == item.key} value={item.key} key={item.key}>{item.value}</option>
                                       ))
                                    }
                                 </select>
                              </div>
                           </div>

                           <div className="table-responsive">
                              {
                                 !dataisLoading ?
                                    <>
                                       <table className="table admintbl profiletbl">
                                          <thead>
                                             <tr>
                                                <th onClick={() => settableOrderType((tableOrderType == 'ASC' ? 'DESC' :'ASC'))}>
                                                {tableOrderType == 'ASC' ? <i className="fa fa-angle-down ml-5"></i> : <i className="fa fa-angle-up ml-5"></i> }
                                                </th>
                                                <th>User Id</th>
                                                <th>Full Name</th>
                                                <th>Email</th>
                                                <th>Phone number</th>
                                                <th>Reg Date</th>
                                                <th>Action</th>
                                             </tr>
                                          </thead>
                                          <tbody>
                                             {
                                                jobseekerDataArr.length ?
                                                   jobseekerDataArr.map((item, index) => (
                                                      <>
                                                         {/* <tr key={item.unique_id} className={(item.status == 1) ? 'active_tr' : 'deactive_tr'} > */}
                                                         <tr key={item.unique_id} className={`${item.status == 1 ? 'active_tr' : 'deactive_tr'}  ${item.profile_enabled == 1 ? 'profile_is_enabled' : 'profile_is_disabled'}`} >
                                                            <td>
                                                               {
                                                                  item.user_type_role == 'rec_owners' ?
                                                                     <img className="usertypeicon" data-toggle="tooltip" data-placement="top" title="Owner" src={'front/images/building.svg'}></img>
                                                                     :
                                                                     <img className="usertypeicon" data-toggle="tooltip" data-placement="top" title="Individual" src={'front/images/profile.svg'}></img>
                                                               }
                                                            </td>
                                                            <td >
                                                               <span className='pl-5'>{item.unique_id}</span>
                                                            </td>
                                                            <td className='text-capitalize' >
                                                            <Link to={`/recruiter-detail?id=${item.unique_id}`} className="ml-3"  data-toggle="tooltip" title="View Recruiter's Detail">
                                                               <span className='pl-5'>{item.first_name + ' ' + item.last_name}</span>
                                                            </Link>
                                                            </td>
                                                            <td>{item.email ? item.email : '----------'}</td>
                                                            <td>{item.contact_number ? `${item.contact_country_code ? '+' + item.contact_country_code + '-' : ''}` + item.contact_number : '----------'}</td>
                                                            <td>{item.created_date ? item.created_date : '---'}</td>
                                                            <td>
                                                               <div className="d-flex">
                                                                  {(viewAccessibility || editAccessibility) && <Link to={`/recruiter-detail?id=${item.unique_id}`} className="ml-3"  data-toggle="tooltip" title="View Recruiter's Detail"><img src="/front/images/Show.svg" /></Link>}

                                                                  {deleteAccessibility && <Link onClick={(e) => showUserBlockAction(item.user_id, item.status)} className="ml-3">
                                                                     <span className="custom-tooltip" data-toggle="tooltip" title={item.status == 1 ? 'Do you want to block account?' : 'Do you want to unblock account?'} >
                                                                        {/* <i className={item.status == 1 ? 'fabanicon fa fa-circle-o' : 'fabanicon fa fa-ban text-danger'} aria-hidden="true"></i> */}

                                                                        {item.status == 1 ? <img className='block_unblock_icon' src="/front/images/blocked.png" /> : <img className='block_unblock_icon' src="/front/images/unblocked.png" />}
                                                                        
                                                                     </span>
                                                                  </Link>}

                                                               </div>
                                                            </td>
                                                         </tr>
                                                      </>
                                                   ))
                                                   :
                                                   <>
                                                      <tr>
                                                         <td className='text-center' colSpan={8}>No data available.</td>
                                                      </tr>
                                                   </>
                                             }
                                          </tbody>
                                       </table>

                                    </>
                                    :
                                    <>
                                       <div className={`tab-pane fade show active mt45px`} >
                                          <div className='loader_container'>
                                             <p className='text-center'><img className='width-100 loader_image' src={'/front/images/loader.gif'} /></p>
                                          </div>
                                       </div>
                                    </>
                              }

                              {
                                 ((jobseekerDataCount > paginationLoadContentNo)) ?
                                    <>
                                       <div className="card-box">
                                          <nav aria-label="Page navigation">
                                             <ul className="pagination mb-0 justify-content-center">
                                                <RenderPaginationNumbers />
                                             </ul>
                                          </nav>
                                       </div>
                                    </>
                                    : ''
                              }
                              {/* <div className="text-center"><a href="#" className="batn batn-orange mt-2">View All profiles</a></div> */}
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </div>


         {/* jobaccept model chat redirect */}

         <Modal className="modal fade modalsmall jobacceptmodle" id="jobacceptmodle" show={isBlockModelShow} onHide={() => setIsBlockModelShow(false)}>
            <div className="modal-dialog1">
               <div className="modal-content">
                  <div className="modal-body">
                     <button type="button" className="btn-close" onClick={() => setIsBlockModelShow(false)}></button>
                     <div className="text-center mb-3">
                        {/* <img src="/front/images/greencheck.svg" /> */}
                     </div>
                     <h5 className="text-center mb-4">Do you want to {isActivated == 1 ? 'block' : 'unblock'} account?</h5>

                     <div className="btnsgroup text-center">
                        <Link onClick={showUserBlockActionExecute} className="batn batn-orange" >Yes</Link>
                        <Link onClick={() => setIsBlockModelShow(false)} className="batn batn-orange batn-border">No</Link>
                     </div>
                  </div>
               </div>
            </div>
         </Modal>
         <Modal className="modal fade modallarg jobacceptmodle" id="jobacceptmodle" show={isUserDetailsModelShow} onHide={() => setIsUserDetailsModelShow(false)}>
            <div className="modal-dialog1">
               <div className="modal-content">
                  <div className="modal-body">
                     <button type="button" className="btn-close" onClick={() => setIsUserDetailsModelShow(false)}></button>
                     <div className="joblistbox">

                        {
                           !dataLoading ?
                              <>
                                 <div className="d-flex flex-wrap justify-content-between">
                                    {console.log(userDtailsDataArr)}
                                    <div className="joblistbox">
                                       <div className="d-flex flex-wrap justify-content-between">
                                          <h3>{userDtailsDataArr.job_title_text}</h3>
                                          <h3 className="job_price orange_clr">
                                             <strong>{userDtailsDataArr.salary_minimum ? '$' + userDtailsDataArr.salary_minimum + '/Hour' : '--'}</strong>
                                             <strong className="subheadpera">To</strong>
                                             <strong>{userDtailsDataArr.salary_maximum ? '$' + userDtailsDataArr.salary_maximum + '/Hour' : '--'}</strong>
                                          </h3>
                                       </div>
                                       <div><span className="tags">{userDtailsDataArr.job_type_text}</span></div>
                                       {/* <p>Maecenas tempus, tellus eget condimentum rhoncus, sem quam semper libero, sit amet adipiscing sem neque sed ipsum. Sed hendrerit. Maecenas nec odio et ante condimentum rhoncus, sem quam semper libero condimentum rhoncus, sem quam...</p> */}
                                       <div className="d-flex flex-wrap mt-2">
                                          <div className="icontxt"><img src="/front/images/calender.png"></img> Offer date: <span>{userDtailsDataArr.created_date_format ?? '.....'}</span></div>
                                          <div className="icontxt"><img src="/front/images/clock.png"></img> Offer expire date: <span>{userDtailsDataArr.expired_date_format ?? '.....'}</span></div>
                                          <div className="icontxt"><img src="/front/images/location.png"></img> Location: <span>{userDtailsDataArr.job_location_text ?? '......'}</span></div>
                                          <div className="icontxt"><img src="/front/images/bag.png"></img> Job type: <span>{userDtailsDataArr.job_type_text}</span></div>
                                          <div className="icontxt"><img src="/front/images/star.png"></img> Experience: <span>{userDtailsDataArr.total_experience_month + '.' + userDtailsDataArr.total_experience_year + ' Years'}</span></div>
                                       </div>
                                       <hr>
                                       </hr>
                                       <h5>Availability to work</h5>
                                       <p>Jobseeker should available on below time.</p>
                                       {/* <Availability  abdata={userDtailsDataArr.availibility} ></Availability> */}



                                       <hr></hr>
                                       <h5>Paying extra for</h5>
                                       <ul>
                                          <li className='text-capitalize'>Public holidays : {userDtailsDataArr.are_you_paying_extra_in_holiday}  </li>
                                          <li className='text-capitalize'>Weekend : {userDtailsDataArr.are_you_paying_extra_in_weekend} </li>
                                          <li className='text-capitalize'>Overtime : {userDtailsDataArr.are_you_paying_extra_in_overtime} </li>
                                       </ul>


                                       <hr></hr>

                                       <h5>Education qualification</h5>
                                       <p>Jobseeker should have following degree and diplomas:</p>
                                       <ul>
                                          <li>{userDtailsDataArr.education_qualification_text}</li>
                                       </ul>
                                       <hr>
                                       </hr>

                                       <h5>Language known</h5>
                                       <p>Jobseeker should be fluent in following languages:</p>
                                       <ul>
                                          <li>{userDtailsDataArr.language_text}</li>
                                       </ul>
                                       <hr>
                                       </hr>
                                       <h5>About recruiter</h5>
                                    </div>
                                 </div>
                              </>
                              :
                              <>
                                 <div className={`tab-pane fade show active mt45px`} >
                                    <div className='loader_container'>
                                       <p className='text-center'><img className='width-100 loader_image' src={'/front/images/loader.gif'} /></p>
                                    </div>
                                 </div>
                              </>
                        }

                     </div>
                     {/* <div className="btnsgroup text-center">
                        <Link to={userAccountStatusChange} className="batn batn-orange" >Yes</Link>
                        <Link onClick={closeUserModelShow} className="batn batn-orange batn-border">No</Link>
                     </div> */}
                  </div>
               </div>
            </div>
         </Modal>
         <Modal className="modal fade modalsmall jobacceptmodle" id="jobacceptmodle" show={isMessageShow} onHide={() => setisMessageShow(false)}>
            <div className="modal-dialog1">
               <div className="modal-content">
                  <div className="modal-body">
                     <button type="button" className="btn-close" onClick={() => setisMessageShow(false)}></button>
                     <div className="text-center mb-3">
                        <img src="/front/images/greencheck.svg" />
                     </div>
                     {/* <h5 className="text-center mb-4">Successfully </h5> */}
                  </div>
               </div>
            </div>
         </Modal>


      </>
   );
}
export default Jobseekers;