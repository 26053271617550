import React, { useState, useEffect, Component } from 'react';
import { BrowserRouter as Router, Routes, Route, NavLink, useParams, useNavigate } from 'react-router-dom';
import { Form } from 'react-bootstrap';
import axios from 'axios';
import ProfileBanner from './profile_header';
import ProfileSidebar from './profile_sidebar';
import Select from 'react-select';
import { toast } from 'react-toastify';
//import 'react-toastify/dist/ReactToastify.css';
import ModalDialog from '../expocomp/WarningModal';

import CheckValidationComp from './CheckValidationComp';
import customStyles from '../expocomp/SelectStyleComp';
const EduandQualification = () => {

   const userdetails = JSON.parse(localStorage.getItem("squadGologin"));
   const [allQualificationList, setAllQualificationList] = useState([]);
   const [allLanguageList, setAllLanguageList] = useState([]);
   const [allLanguageListOption, setAllLanguageListOption] = useState([]);
   const [selectedOptionsLanguage, setSelectedOptionsLanguage] = React.useState([{ value: '1', label: 'English' }]);
   const [selectedOptionsEductions, setSelectedOptionsEductions] = React.useState([]);
   const [isHidden, setIsHidden] = useState(true);
   const [userDetailsArray, setUserDetailsArray] = useState([]);
   const [isAccVerified, setIsAccVerified] = useState('false');
   const [validated, setValidated] = useState(false);
   const [allQualificationListOptions, setAllQualificationListOptions] = useState([]);
   const [isDocumentsModalShow, isSetDocumentsModalShow] = useState(false);
   const [fileLoadingContent, setfileLoadingContent] = useState(false);

   const [qualificationProofDocuments, setqualificationProofDocuments] = useState([]);
   const [isAddedQulification, setisAddedQulification] = useState(false);
   const [isAddedLanguage, setisAddedLanguage] = useState(false);
   const [loadingContent, setLoadingContent] = useState(false);

   const handleSelectChangeEduction = (selected) => { setSelectedOptionsEductions(selected); };
   const handleSelectChangeLanguage = (selected) => { setSelectedOptionsLanguage(selected); };
   const toggleHidden = () => { setIsHidden(!isHidden); };
   const hideFormDetails = () => { setIsHidden(true); }

   useEffect(() => {
      if (userdetails.unique_id) { getUserDetails() } else { setTimeout(function () { window.location.replace("/login"); }, 100) }
   }, []);

   useEffect(() => {

      const sessionStorageData = JSON.parse(localStorage.getItem("sessionStorageData"));
      if (sessionStorageData) {
         setAllLanguageListOption(sessionStorageData.get_all_language.option);
         setAllQualificationList(sessionStorageData.get_all_eduqualification.results);
         setAllQualificationListOptions(sessionStorageData.get_all_qualifications_options);
      } else {
         (async () => {
            try {
               const resData = await axios.get("https://squadgoo.com.au/api.squadgoo.com.au/get_all_qualifications_options");
               if (resData.data.status == 1) {
                  setAllQualificationList(resData.data.data.results);
                  setAllQualificationListOptions(resData.data.data.option);
               }
            } catch (error) { console.log(error); }
            try {
               const resData = await axios.get("https://squadgoo.com.au/api.squadgoo.com.au/get_all_language");
               if (resData.data.status == 1) {
                  setAllLanguageListOption(resData.data.data.option); 
               }
            } catch (error) { console.log(error); }
         })();
      }

   }, []);

   const getUserDetails = () => {
      (async () => {
         try {
            const resData = await axios.get("https://squadgoo.com.au/api.squadgoo.com.au/userdetails/" + userdetails.unique_id);
            if (resData.data.status == 1) {
               const dataArr = resData.data.data.results;
               setUserDetailsArray(dataArr);
               setIsAccVerified(dataArr.verifications_status.statustype.qualification_details);
               setqualificationProofDocuments(dataArr.user_qualification_documents);
               setSelectedOptionsEductions(dataArr.all_qualifications ? JSON.parse(dataArr.all_qualifications) : []);

            }
         } catch (error) { console.log(error); }
      })();
   }




   const onvisaDetailsSubmit = async (e) => {
      e.preventDefault();
      const form = e.currentTarget;

      if (form.checkValidity() === false) {
         setValidated(true); // Show validation errors
         return; // Don't proceed if the form is invalid
      }

      if (isAccVerified == true) {
         setIsModalShow(true);
      } else {
         formSubmitData();
      }

      setValidated(false); // Reset validation state
   };

   const formSubmitData = async () => {
      try {
         const formData = new FormData();
         formData.append('all_qualifications', JSON.stringify(selectedOptionsEductions));
         if (userdetails.unique_id) {
            formData.append('userid', userdetails.unique_id);
         }
         const response = await axios.post(
            `https://squadgoo.com.au/api.squadgoo.com.au/userdetails_data_update`,
            formData,
            { headers: { 'Content-Type': 'multipart/form-data' } }
         );
         if (response.data.status === 1) {
            getUserDetails(); // Refresh user details
            toast.success(response.data.message);
            setIsModalShow(false); // Close the modal
         } else {
            toast.warning(response.data.message);
            setIsModalShow(false); // Close the modal
         }
      } catch (error) {
         console.error('Error during form submission:', error);
         toast.error('An error occurred while submitting the form.');
      }
   };


   /*-----------------file remove code-------------------------*/
   const userFileUploadToServerVisaFun = async (field, file, reference_id = 0) => {
      const maxSizeInBytes = 10 * 1024 * 1024; // For example, 1 MB
      if (file.size > maxSizeInBytes) {
         //console.log("File size is greater than the specified limit.");
         toast.warning('File size is greater than the specified limit.');
      } else {
         // console.log("File size is within the specified limit.");
         const queryParameters = new URLSearchParams(window.location.search)
         try {
            setLoadingContent(1);
            const formData = new FormData();
            formData.append('file', file);
            formData.append('uploaded_for', field);
            formData.append('userid', userdetails.unique_id);
            formData.append('reference_id', reference_id);
            formData.append('maxSizeInBytes', maxSizeInBytes);
            const response = await axios.post(`https://squadgoo.com.au/api.squadgoo.com.au/userdetails_file_uploads`,
               formData, { headers: { 'Content-Type': 'multipart/form-data' } });
            setLoadingContent(0);
            if (response.data.status == 1) {
               setqualificationProofDocuments(response.data.data.upload_array)
            } else { toast.warning(response.data.message); }
         } catch (error) { console.error('Error uploading file:', error); }
      }
   };
   const editFileFromServer = async (field, id) => { }
   const removeFileFromServer = async (field, id) => {
      if (window.confirm("Do you want to remove this file?")) {
         try {
            const formData = new FormData();
            formData.append('field', field);
            formData.append('file_id', id);
            const response = await axios.post(
               `https://squadgoo.com.au/api.squadgoo.com.au/userdetails_upload_file_remove`,
               formData, { headers: { 'Content-Type': 'multipart/form-data' } });
            if (response.data.status == 1) {
               toast.success('Removed Successfully!');
               setqualificationProofDocuments(response.data.data)
            } else { toast.warning(response.data.message); }
         } catch (error) { console.error('Error uploading file:', error); }
      }
   }

   /*--------------------------------------------------------*/
   const [isModalShow, setIsModalShow] = useState(false);
   const actionConfirmed = () => { formSubmitData(); };

   return (
      <>
         {/* <ToastContainer />*/}

         <div className="dashboarwrap">
            <ProfileBanner />
            <div className="jobbtmsec py-5">
               <div className="container">

                  <div className="row">
                     <div className="col-md-3 profilesidebarwrap">
                        <ProfileSidebar />
                     </div>
                     <div className="col-md-9">
                        <div className="profilerightwraper stepscont commonform basicdetail">

                           <div className="card-box license-sec tfn-sec">
                              <div className="">
                                 <div className="d-flex justify-content-between">
                                    <h3 className="mb-3">
                                       Extra Qualification
                                       {
                                          isAccVerified == true ?
                                             <>
                                                <img className='veri_unveri_icon' src="/front/images/vfy.png" data-toggle="tooltip" data-placement="top" title="verified"></img></> :
                                             isAccVerified == false ?
                                                <><img className='veri_unveri_icon' src="/front/images/uvfy.png" data-toggle="tooltip" data-placement="top" title="Your Profile is in Review"></img></> :
                                                <></>
                                       }
                                    </h3>
                                    {/* {isAccVerified == false && <div className="editbtn" onClick={toggleHidden}  ><img src="/front/images/edit.png" /></div>} */}
                                    {<div className="editbtn" onClick={toggleHidden}  ><img src="/front/images/edit.png" /></div>}
                                 </div>



                                 <div className="d-flex align-items-center justify-content-between">
                                    <p className="mb-0 largepera">Qualification</p>
                                    <div className="d-flex flex-wrap">
                                       <p>

                                          {
                                             selectedOptionsEductions.length < 2 &&
                                             selectedOptionsEductions.map((item, index) => (
                                                <>{item.label} </>
                                             ))
                                          }
                                       </p>

                                    </div>

                                 </div>
                                 <p>
                                    {
                                       selectedOptionsEductions.length > 1 &&
                                       selectedOptionsEductions.map((item, index) => (
                                          <>{item.label} {index == selectedOptionsEductions.length - 1 ? '' : ','} </>
                                       ))
                                    }
                                 </p>




                                 {!isHidden &&
                                    <div className="">
                                       <Form noValidate validated={validated} onSubmit={onvisaDetailsSubmit}>
                                          <div className="row mb-2">

                                             <div className="col-md-12">
                                                <div className="form-group mb-2 taxtype">
                                                   <label className="form-label">Highest Qualification <span className="starr">*</span></label>

                                                   <Select
                                                      className={`react_select ${isAddedQulification ? 'bordercss' : ''}`}
                                                      options={allQualificationListOptions}
                                                      isMulti
                                                      value={selectedOptionsEductions}
                                                      onChange={handleSelectChangeEduction}
                                                      placeholder="Select options..."
                                                      styles={customStyles}
                                                   />
                                                   {
                                                      (selectedOptionsEductions.length > 0) &&
                                                      selectedOptionsEductions.map((item, index) => (
                                                         <>
                                                            {
                                                               (!qualificationProofDocuments[index]) ?
                                                                  <>
                                                                     {
                                                                        <div className="form-group identityproof mt-2">
                                                                           <div className="customuploadsec">
                                                                              {!fileLoadingContent && <input type="file" accept="image/x-png,image/jpg,image/jpeg ,application/pdf"
                                                                                 onChange={(e) => userFileUploadToServerVisaFun(`qualification_proof`, e.target.files[0], item.value)}
                                                                              ></input>}
                                                                              <div className="customuploadinner text-center">
                                                                                 <div className="my-4"><img src="/front/images/uploadicon.png" className="mt-4"></img></div>
                                                                                 <p className="mb-0">{fileLoadingContent ? `Docuemnt is uploading.....` : `Document upload for ${item.label}`}</p>
                                                                              </div>
                                                                           </div>
                                                                        </div>
                                                                     }
                                                                     <p className="mt-2 purpleclr font12">Accept only jpg, jpeg, png, doc and pdf files (Max file size 10 MB)</p>
                                                                  </>
                                                                  :
                                                                  <>
                                                                     <div className="documentwrap d-flex align-items-center mt-2 mb-2">
                                                                        <a className='document_list' target="_blank" href={'https://squadgoo.com.au/api.squadgoo.com.au/' + qualificationProofDocuments[index].document_url}>
                                                                           <p>Qualification proof {index + 1} - {item.label}</p>
                                                                        </a>
                                                                        <div className="documentwrapinner d-flex align-items-center">
                                                                           <span className='cursor_pointer ml-2 editfile' onClick={e => editFileFromServer('qualification_proof', qualificationProofDocuments[index].id)}><img src="/front/images/edit.png" /></span>
                                                                           <span className='cursor_pointer ml-2' onClick={e => removeFileFromServer('qualification_proof', qualificationProofDocuments[index].id)}><img src="/front/images/delete.png" /></span>
                                                                        </div>
                                                                     </div>
                                                                  </>
                                                            }
                                                         </>
                                                      ))
                                                   }

                                                </div>
                                             </div>



                                          </div>
                                          <div className="col-md-12 mt-3">
                                             <button type='submit' className="batn batn-rounded rounded-orange mr-2">Save</button>
                                             <button type='button' onClick={hideFormDetails} className="batn batn-rounded rounded-orange mr-2">Cancel</button>
                                          </div>
                                       </Form>
                                       <ModalDialog
                                          show={isModalShow}
                                          onClose={() => setIsModalShow(false)} // Close the modal without taking action
                                          onConfirm={actionConfirmed} // Action to take when confirming
                                          title="Existing details are already verified, Are you sure to change it?"
                                          message="This may cause you to wait for re-verification from Squad Goo."
                                       />
                                    </div>
                                 }
                              </div>
                           </div>

                        </div>
                     </div>
                  </div>
               </div>
            </div>


            {/*  */}


         </div>
      </>
   );
}
export default EduandQualification;