import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { toast } from 'react-toastify';
import BankDetailsComp from '../BankDetailsComp';
import BANKLIST from '../../json/austrlia_bank_list.json'
const RecWalletWithdraw = ({ backToParent, total_available_coins, productdata }) => {

    const backToParentFun = (parma) => { backToParent(false); }

    console.log(productdata);
    const totalCoins = parseInt(total_available_coins);
    const [minWithDrawCoins, setMinWithDrawCoins] = useState(productdata.minimum_withdrawal_coins ? parseFloat(productdata.minimum_withdrawal_coins) : 10);
    const [transectionFee, settransectionFee] = useState(productdata.transaction_fee ? parseFloat(productdata.transaction_fee) : 1);
    const [coins, setCoins] = useState(productdata.minimum_withdrawal_coins ? parseFloat(productdata.minimum_withdrawal_coins) : 10);
    const [withdrawaBankAccountId, setwithdrawaBankAccountId] = useState(0);
    const [withdrawalAmount, setWithdrawalAmount] = useState(9);
    const [cutrrentActiveStep, setCutrrentActiveStep] = useState(1);
    const [productName, setProductName] = useState(productdata.name);
    const [errorMessage, setErrorMessage] = useState('');
    const useriddata = JSON.parse(localStorage.getItem("squadGologinSession"));

    const calculateWithdrawalAmount = (coins) => {
        const coinsValue = parseInt(coins) - parseFloat(transectionFee);
        //setTotalCoins(totalCoins-coinsValue);
        if (isNaN(coinsValue) || coinsValue < 0) {
            setWithdrawalAmount('');
            setErrorMessage('Please enter a valid number of coins.');
            return;
        }
        const exchangeRate = 1; // 4 coins = 1 USD
        const amountInUSD = coinsValue / 1 * exchangeRate;
        setWithdrawalAmount(amountInUSD.toFixed(2));
        setErrorMessage('');
    };

    const totalCoinsCount = () => {
        return parseInt(coins) + parseFloat(transectionFee)
    }

    // Function to handle withdrawal




    const finalSubmitData = async () => {
        if (coins > 0) {
            try {
                const formData = new FormData();
                formData.append('loginToken', useriddata.login_token);
                formData.append('user_id', useriddata.user_id);
                formData.append('coins_request', coins);
                formData.append('withdraw_account_id', withdrawaBankAccountId);
                const response = await axios.post('https://squadgoo.com.au/api.squadgoo.com.au/api/stripe/paymentwithdrwa-request', formData);
                if (response.status == 200 && response.data.status == 1) {
                    toast.success(response.data.message);
                    const timeoutId = setTimeout(() => { window.location.reload(); }, 1000);
                    return () => clearTimeout(timeoutId);
                } else { toast.warning(response.data.message); }
            } catch (err) { console.log(err); }
        }
    }






    useEffect(() => {
        if (coins > 1 && totalCoins >= coins) {
            if (minWithDrawCoins >= coins) {
                setErrorMessage(`The minimum withdrawal amount should be greater than ${minWithDrawCoins} coins.`);
            } else {
                calculateWithdrawalAmount(coins);
            }
        } else if (totalCoins <= coins) {
            setErrorMessage(`You can't enter coin value more then available coins.`);
        } else {
            setErrorMessage(`Please enter coins value.`);
        }
    }, [coins])

    const setAllCoins = () => {
        setCoins(parseInt(totalCoins));
    };


    const increment = () => {
        setCoins(prevQuantity => coins + 1);
    };

    const decrement = () => {
        if (coins > 1) {
            setCoins(prevQuantity => coins - 1);
        }
    };


    /*step2 functionality */


    const setActionNext = (id) => {
        let activeId = parseInt(id);
        setwithdrawaBankAccountId(activeId);
        console.log(activeId);
    }


    const findBankNameById = (id) => {
        const bank = BANKLIST.find(bank => bank.id === parseInt(id));
        return bank ? bank.name : 'Bank not found';
    };
    // const findBankAccountIDById = (id) => {
    //     const bank = BANKLIST.find(bank => bank.id === parseInt(id));
    //     return bank ? bank.name : 'Bank not found';
    // };

    return (
        <>

            <div className="row">
                <div className="col-md-12">
                    <div className="profilerightwraper stepscont walletblocks">
                        <div className="row justify-content-center">
                            <div className="">
                                {
                                    console.log(cutrrentActiveStep)
                                }
                                {/* <form> */}
                                {cutrrentActiveStep == 1 && <>
                                    <div id="step_one_form">
                                        <div class="staffbanner text-center">
                                            <h3>
                                                withdraw coins
                                            </h3>
                                        </div>

                                        {
                                            (minWithDrawCoins >= totalCoins) ?
                                                <>
                                                    <div class="alert alert-light text-center" style={{ margin: '10%' }} role="alert" >
                                                            <span>❗</span> {/* Exclamation mark icon */}
                                                            <span> You don't have enough coins to withdraw. 
                                                                {/* 😞 */}
                                                                <img src="/front/images/face2.png"></img>
                                                            </span> {/* Sad face icon */}
                                                    </div>
                                                </>
                                                :
                                                <>
                                                    <div class="m-3" role="alert">
                                                        <h3>Total avaialable coins: {totalCoins}</h3>
                                                        <div className="">
                                                            <label>Enter Coins:</label>
                                                        </div>
                                                        <div className="input-group">
                                                            <input type="number" className="form-control" value={coins} onChange={e => setCoins(parseInt(e.target.value))} />
                                                            <div className="input-group-append">

                                                                {(coins > minWithDrawCoins) && <button className="btn btn-outline-secondary" type="button" onClick={decrement}>-</button>}
                                                                {(totalCoins > coins) && <>
                                                                    <button className="btn btn-outline-secondary" type="button" onClick={increment}>+</button>
                                                                    <button className="btn btn-outline-secondary" type="button" onClick={setAllCoins}>Max</button>
                                                                </>}

                                                            </div>
                                                        </div>
                                                        <div>
                                                            {errorMessage && <div className="text-danger mb-3">{errorMessage}</div>}
                                                        </div>
                                                        {

                                                            <div className="transection_table">
                                                                <table className='table table-striped text-right' >
                                                                    <tr>
                                                                        <th>Withdraw coins  ({productName}) </th>
                                                                        <td>{coins}</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <th>Transection fees ({productName}) </th>
                                                                        <td>{transectionFee}</td>
                                                                    </tr>

                                                                    <tr>
                                                                        <th>Total Coins ({productName}) </th>
                                                                        <td>{parseFloat(coins) - parseFloat(transectionFee)}</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <th>Total Amount  (USD) </th>
                                                                        <td>${withdrawalAmount}</td>
                                                                    </tr>
                                                                </table>
                                                            </div>

                                                        }



                                                    </div>
                                                    <div className='transectionFormSection'>
                                                        <button type="button" disabled={errorMessage ? true : false} className="batn batn-orange1 btncolor backbtnchkout" onClick={() => setCutrrentActiveStep(2)}  >Next</button>
                                                    </div>
                                                </>
                                        }
                                    </div>
                                </>}

                                {cutrrentActiveStep == 2 && <>
                                    <div id="step_two">
                                        <div class="staffbanner text-center">
                                            <h3>
                                                <span class="text-white cursor_pointer" onClick={() => setCutrrentActiveStep(1)}><i class="fa fa-chevron-left" aria-hidden="true"></i></span>
                                                Bank Details
                                            </h3>
                                        </div>
                                        <BankDetailsComp setActionNextChild={setActionNext} />
                                        <div className='transectionFormSection'>
                                            <button type="button" className="batn batn-orange1 btncolor backbtnchkout mt-2 ml-3 mb-3" onClick={() => setCutrrentActiveStep(3)}  >Next</button>
                                        </div>
                                    </div>
                                </>}

                                {cutrrentActiveStep == 3 && <>
                                    <div id="step_two">
                                        <div class="staffbanner text-center">
                                            <h3>
                                                <span class="text-white cursor_pointer" onClick={() => setCutrrentActiveStep(1)}><i class="fa fa-chevron-left" aria-hidden="true"></i></span>
                                                Form summary
                                            </h3>
                                        </div>
                                        <div className='dd m-3'>
                                            <div className="transection_table">
                                                <table className='table table-striped text-right' >
                                                    <tr>
                                                        <th>Withdraw coins  ({productName}) </th>
                                                        <td>{coins}</td>
                                                    </tr>
                                                    <tr>
                                                        <th>Transection fees ({productName}) </th>
                                                        <td>{transectionFee}</td>
                                                    </tr>

                                                    <tr>
                                                        <th>Total Coins ({productName}) </th>
                                                        <td>{parseFloat(coins) - parseFloat(transectionFee)}</td>
                                                    </tr>
                                                    <tr>
                                                        <th>Total Amount  (USD) </th>
                                                        <td>${withdrawalAmount}</td>
                                                    </tr>
                                                    <tr>
                                                        <th>Bank Name </th>
                                                        <td>{findBankNameById(withdrawaBankAccountId)}</td>
                                                    </tr>
                                                    {/* <tr>
                                                        <th>Bank Name </th>
                                                        <td>{findBankAccountIDById(withdrawaBankAccountId)}</td>
                                                    </tr> */}
                                                </table>

                                            </div>
                                        </div>

                                        <div className='transectionFormSection'>
                                            <button type="button" className="batn batn-orange1 btncolor backbtnchkout mt-2  mb-3" onClick={() => finalSubmitData()}  >Withdraw Now</button>
                                        </div>
                                    </div>
                                </>}
                                {/* </form> */}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}
export default RecWalletWithdraw;