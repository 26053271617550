import React, { useState, useEffect } from 'react';
import { Link } from "react-router-dom";
import ProfileNotCompleted from '../../front/pages/profilenotcompleted';
import axios from 'axios';
import { Form, Modal } from 'react-bootstrap';
import JobDesModalcomp from './JobDesModalcomp'
import CheckValidationComp from '../CheckValidationComp';
const Dashboard = () => {

   const [currentScreen, setcurrentScreen] = useState('active_job');
   const [offerDataActivatedArray, setOfferDataActivatedArray] = useState([]);
   const [offerDataAcceptedArray, setOfferDataAcceptedArray] = useState([]);
   const [offerDataDeclinedArray, setOfferDataDeclinedArray] = useState([]);
   const [offerDataExpiredArray, setOfferDataExpiredArray] = useState([]);
   const [dataLength, setDataLength] = useState({ length_activated: 0, length_completed: 0, length_declined: 0, length_expired: 0 });
   const [dataisLoading, setDataisLoading] = useState(true);
   const [isLoadingAcceptedOffer, setisLoadingAcceptedOffer] = useState(false);
   const [isModalDataLoad, setIsModalDataLoad] = useState(false);
   const [staffViewDetailsDataArr, setstaffViewDetailsDataArr] = useState([]);
   const userdetails = JSON.parse(localStorage.getItem("squadGologinSession"));
   const [shortBySearchtype , setShortBySearchtype ] = useState('');
   const [filterActionTye, setfilterActionTye] = useState('weekly');
   const [currentActivePage, setCurrentActivePage] = useState(1);
   
   const [callingScreenType, setCallingScreenType] = useState('all');
   const [shortByFilter, setshortByFilter] = useState('latest');
   const ShortByFilter = [
      { key: 1, value: 'latest', name: 'All offers' },
      { key: 2, value: 'last_week', name: 'Last Week' },
      { key: 3, value: 'last_2_week', name: 'Last 2 Week' }
   ];

   const ShortByQuickSearch = [
      { key: 1, value: '', name: 'Select Searchtype' },
      { key: 2, value: 'manually', name: 'Manually' },
      { key: 3, value: 'quick_search', name: 'Quick search' }
   ];

   const shortByFilterfun = (type) => {
      setshortByFilter(type)
   }

   const [isUserVerified, setisUserVerified] = useState('false');

   const getDashboardData = () => {
      (async () => {
         setDataisLoading(true);
         try {
            const formData = new FormData();
            axios.get(`https://squadgoo.com.au/api.squadgoo.com.au/get_jobs_for_jobseeker/${userdetails.login_token}?shortby=${shortByFilter}&searchtype=${shortBySearchtype}&actiontype=${filterActionTye}`, formData)
               .then((response) => {
                  setDataisLoading(false);
                  if (response.status == 200 && response.data.status == 1) {
                     setOfferDataActivatedArray(response.data.data.activated);
                     setOfferDataAcceptedArray(response.data.data.completed);
                     setOfferDataDeclinedArray(response.data.data.declined);
                     setOfferDataExpiredArray(response.data.data.expired);
                     setDataLength(response.data.data.length);
                     setisUserVerified(response.data.data.verification.verification_status);
                  }
               })
               .catch((error) => { console.error(error); });
         } catch (error) { console.log(error); }

      })();
   }
   useEffect(() => {
      getDashboardData();
   }, [shortByFilter,currentActivePage, callingScreenType,shortBySearchtype]);


   const [offerID, setofferID] = useState(0);

   /*-------------------jobdetail module--------------------*/
   const [isJobDetailsModalShow, setIsJobDetailsModalShow] = useState(false);
   const jobDetailModuleShow = (offer_id, job_id) => {
      setofferID(offer_id); setIsModalDataLoad(true); setIsJobDetailsModalShow(true);
      (async () => {
         try {
            const formData = new FormData();
            axios.get(`https://squadgoo.com.au/api.squadgoo.com.au/get_jobdetail_for_jobseeker/${userdetails.login_token}/${job_id}/${offer_id}`, formData)
               .then((response) => {
                  setIsModalDataLoad(false);
                  if (response.status == 200 && response.data.status == 1) { setstaffViewDetailsDataArr(response.data.data); }
               })
               .catch((error) => { console.error(error); });
         } catch (error) { console.log(error); setIsModalDataLoad(false); }

      })()
   }
   const closeJobDetailModal = () => { setIsJobDetailsModalShow(false) }

   /*-------------------offerAccept module--------------------*/
   const [isJobAcceptModalShow, setIsJobAcceptModalShow] = useState(false);
   const jobAcceptModuleShow = (id) => { setofferID(id); closeJobDetailModal(); setIsJobAcceptModalShow(true) }
   const closeJobAcceptModal = () => { setIsJobAcceptModalShow(false) }
   const offerAccepted = async (offid) => {
      setofferID(offid); setisLoadingAcceptedOffer(true);
      try {
         const formData = new FormData();
         formData.append('offerid', offid);
         formData.append('staff_unique_id', userdetails.unique_id);
         formData.append('declined_message', '');
         formData.append('login_token', userdetails.login_token);
         axios.post('https://squadgoo.com.au/api.squadgoo.com.au/offeraccepted', formData)
            .then((response) => { setisLoadingAcceptedOffer(false); if (response.status == 200 && response.data.status == 1) { getDashboardData(); /*toast.success('Accepted Successfully!');*/ } jobAcceptModuleShow(offid) })
            .catch((error) => { console.error(error); });
      } catch (error) { console.log(error); }
      setValidated(true);
   };
   const [validated, setValidated] = useState(false);
   /*-------------------offerDecline module--------------------*/

   const [isAreYouSureDeclineModalShow, setIsAreYouSureDeclineModalShow] = useState(false);
   const [isDeclineSuccessModalShow, setIsDeclineSuccessModalShow] = useState(false);
   const jobAreYouSureDeclineModuleShow = (id) => { setofferID(id); setIsDeclineModalShow(false); setIsAreYouSureDeclineModalShow(true) }
   const closeAreYouSureDeclineModal = () => { setIsAreYouSureDeclineModalShow(false) }
   const [isDeclineModalShow, setIsDeclineModalShow] = useState(false);
   const jobDeclineModuleShow = (id) => { setDeclineReasonDefault(false); setDeclineReasonDefaultValue(''); setofferID(id); closeJobDetailModal(); setIsDeclineModalShow(true); setIsAreYouSureDeclineModalShow(false); }
   const closeDeclineModal = () => { setIsDeclineModalShow(false) }
   const [declineReasonDefaultValue, setDeclineReasonDefaultValue] = useState('');
   const onDeclineMessageSend = async (event) => {
      event.preventDefault();
      const form = event.currentTarget;
      if (form.checkValidity() === false) { event.preventDefault(); event.stopPropagation(); }
      else {
         try {

            console.log(event.target.declined_reasons)
            const formData = new FormData();
            formData.append('offerid', offerID);
            formData.append('staff_unique_id', userdetails.unique_id);
            formData.append('declined_message', declineReasonDefaultValue);
            formData.append('declined_reasons', event.target.declined_reasons.value);
            formData.append('login_token', userdetails.login_token);
            //formData.append('job_location', (locationTags));
            axios.post('https://squadgoo.com.au/api.squadgoo.com.au/offerdecline', formData)
               .then((response) => { if (response.status == 200 && response.data.status == 1) { setIsDeclineSuccessModalShow(true); getDashboardData(); /*toast.success('Declined Successfully!');*/ } closeDeclineModal() })
               .catch((error) => { console.error(error); });
         } catch (error) { console.log(error); }
         //locationTags
      }
      setValidated(true);
   };

   /*--------------------decline reasons-------------------------------*/
   const [declineReasonDefault, setDeclineReasonDefault] = useState(false);
   const declineReasonsOptions = [
      { key: 1, value: 'better_opportunity', label: 'Better Opportunity' },
      { key: 2, value: 'company_culture', label: 'Company Culture' },
      { key: 3, value: 'location', label: 'Location' },
      { key: 4, value: 'salary', label: 'Salary' },
      { key: 5, value: 'job_role', label: 'Job Role' },
      { key: 6, value: 'job_role', label: 'Family or Personal Reasons' },
      { key: 7, value: 'Skills or Growth', label: 'Skills or Growth' },
      { key: 8, value: 'Contract Terms', label: 'Contract Terms' },
      { key: 9, value: 'Job Security', label: 'Job Security' },
      { key: 10, value: 'Health or Safety Concerns', label: 'Health or Safety Concerns' },
      { key: 11, value: 'Overqualification', label: 'Overqualification' },
      { key: 12, value: 'Thanking and Expressing Gratitude', label: 'Thanking and Expressing Gratitude' },
      { key: 13, value: 'Others', label: 'Others' },
   ];

   const getDeclineOption = (e) => { (e.target.value == 'Others') ? setDeclineReasonDefault(true) : setDeclineReasonDefault(false) }

   //jobAcceptModuleShow
   return (
      <>
         {/* <ToastContainer />*/}

         <div className="dashboarwrap">
            {/* {userdetails.validationdata_check.basicdetail_validation} */}
            {
               (userdetails.email_status == 1 && userdetails.contact_status == 1 && userdetails.validationdata_check.basicdetail_validation == 1) ?
                  <>
                     <div className="jobbanner">
                        <div className="container">
                           <div className="row">
                              <div className="col-md-12">
                                 <h2>Dashboard</h2>
                                 <form className="searchform d-flex">
                                    <input type="text" className="form-control" placeholder="Search your job offers here"></input><input type="submit" value="Search"></input>
                                 </form>
                              </div>
                           </div>
                        </div>
                     </div>
                     <div className="jobbtmsec py-4">
                        <div className="container">
                           <div className="row">
                              <div className="col-md-12">
                                 <ul className="nav nav-tabs jobtab d-flex" id="myTab" role="tablist">
                                    <li className="nav-item" role="presentation">
                                       <button onClick={(e) => (setcurrentScreen('active_job'))} className={`nav-link jobtablink ${currentScreen == 'active_job' ? 'active' : ''}`} id="activejob-tab">Active offer</button>
                                    </li>
                                    <li className="nav-item" role="presentation">
                                       <button onClick={(e) => (setcurrentScreen('completed_job'))} className={`nav-link jobtablink ${currentScreen == 'completed_job' ? 'active' : ''}`} id="completedjob-tab">Accepted offer</button>
                                    </li>
                                    <li className="nav-item" role="presentation">
                                       <button onClick={(e) => (setcurrentScreen('declined_job'))} className={`nav-link jobtablink ${currentScreen == 'declined_job' ? 'active' : ''}`} id="completedjob-tab">Declined offer</button>
                                    </li>
                                    <li className="nav-item" role="presentation">
                                       <button onClick={(e) => (setcurrentScreen('expired_job'))} className={`nav-link jobtablink ${currentScreen == 'expired_job' ? 'active' : ''}`} id="completedjob-tab">Expired offer</button>
                                    </li>
                                 </ul>
                                 <div className="tab-content" id="myTabContent">
                                    {
                                       !dataisLoading ?
                                          <>

                                             <div className="row">
                                                <div className="col-md-12">
                                                   <div className="d-flex flex-wrap justify-content-between mb-2">
                                                      {currentScreen == 'active_job'  && <h3 className=''>{dataLength.length_activated} Active offer</h3>}
                                                      {currentScreen == 'completed_job'  && <h3>{dataLength.length_completed} Completed jobs</h3>}
                                                      {currentScreen == 'declined_job'  && <h3>{dataLength.length_declined} Declined jobs</h3>}
                                                      {currentScreen == 'expired_job'  && <h3>{dataLength.length_expired} Expired jobs</h3>}
                                                      <div className="sort_option d-flex">
                                                         <p>Sort by</p>
                                                      
                                                         <select onChange={(e) => shortByFilterfun(e.target.value, 'weekly')} className="form-control">
                                                            {
                                                               ShortByFilter.map(
                                                                  (item, index) => (
                                                                     <option selected={shortByFilter == item.value ? true :false}  value={item.value}>{item.name}</option>
                                                                  )
                                                               )
                                                            }
                                                         </select>
                                                         <select onChange={(e) => setShortBySearchtype(e.target.value)} className="form-control ml-2">
                                                            {
                                                               ShortByQuickSearch.map(
                                                                  (item, index) => (
                                                                     <option selected={shortBySearchtype == item.value ? true :false}  value={item.value}>{item.name}</option>
                                                                  )
                                                               )
                                                            }
                                                         </select>
                                                      </div>
                                                   </div>
                                                </div>
                                             </div>
                                             {/* tab section 1 active job */}
                                             {currentScreen == 'active_job' && <div className={`tab-pane fade  ${currentScreen == 'active_job' ? 'show active' : ''}`} id="activejob" >
                                                <div className="row">
                                                   <div className="col-md-12">

                                                      <div className="joblistingwrap">
                                                         {
                                                            offerDataActivatedArray.length ?
                                                               <>
                                                                  {
                                                                     offerDataActivatedArray.map((item, index) => (
                                                                        <div className="card-box joblistbox">
                                                                           <div className="d-flex flex-wrap justify-content-between">
                                                                              <h3 data-bs-toggle="modal" data-bs-target="#jobsetailmodle">{item.job_title_text}</h3>
                                                                              {/* <h3 className="job_price orange_clr">${item.salary_minimum}/Hour to ${item.salary_maximum}/Hour</h3> */}
                                                                              <h3 className="job_price orange_clr">
                                                                                 ${(item.fixed_salary != '0.00') ? item.fixed_salary : `${item.salary_minimum}/Hour to ${item.salary_maximum}/Hour`}
                                                                              </h3>
                                                                           </div>
                                                                           <div>
                                                                              {item.searchtype == 'manually' ? <span className="tags">{item.job_type_text}</span> : <span className="tags">{item.industry_text}</span>}
                                                                              <span className="tags float-right bgdarkgreen"> {(item.searchtype == 'manually') ? 'Manually' : 'Quick'} Search</span>
                                                                           </div>

                                                                           {/* <p>Maecenas tempus, tellus eget condimentum rhoncus, sem quam semper libero, sit amet adipiscing sem neque sed ipsum. Sed hendrerit. Maecenas nec odio et ante condimentum rhoncus, sem quam semper libero condimentum rhoncus, sem quam...</p> */}
                                                                           <div className="d-flex flex-wrap">
                                                                              <div className="icontxt"><img src="/front/images/calender.png"></img> Offer date: <span>{item.created_date_format}</span></div>
                                                                              <div className="icontxt"><img src="/front/images/clock.png"></img> Offer expire date: <span>{item.expired_date_format}</span></div>
                                                                              <div className="icontxt"><img src="/front/images/location.png"></img> Work location: <span>{item.job_location_text}</span></div>
                                                                              <div className="icontxt"><img src="/front/images/bag.png"></img> Job type: <span>{item.job_type_text}</span></div>
                                                                              <div className="icontxt"><img src="/front/images/star.png"></img> Experience: <span>{item.total_experience_year + '.' + item.total_experience_month}</span></div>
                                                                           </div>
                                                                           <div className="btnsgroup" data-toggle="tooltip" data-placement="top" title={isUserVerified != true ? 'Your profile is under review for verification' : ''} >
                                                                              <button className="batn batn-orange" disabled={isUserVerified != true} onClick={(e) => jobDetailModuleShow(item.offer_id, item.job_form_id)} >View details</button>
                                                                              <button disabled={isLoadingAcceptedOffer && (isUserVerified != true)} className="batn batn-green" onClick={(e) => offerAccepted(item.offer_id)} >Accept {isLoadingAcceptedOffer ? <><img src={'/front/images/loader.gif'} /></> : <></>}</button>
                                                                              <button className="batn batn-red" disabled={isUserVerified != true} onClick={(e) => jobAreYouSureDeclineModuleShow(item.offer_id)} >Decline</button>
                                                                           </div>
                                                                        </div>
                                                                     ))
                                                                  }
                                                               </>
                                                               :
                                                               <><div className="card-box joblistbox data_not_found_card"><p>There are no completed job</p></div></>
                                                         }
                                                      </div>
                                                      {
                                                         (dataLength.length_activated > 10) ?
                                                            <>
                                                               <div className="card-box">
                                                                  <nav aria-label="Page navigation">
                                                                     <ul className="pagination mb-0 justify-content-center">
                                                                        <li className="page-item "><a className="page-link" href="#"><i className="fa fa-angle-left" aria-hidden="true"></i></a></li>
                                                                        <li className="page-item active"><a className="page-link" href="#">1</a></li>
                                                                        <li className="page-item"><a className="page-link" href="#"><i className="fa fa-angle-right" aria-hidden="true"></i></a></li>
                                                                     </ul>
                                                                  </nav>
                                                               </div>
                                                            </>
                                                            : <></>
                                                      }
                                                   </div>
                                                </div>
                                             </div>}
                                             {/* tab section 2 accepted job offers */}
                                             {currentScreen == 'completed_job' && <div className={`tab-pane fade  ${currentScreen == 'completed_job' ? 'show active' : ''}`} id="completedjob">
                                                <div className="row">
                                                   {/* <div className="col-md-3 sidebarwrap display_none">
                                                      <div className="jobfilter">
                                                         <h3 className="mb-4">Filters</h3>
                                                         <div className="jobfilterinner card-box">
                                                            <form className="commonform">
                                                               <div className="filtersecblock mb-4">
                                                                  <div className="sidebarttl d-flex justify-content-between flex-wrap">
                                                                     <h4>Location</h4>
                                                                     <a href="#">Reset</a>
                                                                  </div>
                                                                  <div className="form-group">
                                                                     <select className="form-control searchcity">
                                                                        <option>Select City</option>
                                                                        <option>Select City</option>
                                                                        <option>Select City</option>
                                                                     </select>
                                                                  </div>
                                                               </div>
                                                               <div className="filtersecblock mb-4">
                                                                  <div className="sidebarttl d-flex justify-content-between flex-wrap">
                                                                     <h4>Job Category</h4>
                                                                     <a href="#">Reset</a>
                                                                  </div>
                                                                  <div className="form-group">
                                                                     <input type="text" className="form-control jobcategorysearch" />
                                                                     <div className="serch_categorylist">
                                                                        <label className="checkboxlabl"><input type="checkbox"></input><span></span>Electrician</label>
                                                                        <label className="checkboxlabl"><input type="checkbox"></input><span></span>Plumber</label>
                                                                        <label className="checkboxlabl"><input type="checkbox"></input><span></span>Constructer</label>
                                                                        <label className="checkboxlabl"><input type="checkbox"></input><span></span>Driver</label>
                                                                        <label className="checkboxlabl"><input type="checkbox"></input><span></span>Care taker</label>
                                                                        <label className="checkboxlabl"><input type="checkbox"></input><span></span>Home tutor</label>
                                                                        <label className="checkboxlabl"><input type="checkbox"></input><span></span>Pet care</label>
                                                                        <label className="checkboxlabl"><input type="checkbox"></input><span></span>Cleaner</label>
                                                                        <label className="checkboxlabl"><input type="checkbox"></input><span></span>Chef</label>
                                                                     </div>
                                                                  </div>
                                                               </div>
                                                               <div className="filtersecblock mb-4">
                                                                  <div className="sidebarttl d-flex justify-content-between flex-wrap">
                                                                     <h4>Job type</h4>
                                                                     <a href="#">Reset</a>
                                                                  </div>
                                                                  <div className="form-group">
                                                                     <div className="switch-field">
                                                                        <input type="radio" id="monthly" name="jobtype" value="monthly" checked />
                                                                        <label htmlFor="monthly">Monthly</label>
                                                                        <input type="radio" id="weekly" name="jobtype" value="weekly" />
                                                                        <label htmlFor="weekly">Weekly</label>
                                                                        <input type="radio" id="hourly" name="jobtype" value="hourly" />
                                                                        <label htmlFor="hourly">Hourly</label>
                                                                     </div>
                                                                  </div>
                                                               </div>
                                                               <div className="filtersecblock">
                                                                  <div className="sidebarttl d-flex justify-content-between flex-wrap">
                                                                     <h4>Pay rate</h4>
                                                                     <a href="#">Reset</a>
                                                                  </div>
                                                                  <div className="form-group">
                                                                     <label className="form-label">Min</label>
                                                                     <input type="text" className="form-control" placeholder="$" />
                                                                  </div>
                                                                  <div className="form-group mb-2">
                                                                     <label className="form-label">Max</label>
                                                                     <input type="text" className="form-control" placeholder="$" />
                                                                  </div>
                                                                  <div className="form-group">
                                                                     <input type="Submit" className="batn batn-orange w-100" value="Apply Filter"></input>
                                                                  </div>
                                                               </div>
                                                            </form>
                                                         </div>
                                                      </div>
                                                   </div> */}
                                                   <div className="col-md-12 rightside">

                                                      <div className="joblistingwrap">
                                                         {
                                                            offerDataAcceptedArray.length ?
                                                               <>
                                                                  {
                                                                     offerDataAcceptedArray.map((item, index) => (
                                                                        <div className="card-box joblistbox">
                                                                           <div className="d-flex flex-wrap justify-content-between">
                                                                              <h3 data-bs-toggle="modal" data-bs-target="#jobsetailmodle">{item.job_title_text}</h3>
                                                                              {/* <h3 className="job_price orange_clr">${item.salary_minimum}/Hour to ${item.salary_maximum}/Hour</h3> */}
                                                                              <h3 className="job_price orange_clr">
                                                                                 ${(item.fixed_salary != '0.00') ? item.fixed_salary : `${item.salary_minimum}/Hour to ${item.salary_maximum}/Hour`}
                                                                              </h3>
                                                                           </div>
                                                                           {/* <div><span className="tags">{item.job_type_text}</span></div> */}
                                                                           <div>
                                                                              {item.searchtype == 'manually' ? <span className="tags">{item.job_type_text}</span> : <span className="tags">{item.industry_text}</span>}
                                                                              <span className="tags float-right bgdarkgreen"> {(item.searchtype == 'manually') ? 'Manually' : 'Quick'} Search</span>
                                                                           </div>
                                                                           {/* <p>Maecenas tempus, tellus eget condimentum rhoncus, sem quam semper libero, sit amet adipiscing sem neque sed ipsum. Sed hendrerit. Maecenas nec odio et ante condimentum rhoncus, sem quam semper libero condimentum rhoncus, sem quam...</p> */}
                                                                           <div className="d-flex flex-wrap">
                                                                              <div className="icontxt"><img src="/front/images/calender.png"></img> Offer date: <span>{item.created_date_format}</span></div>
                                                                              <div className="icontxt"><img src="/front/images/clock.png"></img> Offer expire date: <span>{item.expired_date_format}</span></div>
                                                                              <div className="icontxt"><img src="/front/images/location.png"></img> Work location: <span>{item.job_location_text}</span></div>
                                                                              <div className="icontxt"><img src="/front/images/bag.png"></img> Job type: <span>{item.job_type_text}</span></div>
                                                                              <div className="icontxt"><img src="/front/images/star.png"></img> Experience: <span>{item.total_experience_year + '.' + item.total_experience_month}</span></div>
                                                                           </div>
                                                                           <div className="btnsgroup" data-toggle="tooltip" data-placement="top" title={isUserVerified != true ? 'Your profile is under review for verification' : ''}>
                                                                              <button className="batn batn-orange" disabled={isUserVerified != true} onClick={(e) => jobDetailModuleShow(item.offer_id, item.job_form_id)} >View details</button>
                                                                              {/* <Link disabled={isLoadingAcceptedOffer} className="batn batn-green" onClick={(e) => offerAccepted(item.offer_id) } >Accept Offer {isLoadingAcceptedOffer ? <><img   src={'/front/images/loader.gif'} /></> : <></>}</Link> */}
                                                                              <button disabled={isUserVerified != true} className="batn batn-green" to={`/chat?offerid=${item.offer_id}`} >Chat</button>
                                                                              {/* <button className="batn batn-red" disabled={isUserVerified != true} onClick={(e) => jobAreYouSureDeclineModuleShow(item.offer_id)} >Decline</button> */}
                                                                           </div>
                                                                        </div>
                                                                     ))
                                                                  }
                                                               </>
                                                               :
                                                               <><div className="card-box joblistbox data_not_found_card"><p>There are no accepted jobs</p></div></>
                                                         }
                                                      </div>

                                                      {
                                                         (dataLength.length_completed > 10) ?
                                                            <>
                                                               <div className="card-box">
                                                                  <nav aria-label="Page navigation">
                                                                     <ul className="pagination mb-0 justify-content-center">
                                                                        <li className="page-item "><a className="page-link" href="#"><i className="fa fa-angle-left" aria-hidden="true"></i></a></li>
                                                                        <li className="page-item active"><a className="page-link" href="#">1</a></li>
                                                                        <li className="page-item"><a className="page-link" href="#"><i className="fa fa-angle-right" aria-hidden="true"></i></a></li>
                                                                     </ul>
                                                                  </nav>
                                                               </div>
                                                            </>
                                                            : <></>
                                                      }
                                                   </div>
                                                </div>
                                             </div>}
                                             {/* tab section 3 declined job */}
                                             {currentScreen == 'declined_job' && <div className={`tab-pane fade  ${currentScreen == 'declined_job' ? 'show active' : ''}`} id="activejob" >
                                                <div className="row">
                                                   <div className="col-md-12">

                                                      <div className="joblistingwrap">
                                                         {
                                                            offerDataDeclinedArray.length ?
                                                               <>
                                                                  {
                                                                     offerDataDeclinedArray.map((item, index) => (
                                                                        <div className="card-box joblistbox">
                                                                           <div className="d-flex flex-wrap justify-content-between">
                                                                              <h3 data-bs-toggle="modal" data-bs-target="#jobsetailmodle">{item.job_title_text}</h3>
                                                                              {/* <h3 className="job_price orange_clr">${item.salary_minimum}/Hour to ${item.salary_maximum}/Hour</h3> */}
                                                                              <h3 className="job_price orange_clr">
                                                                                 ${(item.fixed_salary != '0.00') ? item.fixed_salary : `${item.salary_minimum}/Hour to ${item.salary_maximum}/Hour`}
                                                                              </h3>
                                                                           </div>
                                                                           {/* <div><span className="tags">{item.job_type_text}</span></div> */}
                                                                           <div>
                                                                              {item.searchtype == 'manually' ? <span className="tags">{item.job_type_text}</span> : <span className="tags">{item.industry_text}</span>}
                                                                              <span className="tags float-right bgdarkgreen"> {(item.searchtype == 'manually') ? 'Manually' : 'Quick'} Search</span>
                                                                           </div>
                                                                           {/* <p>Maecenas tempus, tellus eget condimentum rhoncus, sem quam semper libero, sit amet adipiscing sem neque sed ipsum. Sed hendrerit. Maecenas nec odio et ante condimentum rhoncus, sem quam semper libero condimentum rhoncus, sem quam...</p> */}
                                                                           <div className="d-flex flex-wrap">
                                                                              <div className="icontxt"><img src="/front/images/calender.png"></img> Offer date: <span>{item.created_date_format}</span></div>
                                                                              <div className="icontxt"><img src="/front/images/clock.png"></img> Offer expire date: <span>{item.expired_date_format}</span></div>
                                                                              <div className="icontxt"><img src="/front/images/location.png"></img> Work location: <span>{item.job_location_text}</span></div>
                                                                              <div className="icontxt"><img src="/front/images/bag.png"></img> Job type: <span>{item.job_type_text}</span></div>
                                                                              <div className="icontxt"><img src="/front/images/star.png"></img> Experience: <span>{item.total_experience_year + '.' + item.total_experience_month}</span></div>
                                                                           </div>
                                                                           <div className="btnsgroup" data-toggle="tooltip" data-placement="top" title={isUserVerified != true ? 'Your profile is under review for verification' : ''} >
                                                                              {/* <button className="batn batn-orange" disabled={isUserVerified != true} onClick={(e) => jobDetailModuleShow(item.offer_id, item.job_form_id)} >View details</button> */}
                                                                              {/* <Link disabled={isLoadingAcceptedOffer} className="batn batn-green" onClick={(e) => offerAccepted(item.offer_id) } >Accept Offer {isLoadingAcceptedOffer ? <><img   src={'/front/images/loader.gif'} /></> : <></>}</Link> */}
                                                                              {/* <Link className="batn batn-red" onClick={(e) => jobAreYouSureDeclineModuleShow(item.offer_id) } >Decline Offer</Link> */}
                                                                           </div>
                                                                        </div>
                                                                     ))
                                                                  }
                                                               </>
                                                               :
                                                               <><div className="card-box joblistbox data_not_found_card"><p>There are no completed job</p></div></>
                                                         }


                                                      </div>
                                                      {
                                                         (dataLength.length_declined > 10) ?
                                                            <>
                                                               <div className="card-box">
                                                                  <nav aria-label="Page navigation">
                                                                     <ul className="pagination mb-0 justify-content-center">
                                                                        <li className="page-item "><a className="page-link" href="#"><i className="fa fa-angle-left" aria-hidden="true"></i></a></li>
                                                                        <li className="page-item active"><a className="page-link" href="#">1</a></li>
                                                                        <li className="page-item"><a className="page-link" href="#"><i className="fa fa-angle-right" aria-hidden="true"></i></a></li>
                                                                     </ul>
                                                                  </nav>
                                                               </div>
                                                            </>
                                                            : <></>
                                                      }
                                                   </div>
                                                </div>
                                             </div>}
                                             {/* tab section 4 expired job */}
                                             {currentScreen == 'expired_job' && <div className={`tab-pane fade  ${currentScreen == 'expired_job' ? 'show active' : ''}`} id="activejob" >
                                                <div className="row">
                                                   <div className="col-md-12">

                                                      <div className="joblistingwrap">
                                                         {
                                                            offerDataExpiredArray.length ?
                                                               <>
                                                                  {
                                                                     offerDataExpiredArray.map((item, index) => (
                                                                        <div className="card-box joblistbox">
                                                                           <div className="d-flex flex-wrap justify-content-between">
                                                                              <h3 data-bs-toggle="modal" data-bs-target="#jobsetailmodle">{item.job_title_text}</h3>
                                                                              {/* <h3 className="job_price orange_clr">${item.salary_minimum}/Hour to ${item.salary_maximum}/Hour</h3> */}
                                                                              <h3 className="job_price orange_clr">
                                                                                 ${(item.fixed_salary != '0.00') ? item.fixed_salary : `${item.salary_minimum}/Hour to ${item.salary_maximum}/Hour`}
                                                                              </h3>
                                                                           </div>
                                                                           {/* <div><span className="tags">{item.job_type_text}</span></div> */}
                                                                           <div>
                                                                              {item.searchtype == 'manually' ? <span className="tags">{item.job_type_text}</span> : <span className="tags">{item.industry_text}</span>}
                                                                              <span className="tags float-right bgdarkgreen"> {(item.searchtype == 'manually') ? 'Manually' : 'Quick'} Search</span>
                                                                           </div>
                                                                           {/* <p>Maecenas tempus, tellus eget condimentum rhoncus, sem quam semper libero, sit amet adipiscing sem neque sed ipsum. Sed hendrerit. Maecenas nec odio et ante condimentum rhoncus, sem quam semper libero condimentum rhoncus, sem quam...</p> */}
                                                                           <div className="d-flex flex-wrap">
                                                                              <div className="icontxt"><img src="/front/images/calender.png"></img> Offer date: <span>{item.created_date_format}</span></div>
                                                                              <div className="icontxt"><img src="/front/images/clock.png"></img> Offer expire date: <span>{item.expired_date_format}</span></div>
                                                                              <div className="icontxt"><img src="/front/images/location.png"></img> Work location: <span>{item.job_location_text}</span></div>
                                                                              <div className="icontxt"><img src="/front/images/bag.png"></img> Job type: <span>{item.job_type_text}</span></div>
                                                                              <div className="icontxt"><img src="/front/images/star.png"></img> Experience: <span>{item.total_experience_year + '.' + item.total_experience_month}</span></div>
                                                                           </div>
                                                                           {/* <div className="btnsgroup">
                                                                           <Link className="batn batn-orange" onClick={(e) => jobDetailModuleShow(item.offer_id , item.job_form_id ) } >View details</Link>
                                                                           <Link disabled={isLoadingAcceptedOffer} className="batn batn-green" onClick={(e) => offerAccepted(item.offer_id) } >Accept Offer {isLoadingAcceptedOffer ? <><img   src={'/front/images/loader.gif'} /></> : <></>}</Link>
                                                                           <Link className="batn batn-red" onClick={(e) => jobAreYouSureDeclineModuleShow(item.offer_id) } >Decline Offer</Link>
                                                                        </div> */}
                                                                        </div>
                                                                     ))
                                                                  }
                                                               </>
                                                               :
                                                               <><div className="card-box joblistbox data_not_found_card"><p>There are no completed job</p></div></>
                                                         }


                                                      </div>
                                                      {
                                                         dataLength.length_expired > 10 ?
                                                            <>
                                                               <div className="card-box">
                                                                  <nav aria-label="Page navigation">
                                                                     <ul className="pagination mb-0 justify-content-center">
                                                                        <li className="page-item "><a className="page-link" href="#"><i className="fa fa-angle-left" aria-hidden="true"></i></a></li>
                                                                        <li className="page-item active"><a className="page-link" href="#">1</a></li>
                                                                        <li className="page-item"><a className="page-link" href="#"><i className="fa fa-angle-right" aria-hidden="true"></i></a></li>
                                                                     </ul>
                                                                  </nav>
                                                               </div>
                                                            </>
                                                            : <></>
                                                      }
                                                   </div>
                                                </div>
                                             </div>}
                                          </>
                                          :
                                          <>
                                             <div className={`tab-pane fade show active mt45px`} >
                                                <div className='loader_container'>
                                                   <p className='text-center'><img className='width-100 loader_image' src={'/front/images/loader.gif'} /></p>
                                                </div>
                                             </div>
                                          </>
                                    }
                                 </div>

                              </div>
                           </div>
                        </div>
                     </div>
                  </> : <><ProfileNotCompleted /></>
            }


            {
               (userdetails.email_status == 1 && userdetails.contact_status == 1) ?
                  <>
                     {/* recruiter View details model */}
                     <Modal className="modal fade modallarg jobmodle jobdetailmdl" id="jobsetailmodle" show={isJobDetailsModalShow} onHide={closeJobDetailModal}>
                        {
                           staffViewDetailsDataArr.job_title_text ?
                              <>
                                 <div className="modal-dialog1">
                                    <div className="modal-content">
                                       <div className="modal-body">
                                          <button type="button" className="btn-close" onClick={closeJobDetailModal} ></button>

                                          <JobDesModalcomp data={staffViewDetailsDataArr} currentScreen={currentScreen} />
                                          <div className="joblistbox">
                                             <div className="btnsgroup">
                                                {
                                                   currentScreen == 'active_job' &&
                                                   <>
                                                      <Link disabled={isLoadingAcceptedOffer} className="batn batn-green" onClick={(e) => offerAccepted(offerID)} >Accept {isLoadingAcceptedOffer ? <><img src={'/front/images/loader.gif'} /></> : <></>}</Link>
                                                      {/* <Link className="batn batn-red" onClick={(e) => jobDeclineModuleShow(offerID)} >Decline</Link> */}
                                                      <Link className="batn batn-red" onClick={(e) => { setIsJobDetailsModalShow(false); jobAreYouSureDeclineModuleShow(offerID) }} >Decline</Link>
                                                   </>
                                                }
                                             </div>
                                          </div>
                                       </div>
                                       <div className={`model_loader ${!isModalDataLoad ? 'display_none' : ''}`}>
                                          <img src={'/front/images/loader.gif'} />
                                       </div>
                                    </div>
                                 </div>
                              </>
                              :
                              <></>
                        }
                     </Modal>

                     {/* jobaccept model chat redirect */}
                     <Modal className="modal fade modalsmall jobacceptmodle" id="jobacceptmodle" show={isJobAcceptModalShow} onHide={closeJobAcceptModal}>
                        <div className="modal-dialog1">
                           <div className="modal-content">
                              <div className="modal-body">
                                 <button type="button" className="btn-close" onClick={closeJobAcceptModal}></button>
                                 <div className="text-center mb-3">
                                    <img src="/front/images/greencheck.svg" />
                                 </div>
                                 <h5 className="text-center mb-4">You have successfully accepted the job offer!</h5>

                                 <div className="btnsgroup text-center">
                                    <Link to={`/chat?offerid=${offerID}`} className="batn batn-orange" >Yes</Link>
                                    <Link onClick={closeAreYouSureDeclineModal} className="batn batn-orange batn-border">No</Link>
                                 </div>
                              </div>
                           </div>
                        </div>
                     </Modal>

                     {/* after jobdeclinedmodle  model  */}
                     <Modal className="modal fade ask-jobdeclinedmodle" id="ask-jobdeclinedmodle" show={isAreYouSureDeclineModalShow} onHide={closeAreYouSureDeclineModal} >
                        <div className="modal-dialog1">
                           <div className="modal-content">
                              <div className="modal-body">
                                 <button type="button" className="btn-close" onClick={closeAreYouSureDeclineModal} ></button>
                                 <h5 className="text-center mb-4">Decline this offer may effect your profile rating and you can not see this offer again. <br></br> Do you still want to decline it?</h5>
                                 <div className="btnsgroup text-center">
                                    <Link onClick={() => jobDeclineModuleShow(offerID)} className="batn batn-orange" >Yes</Link>
                                    <Link onClick={closeAreYouSureDeclineModal} className="batn batn-orange batn-border">No</Link>
                                 </div>
                              </div>
                           </div>
                        </div>
                     </Modal>

                     {/* jobdeclinedmodle  model  */}
                     <Modal className="modal fade jobdeclinedmodle" id="jobdeclinemodle" show={isDeclineModalShow} onHide={closeDeclineModal} >
                        <div className="modal-dialog1">
                           <div className="modal-content">
                              <div className="modal-body">
                                 <button type="button" onClick={closeDeclineModal} className="btn-close" ></button>
                                 <Form className="commonform" noValidate validated={validated} onSubmit={onDeclineMessageSend} >
                                    <div className="from-group mb-3">
                                       <h4 className="mb-2">Please tell us the reason to decline this job offer</h4>
                                       {/* <div className="mb-3">
                                          <div className="commonchecbox d-flex"><span className="agrrimentbox"><input type="checkbox" id="better-opportunity" name="better-opportunity" /><span className="checkafter"></span></span><label className="checklabel f-w-medium" for="better-opportunity">Better Opportunity</label></div>
                                          <div className="commonchecbox d-flex"><span className="agrrimentbox"><input type="checkbox" id="Company-culture" name="Company-culture" /><span className="checkafter"></span></span><label className="checklabel f-w-medium" for="Company-culture">Company Culture</label></div>
                                          <div className="commonchecbox d-flex"><span className="agrrimentbox"><input type="checkbox" id="Location" name="Location" /><span className="checkafter"></span></span><label className="checklabel f-w-medium" for="Location">Location</label></div>
                                          <div className="commonchecbox d-flex"><span className="agrrimentbox"><input type="checkbox" id="Family-or-Personal-Reasons" name="Family-or-Personal-Reasons" /><span className="checkafter"></span></span><label className="checklabel f-w-medium" for="Family-or-Personal-Reasons">Family or Personal Reasons</label></div>
                                          <div className="commonchecbox d-flex"><span className="agrrimentbox"><input type="checkbox" id="Job-Security" name="Job-Security" /><span className="checkafter"></span></span><label className="checklabel f-w-medium" for="Job-Security">Job Security</label></div>
                                       </div> */}
                                       <select name='declined_reasons' onChange={getDeclineOption} className='form-control mb-2'>
                                          {
                                             declineReasonsOptions.map((item, index) => (
                                                <>
                                                   <option key={item.key} value={item.label}>{item.label}</option>
                                                </>
                                             ))
                                          }
                                       </select>
                                       {
                                          declineReasonDefault &&
                                          <Form.Control
                                             as="textarea"
                                             name='declined_message'
                                             className="form-control"
                                             required={true}
                                             placeholder="Describe here"
                                             rows={7}
                                             onChange={(e) => setDeclineReasonDefaultValue(e.target.value)}
                                          />
                                       }
                                       {/* <textarea className="form-control" rows="7" placeholder="Describe here"></textarea> */}
                                    </div>
                                    <div className="from-group">
                                       <button type='submit' className="batn batn-orange" value="Submit"  >Submit</button>
                                    </div>
                                 </Form>
                              </div>
                           </div>
                        </div>
                     </Modal>

                     {/* jobdeclinedmodle  model  */}
                     <Modal className="modal fade modalsmallest jobdeclinesucess" id="jobdeclinesucess" show={isDeclineSuccessModalShow} onHide={() => setIsDeclineSuccessModalShow(false)} >
                        <div className="modal-dialog1">
                           <div className="modal-content">
                              <div className="modal-body">
                                 <button type="button" className="btn-close" onClick={() => setIsDeclineSuccessModalShow(false)}></button>
                                 <div className="text-center mb-3"><img src="/front/images/greencheck.svg"></img></div>
                                 <h5 className="text-center mb-4">Reason submitted successfully!</h5>
                              </div>
                           </div>
                        </div>
                     </Modal>

                  </>
                  : ''
            }
         </div>
      </>
   );
}
export default Dashboard;